import React from 'react';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'firebase/firestore';
import moment from 'moment';
import 'moment/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/material/styles';

import './style/tailwind.css';
import { ComponentPreviews, useInitial } from './react-buddy';
import { router } from './routing/router';
import { StyledMaterialDesignContent } from './style/toasts';
// @ts-ignore
import i18n from './locales/i18n';
import { GlobalStyle } from './style/globalStyle';
import muiTheme from './style/mui-theme';
import { NewStudyProvider } from './contexts/NewStudyContext';

const root = createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

moment.locale('fr');

const consoleError = console.error;
console.error = function filterWarnings(msg, ...args) {
  try {
    if (
      !['Warning: React does not recognize the', 'Warning: validateDOMNesting'].some((entry) =>
        msg.includes(entry)
      )
    ) {
      consoleError(msg, ...args);
    }
  } catch (_e) {
    consoleError(msg, ...args);
  }
};

root.render(
  // <React.StrictMode>
  <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
    <ThemeProvider theme={muiTheme}>
      <GlobalStyle />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3000}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
          }}>
          <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
              <NewStudyProvider>
                <RouterProvider router={router} />
              </NewStudyProvider>
            </I18nextProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </DevSupport>
  // </React.StrictMode>
);
