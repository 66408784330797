export enum AllOrientations {
  S = 'S',
  SSW = 'SSW',
  SW = 'SW',
  WSW = 'WSW',
  W = 'W',
  WNW = 'WNW',
  NW = 'NW',
  NNW = 'NNW',
  N = 'N',
  NNE = 'NNE',
  NE = 'NE',
  ENE = 'ENE',
  E = 'E',
  ESE = 'ESE',
  SE = 'SE',
  SSE = 'SSE',
  NC = 'NC',
}

export type Orientation = Extract<
  AllOrientations,
  | AllOrientations.S
  | AllOrientations.SSW
  | AllOrientations.SW
  | AllOrientations.WSW
  | AllOrientations.W
  | AllOrientations.E
  | AllOrientations.ESE
  | AllOrientations.SE
  | AllOrientations.SSE
  | AllOrientations.NC
>;

export const Orientation = {
  [AllOrientations.S]: AllOrientations.S,
  [AllOrientations.SSW]: AllOrientations.SSW,
  [AllOrientations.SW]: AllOrientations.SW,
  [AllOrientations.WSW]: AllOrientations.WSW,
  [AllOrientations.W]: AllOrientations.W,
  [AllOrientations.E]: AllOrientations.E,
  [AllOrientations.ESE]: AllOrientations.ESE,
  [AllOrientations.SE]: AllOrientations.SE,
  [AllOrientations.SSE]: AllOrientations.SSE,
  [AllOrientations.NC]: AllOrientations.NC,
} as const;

export const isOrientation = (orientation: AllOrientations): orientation is Orientation => {
  return orientation in Orientation;
};

const orientationToFrenchName = {
  NC: 'nc',
  E: 'est',
  ESE: 'est-sud-est',
  SE: 'sud-est',
  SSE: 'sud-sud-est',
  S: 'sud',
  SSW: 'sud-sud-ouest',
  SW: 'sud-ouest',
  WSW: 'ouest-sud-ouest',
  W: 'ouest',
};

export const fromOrientationToFrenchName = (orientation: string): string => {
  // @ts-ignore
  return orientationToFrenchName[orientation];
};
