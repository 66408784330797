import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import Icon from '@mdi/react';
import { mdiRouterNetwork } from '@mdi/js';

import { TitleWithIcon } from './components/TitleWithIcon';
import { allModels } from './components/ColoredSquare';
import { ColoredSquareAndModel } from './components/ColoredSquareAndModel';
import { getAllData } from '../../firebase/firestoreQueries';
import {
  connectionCostsCollection,
  powerRangesCollection,
  powerRangesCollectionName,
  turpeCostsCollection,
} from '../../firebase/firestoreCollections';
import { onSubmitValueForPowerRanges } from './onSubmitValueForPowerRanges';
import { PowerRange } from '../../types/AdminSettings';
import { COLORS } from '../../style/colors';
import { PaddingBox } from './components/PaddingBox';

type FormData<T extends number[]> = {
  turpeCost: T;
};

export const NetworkCosts = () => {
  const { t } = useTranslation('admin');

  const powerRangesQuery = useFirestoreQueryData<PowerRange>(
    [powerRangesCollectionName],
    powerRangesCollection
  );

  const {
    handleSubmit: handleSubmitTurpe,
    control: controlTurpe,
    setValue: setValueTurpe,
    formState: { errors: errorsTurpe },
  } = useForm<FormData<number[]>>({});

  const {
    handleSubmit: handleSubmitConnection,
    control: controlConnection,
    setValue: setValueConnection,
    formState: { errors: errorsConnection },
  } = useForm<FormData<number[]>>({});

  React.useEffect(() => {
    (async () => {
      const turpeCosts = await getAllData(turpeCostsCollection);

      turpeCosts.forEach((t) => {
        // @ts-ignore
        setValueTurpe(t.model + t.powerRangeUid, t.turpeCost);
      });
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const connectionCosts = await getAllData(connectionCostsCollection);

      connectionCosts.forEach((t) => {
        // @ts-ignore
        setValueConnection(t.model + t.powerRangeUid, t.connectionCost);
      });
    })();
  }, []);

  return (
    <PaddingBox withLessPadding>
      <div>
        <TitleWithIcon
          icon={
            <Icon
              path={mdiRouterNetwork}
              size={1.6}
              color={COLORS.primary}
              style={{ marginBottom: 8 }}
            />
          }
          title={t('NetworkCosts.title0')}
        />
        <div className="pl-5">
          <Grid container spacing={6}>
            {allModels.map((model) => (
              <Grid item xs={12} md={4} key={model}>
                <ColoredSquareAndModel
                  model={model}
                  errors={errorsTurpe}
                  control={controlTurpe}
                  inputLabel={t('NetworkCosts.turpeCost')}
                  inputAdornment={t('NetworkCosts.euroPerYear')}
                />
              </Grid>
            ))}
          </Grid>

          <div className="mb-12 mt-5 flex items-center justify-start">
            <Button
              onClick={handleSubmitTurpe((data) =>
                onSubmitValueForPowerRanges(
                  data,
                  powerRangesQuery,
                  turpeCostsCollection,
                  'turpeCost'
                )
              )}
              variant="adminGreen">
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </div>

      <div>
        <TitleWithIcon
          icon={
            <Icon
              path={mdiRouterNetwork}
              size={1.6}
              color={COLORS.primary}
              style={{ marginBottom: 8 }}
            />
          }
          title={t('NetworkCosts.title1')}
        />
        <div className="pl-5">
          <Grid container spacing={6}>
            {allModels.map((model) => (
              <Grid item xs={12} md={4} key={model}>
                <ColoredSquareAndModel
                  model={model}
                  errors={errorsConnection}
                  control={controlConnection}
                  inputLabel={t('NetworkCosts.cost')}
                  inputAdornment={t('NetworkCosts.euro')}
                />
              </Grid>
            ))}
          </Grid>

          <div className="mb-12 mt-5 flex items-center justify-start">
            <Button
              onClick={handleSubmitConnection((data) =>
                onSubmitValueForPowerRanges(
                  data,
                  powerRangesQuery,
                  connectionCostsCollection,
                  'connectionCost'
                )
              )}
              variant="adminGreen">
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </div>
    </PaddingBox>
  );
};
