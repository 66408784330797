import React from 'react';
import { Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Step } from '../NewSimulation';
import { COLORS } from '../../../style/colors';

export const RoofExplanationsModalMobile = ({
  isExplanationModalOpen,
  setStep,
}: {
  isExplanationModalOpen: boolean;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={isExplanationModalOpen}>
      <ModalContentMobile>
        <div>
          <ImageContainer>
            <Image src="/images/satellite.png" alt={t('checkList')} />
          </ImageContainer>

          <div className="mb-10">
            <h2 className="text-center">{t('RoofExplanationsModal.title')}</h2>
          </div>

          <div className="mb-5 mt-3 flex justify-center ">{t('RoofExplanationsModal.content')}</div>
        </div>

        <div className="mb-5">
          <div className="m-3">
            <SButton
              onClick={() => setStep(Step.form)}
              variant="outlined"
              sx={{ borderRadius: '22px', height: '46px' }}>
              {t('RoofExplanationsModal.fillManually')}
            </SButton>
          </div>
          <div className="m-3">
            <SButton
              onClick={() => setStep(Step.roofSelector)}
              variant="contained"
              sx={{ borderRadius: '22px', height: '46px' }}>
              {t('ok')}
            </SButton>
          </div>
        </div>
      </ModalContentMobile>
    </Modal>
  );
};

const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Image = styled('img')`
  max-width: 150px;
  min-height: 100px;
`;

const SButton = styled(Button)`
  width: 100%;
`;

const ModalContentMobile = styled('div')`
  background-color: ${COLORS.white};
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
