export const headers = [
  "Numéro de l'étude",
  'statut',
  'Date de création',
  'Date de modification',
  'Nom',
  'Nom du créateur',
  'Région commerciale',
  'Secteur',
  'Affectation',
  'Département',
  'Nom client',
  'Prénom client',
  'Adresse client',
  'CP client',
  'Ville client',
  'Pays client',
  'Téléphone client',
  'Email client',
  'Consentement à des fins commerciales et marketing',
  'Consentement collecte',
  'Puissance',
  'Statut puissance',
  'Modèle économique',
  'Economies la 1ère année',
  'Economies sur 30ans',
  'Montant à payer si non réalisation de l’installation photovoltaïque (sur 30 ans)',
  'Subvention publique',
  'Autoconsommation annuelle',
  'Taux de couverture des besoins annuels',
  'Production PV cumulée en kWh la 1ère année',
  'Production PV cumulée en kWh sur 30 ans',
  'Plage de construction',
  'Altitude',
  'Surface habitable',
  'Surface toiture',
  'Nombre de personnes',
  'Orientation',
  'Pente toiture',
  'Consommation annuelle si connue',
  'Facture mensuelle si connue',
  'Facture annuelle si connue',
  'ChauffageElectrique',
  'PompeAChaleur (chauffage)',
  'PoeleOuCheminee',
  'Climatisation',
  'PACAir (ECS)',
  'ChauffageGaz',
  'ChauffeEau',
  'BallonThermo',
  'ChauffeEauGazFioulBois',
  'Aspirateur',
  'Bouilloire',
  'Cafetiere',
  'Congelateur',
  'FerARepasser',
  'GrillePain',
  'LaveLinge',
  'LaveVaisselle',
  'MicroOndes',
  'Refrigerateur',
  'SecheLinge',
  'BoxInternet',
  'TeleviseurOrdinateur',
  'VMC',
  'Piscine',
  'Piscine chauffée',
  'SPAHammam',
  'VoitureElectrique',
  'VeloElectrique',
  'ScooterElectrique',
  'Choix 1 : Produit sélectionné',
  'Choix 1 : Prix catalogue',
  'Choix 1 : Prix total choisi',
  'Choix 1 : Prix produit choisi',
  'Choix 1 : Prix raccordement choisi',
  'Choix 2 : Produit sélectionné',
  'Choix 2 : Prix catalogue',
  'Choix 2 : Prix total choisi',
  'Choix 2 : Prix produit choisi',
  'Choix 2 : Prix raccordement choisi',
  'Choix 3 : Produit sélectionné',
  'Choix 3 : Prix catalogue',
  'Choix 3 : Prix total choisi',
  'Choix 3 : Prix produit choisi',
  'Choix 3 : Prix raccordement choisi',
  'Choix 4 : Produit sélectionné',
  'Choix 4 : Prix catalogue',
  'Choix 4 : Prix total choisi',
  'Choix 4 : Prix produit choisi',
  'Choix 4 : Prix raccordement choisi',
];
