import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationFr from './fr/frTranslations.json';
import product from './fr/product.json';
import common from './fr/common.json';
import admin from './fr/admin.json';

const translations = {
  fr: {
    all: translationFr,
    product: product,
    common: common,
    admin: admin,
  },
};

i18n
  // .use(HttpApi)
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    debug: false,
    lng: 'fr', // overwrite detected language
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    resources: translations,
    ns: Object.keys(translations.fr),
    defaultNS: 'all',
  });

export default i18n;
