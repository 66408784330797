import {
  StorageReference,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
} from 'firebase/storage';
import Papa from 'papaparse';
import { Product, TileType } from '../types/ProductTypes';

type CsvProduct = {
  ACTIF: string;
  CODSOC: string;
  COEFF_PRIX_MAX: string;
  COEFF_PRIX_MIN: string;
  COULEUR: string;
  DESIGNATION_REF_CAT: string;
  OPT_ECU: string;
  OPT_OCE: string;
  OPT_RAL: string;
  OPT_SBE: string;
  PRIX_CATALOGUE: string;
  PUISSANCE: string;
  PUISSANCE_R_TOTALE: string;
  PUISSANCE_R_UNITAIRE: string;
  PVC_MAX: string;
  PVC_MINI: string;
  PVC_REF: string;
  QTE_PRD_EDILIAN: string;
  REFCAT: string;
  SURFACE: string;
  TARIF_OPT_ERP: string;
  TARIF_OPT_RACCORD_50M: string;
  TYPE_TLE_PHO: string;
};

export const readReferenceCsvFileFromStorage = async () => {
  const storage = getStorage();
  const listRef = ref(storage, '/references/');

  const list = await listAll(listRef);

  let updated = new Date(0).getTime();
  let lastFile: StorageReference | undefined;

  for (const itemRef of list.items) {
    const meta = await getMetadata(itemRef);
    if (new Date(meta.updated).getTime() > updated) {
      updated = new Date(meta.updated).getTime();
      lastFile = itemRef;
    }
  }

  if (!lastFile) {
    console.error('readReferenceCsvFileFromStorage: No file found');
    return [];
  }

  try {
    const url = await getDownloadURL(lastFile);

    const response = await fetch(url);
    const csvText = await response.text();
    const csvProducts = Papa.parse(csvText, { header: true }).data as unknown as CsvProduct[];

    return csvProducts.map(
      (p) =>
        ({
          catalogueReference: p.REFCAT,
          color: p.COULEUR,
          consuelPrice: Number(p.OPT_SBE),
          erpPrice: Number(p.TARIF_OPT_ERP),
          extension50mPrice: Number(p.TARIF_OPT_RACCORD_50M),
          extensionPrice: Number(p.OPT_RAL),
          listPrice: Number(p.PRIX_CATALOGUE),
          maxPrice: Number(p.PVC_MAX),
          maxPriceRatio: Number(p.COEFF_PRIX_MAX),
          minPrice: Number(p.PVC_MINI),
          minPriceRatio: Number(p.COEFF_PRIX_MIN),
          name: p.DESIGNATION_REF_CAT,
          power: Number(p.PUISSANCE),
          productQuantity: Number(p.QTE_PRD_EDILIAN),
          surface: Number(p.SURFACE),
          tileType: p.TYPE_TLE_PHO as TileType,
          totalPower: Number(p.PUISSANCE_R_TOTALE),
        }) as Product
    );
  } catch (error) {
    console.error('Error reading reference file:', error);
    return [];
  }
};
