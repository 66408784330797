import { Autocomplete, Button, Modal, styled, TextField } from '@mui/material';
import React from 'react';
import { ModalContent } from '../../../components/ModalContent';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/User';

interface AddModalProps {
  title: string;
  isModalOpen: boolean;
  setIsEditModalOpen: (value: boolean) => void;
  users: User[];
  newManager?: User;
  setNewManager: (value: any) => void;
  updateManager: () => void;
}

export const SelectManagerModal = ({
  title,
  isModalOpen,
  setIsEditModalOpen,
  users,
  newManager,
  setNewManager,
  updateManager,
}: AddModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={isModalOpen}>
      <SModalContent>
        <div className="mb-5 mt-7">
          <Title className="text-center">{title}</Title>
        </div>

        <div className="mb-12 mt-6 flex justify-center ">
          <Autocomplete
            options={users}
            noOptionsText={t('all:studiesList.aucunUtilisateurTrouve')}
            getOptionLabel={(option) =>
              option.firstName + ' ' + option.lastName + ' ' + option.email
            }
            sx={{ width: 420 }}
            onChange={(_, value) => setNewManager(value)}
            renderInput={(params) => (
              <TextField {...params} label={t('admin:UsersManagement.searchUser')} />
            )}
          />
        </div>

        <div className="mt-8 flex justify-end">
          <div className="mr-1">
            <SButton variant="adminGrey" onClick={() => setIsEditModalOpen(false)}>
              {t('common:cancel')}
            </SButton>
          </div>
          <div className="ml-1">
            <SButton
              variant="adminGreen"
              disabled={!newManager}
              type="submit"
              onClick={updateManager}>
              {t('common:validate')}
            </SButton>
          </div>
        </div>
      </SModalContent>
    </Modal>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 15px;
`;

const SButton = styled(Button)`
  width: 250px;
`;
