import React from 'react';
import { Controller } from 'react-hook-form';

import { Equipment } from './components/Equipment';
import { FormGridLayout } from './components/FormGridLayout';
import { handleMultipleSelect } from '../../util/formMultipleSelect';

export const NewSimulationAnnexes = ({ control, getValues }: { control: any; getValues: any }) => {
  return (
    <Controller
      name="annexes"
      render={({ field }) => (
        <FormGridLayout
          withMobileLabel
          labelTranslationKey="annexes"
          items={[
            <Equipment
              equipmentName="airConditioning"
              isSelectedAtStart={getValues('annexes')?.includes('airConditioning')}
              icon="\ue902"
              onClick={() => handleMultipleSelect(field, 'airConditioning', getValues)}
            />,
            <Equipment
              equipmentName="stove"
              isSelectedAtStart={getValues('annexes')?.includes('stove')}
              icon="\ue900"
              onClick={() => handleMultipleSelect(field, 'stove', getValues)}
            />,
          ]}
        />
      )}
      control={control}
    />
  );
};
