import React from 'react';
import Popover from '@mui/material/Popover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { Divider, ListItemText, MenuItem, MenuList, Paper } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../style/colors';
import { clientRoutes } from '../routing/clientRoutes';
import { useAuth } from '../auth/authContext';
import { User } from '../types/User';
import { getData } from '../firebase/firestoreQueries';
import { usersCollection } from '../firebase/firestoreCollections';

export const AdminSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useAuth();

  const [userData, setUserData] = React.useState<User>();

  React.useEffect(() => {
    (async () => {
      if (user) {
        setUserData(await getData(usersCollection, user.uid));
      }
    })();
  }, [user]);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  if (!userData?.isAdmin) {
    return null;
  }

  return (
    <div>
      <div {...bindHover(popupState)} className="flex">
        <Typography color="primary" sx={{ fontSize: 15 }} fontWeight="bold">
          {t('header.reglages')}
        </Typography>
        <KeyboardArrowDownIcon sx={{ color: COLORS.darkerGrey }} />
      </div>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Paper sx={{}}>
          <MenuList>
            <MenuItem sx={{ padding: 1.5 }}>
              <ListItemText>
                <Line onClick={() => navigate(clientRoutes.admin)}>
                  {t('header.parametrageDonnees')}
                </Line>
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem sx={{ padding: 1.5 }}>
              <ListItemText>
                <Line onClick={() => navigate(clientRoutes.usersManagement)}>
                  {t('header.parametrageUtilisateurs')}
                </Line>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </div>
  );
};

const Line = styled('span')`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
`;
