import React from 'react';
import { UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from 'firebase/firestore';

import { ProductForm } from './ProductFormHelper';
import { useNewStudy } from '../contexts/NewStudyContext';
import { snackError, snackSuccess } from '../util/snacks';
import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection } from '../firebase/firestoreCollections';

export const AddKitButton = ({
  handleSubmit,
  reset,
}: {
  handleSubmit: UseFormHandleSubmit<ProductForm>;
  reset: UseFormReset<ProductForm>;
}) => {
  const { t } = useTranslation();
  const { studyContext, setStudyContext } = useNewStudy();

  const onSubmit = (data: ProductForm) => {
    const current = studyContext.currentProduct;
    if (
      current &&
      !studyContext.chosenProducts.some((p) => p.catalogueReference === current.catalogueReference)
    ) {
      const newChosenProducts = studyContext.chosenProducts.concat([
        {
          ...current,
          ...(data.sellPrice && { sellPrice: data.sellPrice }),
          ...(data.connectionPrice && { connectionPrice: data.connectionPrice }),
        },
      ]);

      updateData(studiesCollection, {
        uid: studyContext.study?.uid,
        chosenProducts: newChosenProducts,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      setStudyContext({
        ...studyContext,
        chosenProducts: newChosenProducts,
      });
      if (newChosenProducts.length <= 4) {
        // @ts-ignore
        reset({ sellPrice: '', connectionPrice: '' });
        snackSuccess(t('product:kits.kitAdded'));
      }
    } else {
      snackError(t('product:kits.kitAlreadyAdded'));
    }
  };

  return (
    <div className="flex h-full items-center justify-end">
      <SButton
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        endIcon={<FontAwesomeIcon icon={faPlus} />}>
        {t('product:price.ajouterComparatif')}
      </SButton>
    </div>
  );
};

const SButton = styled(Button)`
  height: 70px;
  width: 228px;
`;
