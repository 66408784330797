import React from 'react';
import { CircularProgress, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { StudyFormValues } from '../NewSimulationFormHelper';

export const FormProgress = ({ watchedFields }: { watchedFields: StudyFormValues }) => {
  const { t } = useTranslation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  let filledFieldsCount = 1;
  for (const field in watchedFields) {
    if (
      // @ts-ignore
      watchedFields[field] &&
      // @ts-ignore
      watchedFields[field] !== '' &&
      // @ts-ignore
      !(Array.isArray(watchedFields[field]) && watchedFields[field].length === 0) &&
      !(field === 'annexes') &&
      !(field === 'equipments')
    ) {
      filledFieldsCount++;
    }
  }

  const progress = (filledFieldsCount / 12) * 100;
  const progressValue = progress > 100 ? 100 : progress;

  if (!isWeb) {
    return null;
  }

  return (
    <CircularProgressContainer>
      <ProgressTitle>{t('progress')}</ProgressTitle>
      <CircleContainer>
        <CircularProgress
          variant="determinate"
          value={progressValue}
          thickness={5}
          size={80}
          sx={{
            '.MuiCircularProgress-circleDeterminate': {
              backgroundColor: 'green', // Change the color of the determinate progress
            },
          }}
        />
      </CircleContainer>
    </CircularProgressContainer>
  );
};

const CircularProgressContainer = styled('div')`
  position: fixed;
  top: 110px;
  right: 30px;
  width: 140px;
  height: 132px;
  border-radius: 10px;
  box-shadow: 0 0 15px #edeef3;
  background: #ffffff;
  padding: 10px 20px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const CircleContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
`;

const ProgressTitle = styled('h3')`
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
`;
