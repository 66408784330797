import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/raleway/Raleway-Regular.ttf') format("truetype");
    }

    // italic
    @font-face {
        font-family: 'Raleway';
        font-style: italic;
        font-weight: normal;
        src: url('/fonts/raleway/Raleway-Italic.ttf') format("truetype");
    }

    // Bold
    @font-face {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: bold;
        src: url('/fonts/raleway/Raleway-Bold.ttf') format("truetype");
    }

    // Blod italic
    @font-face {
        font-family: 'Raleway';
        font-style: italic;
        font-weight: bold;
        src: url('/fonts/raleway/Raleway-BoldItalic.ttf') format("truetype");
    }

    // Thin
    @font-face {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 300;
        src: url('/fonts/raleway/Raleway-Light.ttf') format("truetype");
    }

    // Blod italic
    @font-face {
        font-family: 'Raleway';
        font-style: italic;
        font-weight: 300;
        src: url('/fonts/raleway/Raleway-LightItalic.ttf') format("truetype");
    }
    
    body,
    html,
    a {
        font-family: 'Raleway', sans-serif;
        color: var(--text-primary);
        font-feature-settings: "lnum" 1;
        //color: #333333;
    }
    
    :root {
        --white: #ffffff;
        --blue: #0b5c94;
        --grey: #939598;
        --black: #000000;
        --red: #af1e23;
        --purple: #27236d;
        --grey-purple: #e8e8f1;
        --light-purple: #1f3167;
        --brown: #8b5351;
        --darker-blue: #005baa;
        --grey-blue: #395a85;
        --light-grey-blue: #4e6ba0;
        --cyan-blue: #1a5b91;
        --lighterGrey: #d4d4e5;
        --lightGreen: #a8ce60;
        
        --text-primary: #333333;

        --primary-color: #303575;
        --secondary-color: #395a87;
        --tertiary-color: #fff;
        --light-color: #6aa4a5;
        --background-secondary-color: #edeef3;
        --danger-color: #bc7466;
        --success-color: #b5cc63;
        --result-success: #b5cc63;
        --result-primary: #0b5c94;

        --line-chart-background-color: transparent;
        --line-chart-border-color: #303575;
        --line-chart-point-background-color: #ccc;

        --stacked-chart-background-color-one: #5d906e;
        --stacked-chart-background-color-two: #af1e23;
        --stacked-chart-background-color-three: #0b5c94;


        --background-primary: #ffffff;
        --background-secondary: #e8e8f1;
        --background-inverse: #0b5c94;
        --background-tertiary: #27236d;
        
        --statut-color: #5d906e;
        --montant-economise: #e02e35;

        --boxShadow: 0px 0px 5px #d4d4e5;
    }
    
    //In order to make google maps Autocomplete dropdown visible when in tooltip
    .pac-container { z-index: 99999 !important; }

    /*  Household appliances icons  */
    @font-face {
        font-family: 'solaire';
        src: url('/fonts/solaire.ttf') format('truetype'),
        url('/fonts/solaire.woff') format('woff'),
        url('/fonts/solaire.svg') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
    
    
    /* LEGACY structure.scss */
    html,
    body,
    #root {
        min-height: 100%;
        height: 100%;
        font-size: 14px;
    }

    * {
        outline: none;
    }

    a:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    h1 {
        font-size: 28px;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        font-size: 21px;
        font-weight: bold;
        margin: 0;
    }

    h3 {
        margin: 0;
        font-size: 17px;
        font-weight: bold;
    }
    
    h4 {
        font-size: 15px;
        font-weight: bold;
    }
    
    h6 {
        font-weight: bold;
    }

    p {
        margin: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding-left: 0;

        > *:not(:last-child) {
            margin-bottom: 10px;
        }

        &.with-dots li {
            padding-left: 1.5rem;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                border: solid 2px var(--list-dot-color);
            }
        }
    }

    a {
        text-decoration: none;
        color: var(--text-primary);
    }

    li {
        list-style: none;
    }

    /*    Orientation icon    */

    .orientation-container {
        display: flex;
        height: 80px;
        width: 60px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nord {
        display: block;
        width: 50px;
        text-align: center;
        font-size: 0.8em;
        opacity: 0.8;
    }

    .orientation-icon {
        transition: all 0.3s;
        position: relative;
        width: 50px;
        height: 50px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: hidden;
        transform: rotate(0deg);

        &.NC {
            .shadow {
                opacity: 0;
            }
        }

        &.E {
            transform: rotate(-90deg);
        }
        &.ESE {
            transform: rotate(-77.5deg);
        }
        &.SE {
            transform: rotate(-45deg);
        }
        &.SSE {
            transform: rotate(-22.5deg);
        }
        &.S {
            transform: rotate(0deg);
        }
        &.SSW {
            transform: rotate(22.5deg);
        }
        &.SW {
            transform: rotate(45deg);
        }
        &.WSW {
            transform: rotate(77.5deg);
        }
        &.W {
            transform: rotate(90deg);
        }

        .point,
        .shadow {
            grid-column: 1;
            grid-row: 1;
            display: block;
        }

        .point {
            margin: auto;
            position: relative;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #27236d;
            border: 2px solid #fff;
            box-shadow: 0 0 5px #d4d4e5;
            z-index: 2;
        }
        .shadow {
            transition: all .3s;
            position: absolute;
            top: 19px;
            left: 4px;
            z-index: 1;
            height: 60px;
            width: 42px;
            clip-path: polygon(50% 0, 0 100%, 100% 100%);
            background: linear-gradient(to bottom, #27236d 3%, transparent 48%);
        }
    }
    /*  End orientation icon  */

    /*  slope icon  */
    .triangle {
        transition: all 0.3s;
        position: relative;
        height: 30px;
        width: 30px;
        background: #27236d;
        box-sizing: border-box;
    }
    /*  End slope icon  */

    /* Icon selection */
    .option {
        transition: all .3s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 10px 15px;
        border: 1px solid #e8e8f1;
        border-radius: 10px;
        cursor: pointer;

        span {
            font-size: 35px;
            font-family: solaire, sans-serif;
            color: #27236d;
        }

        p {
            text-align: center;
            margin-top: 10px;
            overflow-wrap: anywhere;
        }

        .compteur {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: auto;
            justify-content: space-between;

            .minus-plus-btn {
                border-radius: 50%;
                background-color: #e8e8f1;
                width: 15px;
                height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                border: none;
                color: #27236d;
            }
        }

        &.selected {
            border-color: #27236d;
            box-shadow: 0 0 0 1px #27236d;
        }
    }
    /* End icon selection */

    /*  Buttons  */

    .btn {
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        background-color: var(--grey-purple);
        color: #333333;
        margin: 0;
        transition: all 0.3s;
        position: relative;
        text-decoration: none;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        font-family: inherit;
        cursor: pointer;
    }

    .go-back-btn {
        padding: 12px 44px;
        border-radius: 30px;
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
        }
    }
    /*  End buttons  */

    .home-welcome {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: linear-gradient(to right, var(--primary-color) 65%, var(--background-inverse) 65%);
        height: 174px;

        .build-info {
            color: var(--tertiary-color);
            font-size: 9px;
            margin-bottom: 10px;
        }

        h1 {
            color: var(--tertiary-color);
        }

        button {
            background-color: var(--background-primary);
            height: 36px;
            padding: 0 20px;
            border: none;
            border-radius: 20px;
            font-weight: bold;
            font-size: 15px;
        }
    }
`;
