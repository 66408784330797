import { styled } from '@mui/material';
import { MaterialDesignContent } from 'notistack';
import { COLORS } from './colors';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: COLORS.darkGreen,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: COLORS.red,
  },
}));
