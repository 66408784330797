import {
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { v4 } from 'uuid';
import _ from 'lodash';

import { studiesCollection, usersCollection } from './firestoreCollections';
import { User } from '../types/User';
import { Study } from '../types/Study';
import { snackError } from '../util/snacks';

export const createUser = (user: User) => {
  const ref = doc(usersCollection, user.uid);

  return setDoc(ref, {
    ...user,
    isActive: user.isActive || false,
    creationDate: Timestamp.fromDate(new Date()),
  });
};

export const setStudy = (study: Study) => {
  const ref = doc(studiesCollection, study.uid);
  return setDoc(ref, study);
};

export const setData = async <T>(collection: CollectionReference<T>, data: T) => {
  try {
    // @ts-ignore
    const uid: string = data.uid || v4();
    const dataWithUid = { ...data, uid };
    const cleanData = _.omitBy(dataWithUid, _.isNil);
    const ref = doc(collection, uid);
    // @ts-ignore
    await setDoc(ref, cleanData, { merge: true });
    return uid;
  } catch (error) {
    console.error(error);
    snackError();
  }
};

export const updateData = async <T>(collection: CollectionReference<T>, data: Partial<T>) => {
  try {
    // @ts-ignore
    const uid: string = data.uid;
    if (!uid) {
      console.error('updateData without uid. Data:\n' + JSON.stringify(data));
      snackError();
      return;
    }
    const dataWithUid = { ...data, uid };
    const cleanData = _.omitBy(dataWithUid, _.isNil);
    const ref = doc(collection, uid);
    // @ts-ignore
    await updateDoc(ref, cleanData);
    return uid;
  } catch (error) {
    console.error(error);
    snackError();
  }
};

export const removeField = async <T>(
  collection: CollectionReference<T>,
  fieldName: string,
  uid?: string
) => {
  try {
    if (!uid) {
      console.error('Delete field without uid');
      snackError();
      return;
    }
    const ref = doc(collection, uid);
    // @ts-ignore
    await updateDoc(ref, {
      [fieldName]: null,
    });

    return uid;
  } catch (error) {
    console.error(error);
    snackError();
  }
};

export const getData = async <T>(collection: CollectionReference<T>, uid: string) => {
  // @ts-ignore
  const ref = doc(collection, uid);
  // @ts-ignore
  const data = await getDoc(ref, uid);
  return data.data() as T | undefined;
};

export const getAllData = async <T>(collection: CollectionReference<T>) => {
  const data = await getDocs(collection);
  return data.docs.map((d) => {
    return d.data() as T;
  });
};

export const deleteData = async <T>(collection: CollectionReference<T>, uid: string) => {
  // @ts-ignore
  const ref = doc(collection, uid);
  // @ts-ignore
  await deleteDoc(ref, uid);
  return uid;
};
