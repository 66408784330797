import React from 'react';
import { Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ModalContent } from '../components/ModalContent';
import { useNewStudy } from '../contexts/NewStudyContext';

export const MaxKitsAlertModal = () => {
  const { t } = useTranslation();
  const { studyContext, setStudyContext } = useNewStudy();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (studyContext.chosenProducts.length > 4) {
      setStudyContext({ ...studyContext, chosenProducts: studyContext.chosenProducts.slice(0, 4) });
      setIsModalOpen(true);
    }
  }, [studyContext.chosenProducts]);

  return (
    <Modal open={isModalOpen}>
      <SModalContent>
        <ImageContainer>
          <Image src="/images/warning.png" alt={t('common.warning')} />
        </ImageContainer>

        <div className="mb-5 mt-7">
          <Title className="text-center">{t('product:maxSelectionModal.titre')}</Title>
        </div>

        <div className="flex justify-center">
          <p>{t('product:maxSelectionModal.content')}</p>
        </div>

        <div className="mt-6 flex justify-center">
          <SButton onClick={() => setIsModalOpen(false)} variant="outlined">
            {t('common:ok')}
          </SButton>
        </div>
      </SModalContent>
    </Modal>
  );
};

const SModalContent = styled(ModalContent)`
  max-width: 630px;
  padding: 30px 50px 32px;
`;

const Title = styled('h2')``;

const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const Image = styled('img')`
  max-height: 84px;
`;

const SButton = styled(Button)`
  width: 130px;
`;
