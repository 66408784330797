import { Breadcrumbs, useMediaQuery } from '@mui/material';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styled from 'styled-components';

import { COLORS } from '../style/colors';
import { clientRoutes } from '../routing/clientRoutes';
import { useNewStudy } from '../contexts/NewStudyContext';

export const StudyAppHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { studyContext } = useNewStudy();
  const { pathname } = useLocation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const adaptedPathname = pathname.includes(clientRoutes.editSimulation(''))
    ? clientRoutes.simulationProducts
    : pathname;

  const { results, economicModels, chosenProducts } = studyContext;
  const title = studyContext.study?.title || '';

  const links = [
    { name: 'configuration', url: clientRoutes.newSimulation, isActive: true },
    { name: 'results', url: clientRoutes.results, isActive: !!results },
    {
      name: 'products',
      url: clientRoutes.simulationProducts,
      isActive: economicModels?.length > 0,
    },
    { name: 'synthesis', url: clientRoutes.synthesis, isActive: chosenProducts?.length > 0 },
  ];

  if (isWeb) {
    return (
      <AppBar position="sticky" sx={{ backgroundColor: 'white', padding: 2 }}>
        <Toolbar>
          <FontAwesomeIcon
            icon={faHome}
            color={COLORS.primaryColor}
            size="2x"
            onClick={() => navigate(clientRoutes.home)}
            style={{ cursor: 'pointer', marginRight: 15 }}
          />
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            {links.map((link) =>
              adaptedPathname === link.url ? (
                <p key={link.name} className="text-xl font-bold">
                  {t(`breadCrumb.${link.name}`)}
                </p>
              ) : link.isActive ? (
                <Link key={link.name} to={link.url} className="flex items-center">
                  <p className="text-xl text-gray-400 hover:underline">
                    {t(`breadCrumb.${link.name}`)}
                  </p>
                </Link>
              ) : (
                <p key={link.name} className="text-xl text-gray-400">
                  {t(`breadCrumb.${link.name}`)}
                </p>
              )
            )}
          </Breadcrumbs>
          <Title>{title ? title : t('newSimulation')}</Title>:
        </Toolbar>
      </AppBar>
    );
  } else {
    return (
      <AppBar position="sticky" sx={{ backgroundColor: 'white', paddingTop: '5px' }}>
        <Toolbar
          sx={{
            paddingTop: '5px',
            paddingLeft: '2px',
            paddingRight: '2px',
          }}>
          <div className="flex w-full flex-col items-center">
            {links.length > 1 && (
              <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
                {links.map((link) =>
                  adaptedPathname === link.url ? (
                    <p key={link.name} className="font-bold">
                      {t(`breadCrumbShort.${link.name}`)}
                    </p>
                  ) : link.isActive ? (
                    <Link key={link.name} to={link.url} className="flex items-center">
                      <p className="text-gray-400 hover:underline">
                        {t(`breadCrumbShort.${link.name}`)}
                      </p>
                    </Link>
                  ) : (
                    <p key={link.name} className="text-gray-400">
                      {t(`breadCrumbShort.${link.name}`)}
                    </p>
                  )
                )}
              </Breadcrumbs>
            )}
            <div className="mb-4 mt-2 flex w-full items-baseline">
              <div className="ml-10 flex flex-1 justify-start">
                <FontAwesomeIcon
                  icon={faHome}
                  color={COLORS.primaryColor}
                  onClick={() => navigate(clientRoutes.home)}
                  style={{ cursor: 'pointer', fontSize: 22 }}
                />
              </div>
              <div className="flex flex-1">
                <MobileTitle>{title ? title : t('newSimulation')}</MobileTitle>:
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
};

const Title = styled('span')`
  color: var(--primary-color);
  margin: 0 auto 0 25%;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 25px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const MobileTitle = styled('span')`
  color: var(--primary-color);
  font-size: 25px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-top: 10px;
`;
