import React from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Modal,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

import { useNewStudy } from '../contexts/NewStudyContext';
import { COLORS } from '../style/colors';
import { PowerAndModel } from '../productsChoice/PowerAndModel';
import { KitsBox } from '../components/KitsBox';
import { Trans, useTranslation } from 'react-i18next';
import useDimensions from '../util/useDimensions';
import { ModalContent } from '../components/ModalContent';
import { MyInput } from '../components/MyInput';
import { StudyAppHeader } from '../components/StudyAppHeader';
import { serverRoutes } from '../routing/serverRoutes';
import { snackError, snackSuccess } from '../util/snacks';
import { getData } from '../firebase/firestoreQueries';
import { studiesCollection } from '../firebase/firestoreCollections';
import { ScrollToTop } from '../util/ScrollToTop';

type FormValues = {
  email: string;
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required('requiredField').email('invalidEmailAddress'),
  })
  .required();

export const Synthesis = () => {
  const { studyContext, setStudyContext } = useNewStudy();
  const { t } = useTranslation('product');
  const { height } = useDimensions();
  const { uid: uidParam } = useParams();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
  const [shouldDisplayProfitability, setShouldDisplayProfitability] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [shouldDisplayEmailForm, setShouldDisplayEmailForm] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (
        (uidParam && studyContext.study?.uid !== uidParam) ||
        (!uidParam && studyContext.study?.uid)
      ) {
        const study = await getData(studiesCollection, uidParam || studyContext.study?.uid || '');

        if (study) {
          setStudyContext({
            study: study,
            results: study.results,
            chosenProducts: study.chosenProducts || [],
            economicModels: study.economicModels || [],
            isCustomPowerSelected: !!study.isCustomPowerSelected,
            customPowerResults: study.customPowerResults,
            shouldDisplayPrice: study.shouldDisplayPrice ?? true,
            products: studyContext.products,
          });
        }
      }
    })();
  }, [uidParam]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const sendSynthesis = async (data: FormValues) => {
    setIsLoading(true);
    try {
      const rawResponse = await fetch(serverRoutes.sendSynthesisByMail, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          studyUid: studyContext.study?.uid,
          shouldDisplayProfitability,
        }),
      });
      if (rawResponse.ok) {
        snackSuccess('common:mailSent');
        setIsConfirmationModalOpen(false);
      } else {
        snackError();
      }
    } catch (e) {
      snackError();
    } finally {
      setIsLoading(false);
      setShouldDisplayEmailForm(false);
    }
  };

  const downloadSynthesis = async () => {
    setIsLoading(true);
    try {
      const rawResponse = await fetch(serverRoutes.downloadPdfSynthesis, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          studyUid: studyContext.study?.uid,
          shouldDisplayProfitability,
        }),
      });

      if (rawResponse.ok) {
        const blob = new Blob([await rawResponse.arrayBuffer()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // @ts-ignore
        link.download = 'etude_' + studyContext.study?.externalId + '.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsConfirmationModalOpen(false);
      } else {
        snackError();
      }
    } catch (e) {
      console.error('e', e);
      snackError();
    } finally {
      setIsLoading(false);
      setShouldDisplayEmailForm(false);
    }
  };

  const closeModal = () => {
    setIsConfirmationModalOpen(false);
    setIsLoading(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ScrollToTop />

      <StudyAppHeader />

      <Background style={{ minHeight: height }}>
        <PowerAndModel withAddModel />

        <Container>
          <BorderBlock>
            <h2>{t('kits.titleExport', { count: studyContext.chosenProducts.length })}</h2>
            <div className={isWeb ? 'flex flex-wrap' : 'mt-4 flex flex-wrap justify-center'}>
              <KitsBox />
            </div>
            <div className="mt-5">
              {studyContext.chosenProducts.some((p) => p.connectionPrice) && (
                <Trans i18nKey="product:kits.noticeServiceBranchementElectrique" />
              )}
            </div>
          </BorderBlock>
        </Container>

        <SButton onClick={() => setIsConfirmationModalOpen(true)} variant="contained">
          {t('synthesis.downloadSynthesis')}
        </SButton>
      </Background>

      <Modal open={isConfirmationModalOpen}>
        <SModalContent>
          <CloseIconContainer>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </CloseIconContainer>
          {isLoading ? (
            <Center>
              <CircularProgress />
              <span className="my-10">{t('synthesis.wait')}</span>
            </Center>
          ) : shouldDisplayEmailForm ? (
            <>
              <h2 className="mb-4 text-center">{t('synthesis.saisieEmail')}</h2>
              <SInput
                name="email"
                type="email"
                placeholder="email"
                errors={errors}
                control={control}
              />
              <div className="mt-3 flex">
                <div className="mr-1 flex flex-1">
                  <Button
                    onClick={() => setShouldDisplayEmailForm(false)}
                    variant="outlined"
                    size="large"
                    fullWidth>
                    {t('common:cancel')}
                  </Button>
                </div>
                <div className="ml-1 flex flex-1">
                  <Button
                    onClick={handleSubmit(sendSynthesis)}
                    variant="contained"
                    disabled={!isValid}
                    type="submit"
                    size="large">
                    {t('synthesis.sendSynthesis')}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <Button onClick={downloadSynthesis} variant="contained" sx={{ marginBottom: 2 }}>
                {t('synthesis.downloadSynthesisShort')}
              </Button>
              <Button onClick={() => setShouldDisplayEmailForm(true)} variant="outlined">
                {t('synthesis.sendSynthesis')}
              </Button>
              <div className="mt-4 flex items-center justify-start">
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={shouldDisplayProfitability}
                  onChange={() => setShouldDisplayProfitability(!shouldDisplayProfitability)}
                />
                <Label>{t('synthesis.shouldDisplayProfitability')}</Label>
              </div>
            </>
          )}
        </SModalContent>
      </Modal>
    </Box>
  );
};

const Background = styled('div')`
  background-color: ${COLORS.greyPurple};
  padding: 16px 12px 64px;
`;

const Container = styled('div')`
  background-color: ${COLORS.lighterGreyPurple};
  border-radius: 10px;
  padding: 5px;
`;

const BorderBlock = styled('div')`
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  padding: 18px;
  width: 100%;
`;

const SButton = styled(Button)`
  width: 100%;
  margin-top: 25px !important;
`;

const SModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  padding: 28px;
  width: 540px;
  max-width: 100%;
`;

const Label = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  margin-left: 7px;
`;

const CloseIconContainer = styled('div')`
  display: flex;
  justify-content: end;
  margin-right: -24px;
  margin-top: -20px;
  margin-bottom: 6px;
`;

const Center = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const SInput = styled(MyInput)`
  margin-bottom: 20px;
  width: 100%;
`;
