import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { Sector } from '../../types/AdminManagement';
import {
  sectorsCollection,
  sectorsCollectionName,
  usersCollection,
  usersCollectionName,
} from '../../firebase/firestoreCollections';
import { AppHeader } from '../../components/AppHeader';
import {
  ActionIconContainer,
  Background,
  BigPlusButton,
  Container,
  GoBackButton,
  GridElement,
  GridText,
  Subtitle,
} from './components/AdminComponents';
import { Autocomplete, Button, Divider, Grid, Modal, styled, TextField } from '@mui/material';
import { clientRoutes } from '../../routing/clientRoutes';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Department, departments } from '../../util/departments';
import { ModalContent } from '../../components/ModalContent';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { setData } from '../../firebase/firestoreQueries';
import { snackSuccess } from '../../util/snacks';
import { User } from '../../types/User';
import { TitleWithLabelEdit } from './components/TitleWithLabelEdit';

export const EditSector = () => {
  const { t } = useTranslation('admin');
  const { uid } = useParams();

  const [isManagerEditActionOpen, setIsManagerEditActionOpen] = useState(false);
  const [isEditManagerModalOpen, setIsEditManagerModalOpen] = useState(false);
  const [isDeleteDepartmentModalOpen, setIsDeleteDepartmentModalOpen] = useState<string>();
  const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
  const [isDeletePrescriptionModalOpen, setIsDeletePrescriptionModalOpen] = useState<User>();
  const [isDeleteDepartmentVisible, setIsDeleteDepartmentVisible] = useState<string>();
  const [newManager, setNewManager] = useState<User | null>();
  const [newLinkedDepartments, setNewLinkedDepartments] = useState<Department[]>([]);
  const [prescriptionAction, setPrescriptionAction] = useState<string>();
  const [isAddPrescriptionManagerModalOpen, setIsAddPrescriptionManagerModalOpen] = useState(false);
  const [newPrescriptionManagers, setNewPrescriptionManagers] = useState<User[]>([]);
  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection, {
    subscribe: true,
  });
  const internalUsers = (usersQuery.data || []).filter((u) => u.isInternal);

  const sectorQuery = useFirestoreQueryData<Sector>([sectorsCollectionName], sectorsCollection, {
    subscribe: true,
  });
  const sector = sectorQuery.data?.find((sector) => sector.uid === uid);

  const updateSectorLabel = async (newLabel: string) => {
    await setData(sectorsCollection, { ...sector, label: newLabel });
    snackSuccess();
  };

  const updateManager = async () => {
    if (!newManager) {
      return;
    }

    await setData(sectorsCollection, {
      ...sector,
      managerEmail: newManager.email,
      managerFirstName: newManager.firstName,
      managerLastName: newManager.lastName,
    });
    snackSuccess();
    setIsEditManagerModalOpen(false);
  };

  const updateDepartments = async () => {
    await setData(sectorsCollection, {
      ...sector,
      linkedDepartmentCodes: _.uniq(
        sector?.linkedDepartmentCodes.concat((newLinkedDepartments || []).map((d) => d.num_dep))
      ),
    });
    snackSuccess();
    setNewLinkedDepartments([]);
    setIsAddDepartmentModalOpen(false);
  };

  const updatePrescriptionManager = async () => {
    await setData(sectorsCollection, {
      ...sector,
      prescriptionManagers: _.uniqBy(
        (sector?.prescriptionManagers || []).concat(newPrescriptionManagers),
        'email'
      ),
    });
    snackSuccess();
    setNewPrescriptionManagers([]);
    setIsAddPrescriptionManagerModalOpen(false);
  };

  const deleteDepartment = async (departmentCode?: string) => {
    await setData(sectorsCollection, {
      ...sector,
      linkedDepartmentCodes: sector?.linkedDepartmentCodes.filter(
        (code) => code !== departmentCode
      ),
    });
    snackSuccess();
    setIsDeleteDepartmentModalOpen(undefined);
  };

  const deletePrescriptionManager = async (manager?: User) => {
    await setData(sectorsCollection, {
      ...sector,
      prescriptionManagers: sector?.prescriptionManagers.filter((m) => m.email !== manager?.email),
    });
    snackSuccess();
    setIsDeletePrescriptionModalOpen(undefined);
  };

  if (!sector) {
    return null;
  }

  const linkedDepartment = departments.filter((d) =>
    sector.linkedDepartmentCodes.includes(d.num_dep)
  );

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <TitleWithLabelEdit
            title={t('UsersManagement.sector')}
            label={sector?.label || ''}
            updateLabel={updateSectorLabel}
          />
          <Divider />
          <GoBackButton path={clientRoutes.sectorsManagement} />
          <Subtitle>{t('UsersManagement.sectorManager')}</Subtitle>
          <Grid container spacing={2} className="mb-10">
            <Grid item xs={6} lg={4}>
              <GridElement
                className="flex flex-col items-center"
                onMouseEnter={() => setIsManagerEditActionOpen(true)}
                onMouseLeave={() => setIsManagerEditActionOpen(false)}>
                <div className="relative flex w-full justify-center">
                  <GridText>
                    {_.upperFirst(sector.managerFirstName)} {_.upperFirst(sector.managerLastName)}
                  </GridText>
                  {isManagerEditActionOpen && (
                    <ActionsContainer>
                      <ActionIconContainer onClick={() => setIsEditManagerModalOpen(true)}>
                        <CreateIcon />
                      </ActionIconContainer>
                    </ActionsContainer>
                  )}
                </div>
                <GridText>{sector.managerEmail}</GridText>
              </GridElement>
            </Grid>
          </Grid>

          <Subtitle>{t('UsersManagement.prescriptionManagers')}</Subtitle>
          <Grid container spacing={2} className="mb-10">
            {(sector.prescriptionManagers || []).map((pManager) => (
              <Grid item xs={6} lg={4} key={pManager.email}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setPrescriptionAction(pManager.email)}
                  onMouseLeave={() => setPrescriptionAction(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>{sector.code}</GridText>
                    {prescriptionAction === pManager.email && (
                      <ActionsContainer>
                        <ActionIconContainer
                          onClick={() => setIsDeletePrescriptionModalOpen(pManager)}>
                          <DeleteIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <GridText>{pManager.email}</GridText>
                  <GridText>
                    {pManager.firstName} {pManager.lastName}
                  </GridText>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddPrescriptionManagerModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>

          <Subtitle>{t('UsersManagement.linkedDepartments')}</Subtitle>
          <Grid container spacing={2} className="mb-10">
            {linkedDepartment.map((department) => (
              <Grid item xs={6} lg={4} key={department.num_dep}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setIsDeleteDepartmentVisible(department.num_dep)}
                  onMouseLeave={() => setIsDeleteDepartmentVisible(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>
                      {department.num_dep} - {department.dep_name}
                    </GridText>
                    {isDeleteDepartmentVisible === department.num_dep && (
                      <ActionsContainer>
                        <ActionIconContainer
                          onClick={() => setIsDeleteDepartmentModalOpen(department.num_dep)}>
                          <DeleteIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddDepartmentModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>
        </Container>
      </Background>

      <Modal open={isEditManagerModalOpen}>
        <SModalContent>
          <div className="mb-10">
            <Title className="text-center">{t('UsersManagement.selectNewManager')}</Title>
          </div>

          <div className="mb-20 flex justify-center">
            <Autocomplete
              options={internalUsers}
              noOptionsText={t('all:studiesList.aucunUtilisateurTrouve')}
              getOptionLabel={(option) =>
                option.firstName + ' ' + option.lastName + ' ' + option.email
              }
              sx={{ width: 300 }}
              onChange={(_, value) => setNewManager(value)}
              renderInput={(params) => (
                <TextField {...params} label={t('UsersManagement.searchUser')} />
              )}
            />
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsEditManagerModalOpen(false)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                disabled={!newManager}
                type="submit"
                onClick={updateManager}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={!!isDeleteDepartmentModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.removeDepartmentFromSector')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">{t('UsersManagement.youCanAddItAgain')}</div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton
                variant="adminGrey"
                onClick={() => setIsDeleteDepartmentModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                onClick={() => deleteDepartment(isDeleteDepartmentModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={isAddDepartmentModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.addLinkedDepartments')}</Title>
          </div>

          <Autocomplete
            multiple
            options={departments.filter((d) => !sector.linkedDepartmentCodes.includes(d.num_dep))}
            noOptionsText={t('all:studiesList.aucunDepartementTrouve')}
            getOptionLabel={(department) => department.num_dep + ' - ' + department.dep_name}
            sx={{ width: 300 }}
            onChange={(_, value) => setNewLinkedDepartments(value)}
            renderInput={(params) => <TextField {...params} label={t('common:search')} />}
          />

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsAddDepartmentModalOpen(false)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                onClick={updateDepartments}
                disabled={!newLinkedDepartments?.length}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={isAddPrescriptionManagerModalOpen}>
        <SModalContent>
          <div className="mb-10">
            <Title className="text-center">{t('UsersManagement.addPrescriptionManagers')}</Title>
          </div>

          <div className="mb-20 flex justify-center">
            <Autocomplete
              multiple
              options={internalUsers.filter(
                (u) => !sector?.prescriptionManagers.map((m) => m.email).includes(u.email)
              )}
              noOptionsText={t('all:studiesList.aucunUtilisateurTrouve')}
              getOptionLabel={(option) =>
                option.firstName + ' ' + option.lastName + ' ' + option.email
              }
              sx={{ width: 400 }}
              onChange={(_, value) => setNewPrescriptionManagers(value)}
              renderInput={(params) => (
                <TextField {...params} label={t('UsersManagement.searchUser')} />
              )}
            />
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton
                variant="adminGrey"
                onClick={() => setIsAddPrescriptionManagerModalOpen(false)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                onClick={updatePrescriptionManager}
                disabled={!newPrescriptionManagers?.length}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={!!isDeletePrescriptionModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">
              {t('UsersManagement.removePrescriptionManagerFromSector')}
            </Title>
          </div>

          <div className="mb-5 mt-7 text-center">{t('UsersManagement.youCanAddItAgain')}</div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton
                variant="adminGrey"
                onClick={() => setIsDeletePrescriptionModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                onClick={() => deletePrescriptionManager(isDeletePrescriptionModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 70px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const ActionsContainer = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
`;
