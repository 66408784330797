import { EconomicModelEnum } from '../../types/EconomicModel.enum';
import { UseQueryResult } from 'react-query';
import { PowerRange } from '../../types/AdminSettings';

export const getModelPowerRangesValue = (
  model: EconomicModelEnum,
  powerRangesQuery: UseQueryResult<PowerRange[]>,
  modelAndRangeUid: string,
  valueName: string,
  value: unknown
) => {
  const powerRangeUid = modelAndRangeUid.replace(model, '');
  return {
    model: model,
    powerRangeUid,
    powerRangeMin:
      (powerRangesQuery.data || []).find((pr) => pr.uid === powerRangeUid)?.minPower || 0,
    powerRangeMax:
      (powerRangesQuery.data || []).find((pr) => pr.uid === powerRangeUid)?.maxPower || 0,
    [valueName]: value as number,
  };
};
