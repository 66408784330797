import { createTheme } from '@mui/material/styles';

import { COLORS } from './colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    notClickable: true;
    outlinedLogin: true;
    adminGreen: true;
    adminBlue: true;
    adminGrey: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primaryColor,
    },
    secondary: {
      main: COLORS.secondary,
    },
    info: {
      main: COLORS.tertiary,
    },
  },
});

theme = createTheme(theme, {
  palette: {
    primary: {
      main: COLORS.primaryColor,
    },
    info: {
      main: theme.palette.secondary.main,
    },
    error: {
      main: COLORS.error,
    },
  },
  components: {
    MuiDataGrid: {
      // Hack to avoid overlap when no rows: https://github.com/mui/mui-x/issues/3229
      styleOverrides: {
        root: {
          '.MuiDataGrid-overlayWrapper': {
            height: 'auto !important',
          },
          '.MuiDataGrid-overlayWrapperInner': {
            height: 'auto !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          textTransform: 'none',
          fontWeight: 'normal',
          borderRadius: 10,
          backgroundColor: COLORS.tertiary,
          borderColor: COLORS.tertiary,
          color: COLORS.white,
          '&:hover': {
            borderColor: COLORS.primary,
            backgroundColor: COLORS.primaryDark,
          },
        },
        outlinedPrimary: {
          color: COLORS.textPrimary,
          backgroundColor: COLORS.greyPurple,
          border: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: COLORS.outlinedButtonHover,
          },
        },
        outlinedLogin: {
          color: COLORS.textPrimary,
          backgroundColor: 'transparent',
          fontWeight: 'bold',
          border: '1px solid var(--text-primary)',
          '&:hover': {
            backgroundColor: 'var(--grey-purple)',
            fontWeight: 'bold',
          },
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: COLORS.disabledContainedButton,
            color: COLORS.white,
          },
          '&:hover': {
            color: COLORS.white,
          },
        },
        sizeLarge: {
          height: '48px',
        },
        sizeSmall: {
          height: '34px',
          fontWeight: 'normal',
        },
      },
      variants: [
        {
          props: { variant: 'notClickable' },
          style: {
            '&.Mui-disabled': {
              backgroundColor: COLORS.primary,
              color: COLORS.white,
            },
          },
        },
        {
          props: { variant: 'adminGreen' },
          style: {
            textTransform: 'uppercase',
            backgroundColor: COLORS.adminGreen1,
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: COLORS.adminGreen2,
            },
            '&.Mui-disabled': {
              color: COLORS.adminLightGrey,
            },
          },
        },
        {
          props: { variant: 'adminBlue' },
          style: {
            textTransform: 'uppercase',
            backgroundColor: COLORS.adminBluePrimary,
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: COLORS.adminBlue4,
            },
            '&.Mui-disabled': {
              color: COLORS.adminLightGrey,
            },
          },
        },
        {
          props: { variant: 'adminGrey' },
          style: {
            textTransform: 'uppercase',
            backgroundColor: COLORS.adminLightGrey,
            color: 'black',
            borderRadius: '4px',
            '&.Mui-disabled': {
              color: COLORS.adminLightGrey,
            },
            '&:hover': {
              backgroundColor: COLORS.greyPurple,
            },
          },
        },
      ],
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: '30px',
        },
      },
    },
  },
});

// export const muiTheme = theme;
export default theme;
