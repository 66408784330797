import React from 'react';
import { Controller, Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';

import { ProductForm } from './ProductFormHelper';
import { getClosestPowerProduct } from './getClosestPowerProduct';
import { useNewStudy } from '../contexts/NewStudyContext';
import { ProductColor, ProductType, TileType } from '../types/ProductTypes';
import { getImageName } from './getImageName';
import { readReferenceCsvFileFromStorage } from '../firebase/readReferenceCsvFileFromStorage';

const initialTileTypes = [
  {
    tileType: TileType.TerreCuiteSolaire,
    text: 'composition.tuilesTerreCuiteSolaires',
  },
  {
    tileType: TileType.SolaireMax,
    text: 'composition.tuilesSolairesMax',
  },
];

const initialColors = [
  {
    color: ProductColor.Noir,
    text: 'composition.ARDOISE',
  },
  {
    color: ProductColor.Rouge,
    text: 'composition.ROUGE',
  },
];

export const Composition = ({
  control,
  slope,
  setValue,
  watch,
}: {
  control: Control<ProductForm>;
  slope: string;
  setValue: UseFormSetValue<ProductForm>;
  watch: UseFormWatch<ProductForm>;
}) => {
  const { t } = useTranslation('product');
  const { studyContext, setStudyContext } = useNewStudy();

  const [tileTypes, setTileTypes] =
    React.useState<{ tileType: TileType; text: string }[]>(initialTileTypes);
  const [colors, setColors] =
    React.useState<{ color: ProductColor; text: string }[]>(initialColors);

  const watchedProductType = watch('type');
  const watchedProductModel = watch('productModel');
  const watchedColor = watch('color');

  React.useEffect(() => {
    if (studyContext.products.length) {
      return;
    }
    (async () => {
      const products = await readReferenceCsvFileFromStorage();
      setStudyContext({ ...studyContext, products });
    })();
  }, []);

  React.useEffect(() => {
    const power =
      (studyContext.isCustomPowerSelected
        ? studyContext.study?.customPower
        : studyContext.results?.recommendedPower) || 0;

    const closestPowerProduct = getClosestPowerProduct(
      studyContext.products,
      watchedColor,
      watchedProductModel,
      power
    );
    setStudyContext({ ...studyContext, currentProduct: closestPowerProduct });
  }, [studyContext.products, watchedProductModel, watchedColor]);

  const onProductTypeChange = (type: ProductType) => {
    setValue('color', ProductColor.Noir);
    if (type === ProductType.TuilesSolaires) {
      setTileTypes(initialTileTypes);
      setColors(initialColors);
      setValue('productModel', TileType.TerreCuiteSolaire);
    } else {
      if (slope === '0') {
        setValue('productModel', TileType.EasyroofFlat);
      } else {
        setValue('productModel', TileType.EasyroofAccess);
      }
      setColors([
        {
          color: ProductColor.Noir,
          text: 'composition.NOIR',
        },
      ]);
      if (slope === '0') {
        setTileTypes([
          {
            tileType: TileType.EasyroofFlat,
            text: 'composition.easyRoofFlat',
          },
        ]);
      } else {
        setTileTypes([
          {
            tileType: TileType.EasyroofAccess,
            text: 'composition.easyRoofAccess',
          },
          {
            tileType: TileType.EasyroofIntegration,
            text: 'composition.easyRoofIntegration',
          },
          {
            tileType: TileType.EasyroofTop,
            text: 'composition.easyRoofTop',
          },
        ]);
      }
    }
  };

  return (
    <>
      <h2 className="mb-5">{t('composition.titre')}</h2>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="flex flex-col pr-4">
            <div>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <>
                    <h3 className="mb-2">{t('composition.typeProduit')}</h3>
                    <Divider />
                    <Grid container spacing={2} mt={0.5} mb={2}>
                      <Grid item xs={12} md={6}>
                        <SButton
                          onClick={() => {
                            onProductTypeChange(ProductType.TuilesSolaires);
                            field.onChange(ProductType.TuilesSolaires);
                          }}
                          size="large"
                          variant={
                            field.value === ProductType.TuilesSolaires ? 'contained' : 'outlined'
                          }>
                          {t('composition.tuilesSolaires')}
                        </SButton>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SButton
                          onClick={() => {
                            onProductTypeChange(ProductType.PanneauxSolaires);
                            field.onChange(ProductType.PanneauxSolaires);
                          }}
                          size="large"
                          variant={
                            field.value === ProductType.PanneauxSolaires ? 'contained' : 'outlined'
                          }>
                          {t('composition.panneauxSolaires')}
                        </SButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                name="productModel"
                control={control}
                render={({ field }) => (
                  <>
                    <h3 className="mb-2"> {t('composition.modele')}</h3>
                    <Divider />
                    <Grid container spacing={2} mt={0.5} mb={2}>
                      {tileTypes.map((model) => (
                        <Grid item xs={12} md={6} key={model.tileType}>
                          <SButton
                            onClick={() => field.onChange(model.tileType)}
                            size="large"
                            variant={field.value === model.tileType ? 'contained' : 'outlined'}>
                            {t(model.text)}
                          </SButton>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                name="color"
                control={control}
                render={({ field }) => (
                  <>
                    <h3 className="mb-2">{t('composition.coloris')}</h3>
                    <Divider />
                    <Grid container spacing={2} mt={0.5} mb={2}>
                      {colors.map((color) => (
                        <Grid item xs={12} md={6} key={color.text}>
                          <SButton
                            onClick={() => field.onChange(color.color)}
                            size="large"
                            variant={field.value === color.color ? 'contained' : 'outlined'}>
                            {color.color === ProductColor.Noir ? <BlackCircle /> : <RedCircle />}
                            {t(color.text)}
                          </SButton>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              />
            </div>
          </div>
        </Grid>

        <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />

        <Grid item xs={12} md={6}>
          <ProductImageContainer className="pl-4" style={{}}>
            <div className="flex h-full flex-col justify-center">
              <div className="flex">
                <ProductImage
                  src={'/images/tiles/' + getImageName(studyContext.currentProduct)}
                  alt={studyContext.currentProduct?.tileType}
                />
                <div className="flex flex-col justify-evenly">
                  <ProductMatchInfo>
                    {t('composition.surface')} {studyContext.currentProduct?.surface}
                    {t('composition.squareMeter')}
                  </ProductMatchInfo>
                  <ProductMatchInfo>
                    {t('composition.quantite')} {studyContext.currentProduct?.productQuantity}{' '}
                    {watchedProductType === ProductType.PanneauxSolaires
                      ? t('composition.panneaux')
                      : t('composition.tuiles')}
                  </ProductMatchInfo>
                </div>
              </div>
            </div>
          </ProductImageContainer>
        </Grid>
      </Grid>
    </>
  );
};

const SButton = styled(Button)`
  width: 100%;
`;

const ProductImageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
`;

const Circle = styled('div')`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 7px;
`;

const RedCircle = styled(Circle)`
  background-color: var(--red);
`;

const BlackCircle = styled(Circle)`
  background-color: black;
`;

const ProductImage = styled('img')`
  height: 100px;
  margin-right: 40px;
`;

const ProductMatchInfo = styled('span')`
  font-size: 20px;
  font-weight: bold;
`;
