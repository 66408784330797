import React from 'react';
import { Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ModalContent } from '../../../components/ModalContent';
import { Step } from '../NewSimulation';

export const RoofExplanationsModal = ({
  isExplanationModalOpen,
  setStep,
}: {
  isExplanationModalOpen: boolean;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={isExplanationModalOpen}>
      <ModalContent>
        <ImageContainer>
          <Image src="/images/satellite.png" alt={t('checkList')} />
        </ImageContainer>

        <div className="mb-10">
          <h2 className="text-center">{t('RoofExplanationsModal.title')}</h2>
        </div>

        <div className="mb-5 mt-3 flex justify-center ">{t('RoofExplanationsModal.content')}</div>

        <div className="flex justify-end">
          <div className="m-2">
            <SButton onClick={() => setStep(Step.form)} variant="outlined">
              {t('RoofExplanationsModal.fillManually')}
            </SButton>
          </div>
          <div className="m-2">
            <SButton onClick={() => setStep(Step.roofSelector)} variant="contained">
              {t('ok')}
            </SButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Image = styled('img')`
  max-width: 150px;
  min-height: 100px;
`;

const SButton = styled(Button)`
  width: 100%;
`;
