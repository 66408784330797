import { Product } from '../types/ProductTypes';

export const getClosestPowerProduct = (
  produits: Product[],
  color: string,
  tileType: string,
  power: number
): Product => {
  return produits
    .filter((p) => p.color === color && p.tileType === tileType)
    .sort((produit1, produit2) => {
      if (Math.abs(produit1.power - power) <= Math.abs(produit2.power - power)) {
        return -1;
      }
      return 1;
    })?.[0];
};
