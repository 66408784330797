import React from 'react';
import { Divider, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppHeader } from '../../components/AppHeader';
import { clientRoutes } from '../../routing/clientRoutes';
import {
  Background,
  Container,
  GoBackButton,
  GridElement,
  GridText,
} from './components/AdminComponents';

export const UsersManagementIndex = () => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();

  const usersBoxes = [
    { name: t('UsersManagement.admins'), link: clientRoutes.adminsManagement },
    { name: t('UsersManagement.managers'), link: clientRoutes.managersManagement },
    { name: t('UsersManagement.professionals'), link: clientRoutes.professionalManagement },
  ];
  const sectorsBoxes = [
    { name: t('UsersManagement.solarSectors'), link: clientRoutes.solarSectorsManagement },
    { name: t('UsersManagement.salesAreas'), link: clientRoutes.salesAreasManagement },
    { name: t('UsersManagement.sectors'), link: clientRoutes.sectorsManagement },
  ];

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <h1 className="mb-2">{t('UsersManagement.settings')}</h1>

          <Divider />

          <GoBackButton path={clientRoutes.home} />

          <h2 className="mb-4">{t('common:users')}</h2>
          <Grid container spacing={2} className="mb-10">
            {usersBoxes.map((box) => (
              <SGridElement
                className="m-5 flex flex-1 cursor-pointer flex-col items-center"
                onClick={() => navigate(box.link)}>
                <GridText>{box.name}</GridText>
              </SGridElement>
            ))}
          </Grid>

          <h2 className="mb-4">{t('UsersManagement.sectorsAndRegions')}</h2>
          <Grid container spacing={2} className="mb-10">
            {sectorsBoxes.map((sector) => (
              <SGridElement
                className="m-5 flex flex-1 cursor-pointer flex-col items-center"
                onClick={() => navigate(sector.link)}>
                <GridText>{sector.name}</GridText>
              </SGridElement>
            ))}
          </Grid>
        </Container>
      </Background>
    </>
  );
};

const SGridElement = styled(GridElement)`
  height: 70px;
  justify-content: center;
`;
