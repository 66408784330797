import styled from 'styled-components';
import { COLORS } from '../style/colors';

export const ModalContent = styled('div')`
  background-color: ${COLORS.white};
  border-radius: 10px;
  max-width: 800px;
  min-height: 350px;
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;
