export const usersExportHeader = [
  'Nom complet',
  'Raison sociale',
  'Siret',
  'Civilite',
  'Email',
  'Adresse',
  'Code postal',
  'Ville',
  'Pays',
  'Numéro de téléphone',
  'Actif',
  'Date de création',
];
