import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { getFirestore } from 'firebase/firestore';
// import { getPerformance } from 'firebase/performance';
// import { getAnalytics } from 'firebase/analytics';

import { firebaseConfig } from './firebase.config';

export const firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = getFirestore(firebase);
// export const perf = getPerformance(firebase);
// export const analytics = getAnalytics(firebase);
initializeApp(firebaseConfig);
