import { MyInput } from '../../components/MyInput';
import { InputAdornment } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { PowerRange } from '../../types/AdminSettings';
import {
  powerRangesCollection,
  powerRangesCollectionName,
} from '../../firebase/firestoreCollections';
import { useTranslation } from 'react-i18next';
import { EconomicModelEnum } from '../../types/EconomicModel.enum';
import { Control, FieldErrors } from 'react-hook-form';

export const PowerRangesInput = ({
  model,
  errors,
  control,
  inputAdornment,
}: {
  model: EconomicModelEnum;
  errors: FieldErrors<any>;
  control: Control<any>;
  inputAdornment: string;
}) => {
  const { t } = useTranslation('admin');

  const powerRangesQuery = useFirestoreQueryData<PowerRange>(
    [powerRangesCollectionName],
    powerRangesCollection
  );

  const powerRangesUid = (powerRangesQuery.data || [])
    .sort((pr0, pr1) => pr0.minPower - pr1.minPower)
    .map((pr) => pr.uid);

  return powerRangesUid.map((powerRangeUid) => (
    <div className="flex items-center" key={powerRangeUid}>
      <div className="flex" style={{ flex: 9 }}>
        {(powerRangesQuery.data || []).find((pr) => pr.uid === powerRangeUid)?.minPower}
        {' - '}
        {(powerRangesQuery.data || []).find((pr) => pr.uid === powerRangeUid)?.maxPower}
        {t('kiloWattPeak')}
      </div>
      <div className="flex flex-1" style={{ flex: 6 }}>
        <InputContainer>
          <MyInput
            name={model + powerRangeUid}
            errors={errors}
            control={control}
            type="number"
            withoutLabel
            fullWidth
            withZeroPossible
            InputProps={{
              endAdornment: <InputAdornment position="end">{inputAdornment}</InputAdornment>,
            }}
            style={{
              backgroundColor: 'white',
              marginLeft: '-5px',
            }}
          />
        </InputContainer>
      </div>
    </div>
  ));
};

const InputContainer = styled('div')`
  width: 145px;
`;
