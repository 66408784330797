import React from 'react';
import { Button, Checkbox, Modal } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ModalContent } from '../components/ModalContent';
import { goToSynthesis } from './goToSynthesis';
import { useNavigate } from 'react-router-dom';
import { useNewStudy } from '../contexts/NewStudyContext';

export const LessThan4KitsModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const { t } = useTranslation('product');
  const navigate = useNavigate();
  const { studyContext } = useNewStudy();

  const [doNotDisplayMessageAgain, setDoNotDisplayMessageAgain] = React.useState(false);

  const onDoNotDisplayMessageAgainClick = (value: boolean) => {
    setDoNotDisplayMessageAgain(value);
    if (value) {
      localStorage.setItem('dontShow4KitsAlert', 'true');
    } else {
      localStorage.removeItem('dontShow4KitsAlert');
    }
  };

  return (
    <Modal open={isOpen} onClose={close}>
      <SModalContent>
        <h2 className="text-center">{t('compareModal.titre')}</h2>

        <div className="mt-5 flex justify-center text-center">{t('compareModal.content')}</div>

        <div className="my-3 flex items-center justify-center">
          <Checkbox
            value={doNotDisplayMessageAgain}
            onChange={() => onDoNotDisplayMessageAgainClick(!doNotDisplayMessageAgain)}
          />
          {t('compareModal.doNotDisplayAgain')}
        </div>

        <div className="flex">
          <div className="m-2 w-full">
            <SButton variant="contained" onClick={close}>
              {t('compareModal.ajouterAutreKit')}
            </SButton>
          </div>
          <div className="m-2 w-full">
            <SButton
              variant="outlined"
              sx={{ fontWeight: 'normal' }}
              onClick={() => goToSynthesis(studyContext, navigate)}>
              {t('compareModal.continuerAvecCesKit')}
            </SButton>
          </div>
        </div>
      </SModalContent>
    </Modal>
  );
};

const SButton = styled(Button)`
  width: 100%;
`;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  max-width: 650px;
  padding: 30px;
`;
