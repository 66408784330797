import { Product, ProductColor, TileType } from '../types/ProductTypes';

export const getImageName = (product: Product | undefined) => {
  if (!product) {
    return '';
  }
  if (product.tileType === TileType.TerreCuiteSolaire) {
    if (product.color === ProductColor.Noir) {
      return 'TUILES_SOLAIRES_NOIR.png';
    }

    if (product.color === ProductColor.Rouge) {
      return 'TUILES_SOLAIRES_ROUGE.png';
    }
  } else if (product.tileType === TileType.SolaireMax) {
    if (product.color === ProductColor.Noir) {
      return 'SOLAIRE_MAX_NOIR.png';
    }

    if (product.color === ProductColor.Rouge) {
      return 'SOLAIRE_MAX_ROUGE.png';
    }
  } else if (product.tileType === TileType.EasyroofAccess) {
    return 'EASY_ROOF_EVOLUTION.png';
  } else if (product.tileType === TileType.EasyroofIntegration) {
    return 'EASY_ROOF_INTEGRATION.png';
  } else if (product.tileType === TileType.EasyroofFlat) {
    return 'EASY_ROOF_FLAT.png';
  } else if (product.tileType === TileType.EasyroofTop) {
    return 'EASY_ROOF_TOP.jpg';
  }
};
