import { ReactElement } from 'react';
import * as React from 'react';

export const TitleWithIcon = ({ title, icon }: { title: string; icon: ReactElement }) => {
  return (
    <div className="my-10 flex items-center">
      {React.cloneElement(icon, {
        color: icon.props.color || 'primary',
        fontSize: 'large',
        className: 'mr-2',
      })}
      <h2>{title}</h2>
    </div>
  );
};
