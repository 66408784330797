import { Skeleton, TextField } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';

import { departments } from '../../../util/departments';
import { StudyAddress } from '../NewSimulation';

interface Props {
  gMapsApiStatus: boolean;
  setAddress(place?: google.maps.LatLng): void;
  setDepartmentCode: (code: string) => void;
  setStudyAddress: (studyAddress: StudyAddress) => void;
}

const AddressAutocompleteWithoutErrorBoundary = ({
  gMapsApiStatus,
  setAddress,
  setDepartmentCode,
  setStudyAddress,
}: Props) => {
  const { t } = useTranslation();

  const [addressRef, setAddressRef] = useState<google.maps.places.SearchBox>();
  const onLoad = (ref: google.maps.places.SearchBox) => setAddressRef(ref);

  const extractDepartment = (places: google.maps.places.PlaceResult[], index: number): string => {
    const parser = new DOMParser();

    let street = '';
    let zipCode = '';
    let city = '';
    let country = '';

    try {
      const doc = parser.parseFromString(places[0]?.adr_address || '', 'text/html');

      const cityElement = doc.querySelector('.locality');
      const streetElement = doc.querySelector('.street-address');
      const countryElement = doc.querySelector('.country-name');
      const zipCodeElement = doc.querySelector('.postal-code');

      if (streetElement?.textContent) {
        street = streetElement.textContent;
      }
      if (zipCodeElement?.textContent) {
        zipCode = zipCodeElement.textContent;
      }
      if (cityElement?.textContent) {
        city = cityElement.textContent;
      }
      if (countryElement?.textContent) {
        country = countryElement.textContent;
      }
    } catch (error) {
      console.error('Error parsing HTML:', error);
    }

    setStudyAddress({ street, zipCode, city, country });

    // @ts-ignore
    return departments.find(
      // @ts-ignore
      (d) => d.dep_name === places[0].address_components[index].long_name
    ).num_dep;
  };

  const onPickupChanged = () => {
    if (addressRef) {
      const places = addressRef.getPlaces();
      if (places) {
        setAddress(places[0]?.geometry?.location);
        try {
          const departmentCode = extractDepartment(places, 3);
          setDepartmentCode(departmentCode);
        } catch (e) {
          try {
            const departmentCode = extractDepartment(places, 2);
            setDepartmentCode(departmentCode);
          } catch (e) {
            try {
              const departmentCode = extractDepartment(places, 1);
              setDepartmentCode(departmentCode);
            } catch (e) {}
          }
        }
      }
    }
  };

  return (
    <div style={{ height: '100%', flex: 9 }}>
      {gMapsApiStatus ? (
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPickupChanged}>
          <TextField sx={{ width: '100%' }} placeholder={t('address')} />
        </StandaloneSearchBox>
      ) : (
        <Skeleton sx={{ width: '100%' }} />
      )}
    </div>
  );
};

export const AddressAutocomplete = withErrorBoundary(AddressAutocompleteWithoutErrorBoundary, {
  fallback: <div>Something went wrong</div>,
  onError(e) {
    console.error(e);
  },
});
