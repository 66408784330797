import { useEffect, useState } from 'react';

export const useGoogleMaps = (src: string, reload: boolean) => {
  const [status, setStatus] = useState<'ready' | 'notReady'>('notReady');
  useEffect(() => {
    if (status === 'ready') {
      return;
    }
    if (!src) {
      setStatus('notReady');
      return;
    }

    let script: any = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);

      const setAttributeFromEvent = (event: any) => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status'));
    }

    const setStateFromEvent = (event: any) => {
      setStatus(event.type === 'load' ? 'ready' : 'notReady');
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src, reload, document]);

  return status;
};
