const serverURL = process.env.REACT_APP_API_URL;

export const serverRoutes = {
  passwordReset: (email: string) => serverURL + '/password-reset?email=' + email,
  myStudiesSalesAreasAndSectors: (idToken: string, startDate: Date, isForSearch: boolean) =>
    serverURL +
    `/my-studies-sales-areas-and-sectors?idToken=${idToken}&startDate=${startDate}&isForSearch=${isForSearch}`,
  studyComputation: serverURL + '/study-computation',
  sendSynthesisByMail: serverURL + '/send-synthesis-by-mail',
  downloadPdfSynthesis: serverURL + '/pdf-synthesis',
  createAdmin: (userEmail: string, idToken: string) =>
    serverURL + `/create-admin?userEmail=${userEmail}&idToken=${idToken}`,
  removeAdmin: (userUid: string, idToken: string) =>
    serverURL + `/remove-admin?userUid=${userUid}&idToken=${idToken}`,
  createManager: (userEmail: string, idToken: string) =>
    serverURL + `/create-manager?userEmail=${userEmail}&idToken=${idToken}`,
  removeManager: (userUid: string, idToken: string) =>
    serverURL + `/remove-manager?userUid=${userUid}&idToken=${idToken}`,
};
