// 8 characters, 1 lower case, 1 upper case, 1 number

const hasNumber = (string: string) => {
  return /\d/.test(string);
};

export const isPasswordValid = (password: string | undefined) => {
  return !(
    !password ||
    password.length < 8 ||
    password.toLowerCase() === password ||
    password.toUpperCase() === password ||
    !hasNumber(password)
  );
};
