import React from 'react';

export const OrientationIcon = ({ orientation }: { orientation: string | undefined }) => {
  if (!orientation) {
    return null;
  }

  return (
    <div style={{ marginLeft: -15 }}>
      <div className={'orientation-icon ' + orientation}>
        <div className="point"></div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};
