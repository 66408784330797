import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { v4 } from 'uuid';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { Modal, Tooltip } from '@mui/material';
import styled from 'styled-components';

import { powerRangesCollection } from '../../firebase/firestoreCollections';
import { PowerRange } from '../../types/AdminSettings';
import { deleteData, getAllData, setData } from '../../firebase/firestoreQueries';
import { snackError, snackSuccess } from '../../util/snacks';
import { ModalContent } from '../../components/ModalContent';

type PowerRangeForGrid = {
  id: string;
  name: string;
  minPower: number;
  maxPower: number;
  isNew: boolean;
};

const isOverlapping = (newRange: number[], rows: PowerRangeForGrid[]): boolean => {
  const existingRanges = rows.map((r) => [r.minPower, r.maxPower]).filter((r) => r[0]);
  const isMinInRange = existingRanges.some(
    (existingRange) => existingRange[0] < newRange[0] && newRange[0] < existingRange[1]
  );
  const isMaxInRange = existingRanges.some(
    (existingRange) => existingRange[0] < newRange[1] && newRange[1] < existingRange[1]
  );
  const isNewRangeOverlappingCompletelyOne = existingRanges.some(
    (existingRange) => newRange[0] <= existingRange[0] && newRange[1] >= existingRange[1]
  );

  return isMinInRange || isMaxInRange || isNewRangeOverlappingCompletelyOne;
};

export const PowerRanges = () => {
  const { t } = useTranslation();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [rows, setRows] = React.useState<PowerRangeForGrid[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<GridRowId | undefined>();

  const formatRowsForGrid = (powerRanges: PowerRange[]): PowerRangeForGrid[] => {
    return powerRanges
      .sort((r1, r2) => r1.maxPower - r2.maxPower)
      .map((range, i) => ({
        ...range,
        id: range.uid,
        name: t('admin:rangeWithSharp') + i,
        isNew: false,
      }));
  };

  React.useEffect(() => {
    (async () => {
      const powerRanges = await getAllData(powerRangesCollection);
      setRows(formatRowsForGrid(powerRanges));
    })();
  }, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setIsDeleteModalOpen(id);
  };

  const onConfirmDelete = async () => {
    if (!isDeleteModalOpen) {
      return;
    }
    await deleteData(powerRangesCollection, isDeleteModalOpen.toString());
    setRows(rows.filter((row) => row.id !== isDeleteModalOpen));
    setIsDeleteModalOpen(undefined);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: PowerRangeForGrid) => {
    if (
      isOverlapping(
        [newRow.minPower, newRow.maxPower],
        rows.filter((r) => r.id !== newRow.id)
      ) ||
      newRow.minPower >= newRow.maxPower ||
      newRow.minPower < 0
    ) {
      snackError('admin:GlobalSettings.noOverlappingRanges');
      throw new Error('Overlapping ranges');
    }

    const allRows = rows.concat(newRow).sort((r1, r2) => r1.maxPower - r2.maxPower);
    const newRowIndex = allRows.findIndex((row) => row.id === newRow.id);
    const updatedRow = { ...newRow, name: t('admin:rangeWithSharp') + newRowIndex, isNew: false };
    try {
      await setData(powerRangesCollection, omit({ uid: newRow.id, ...newRow }, 'isNew'));
      snackSuccess();
    } catch (e) {
      snackError();
    }

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddRange = () => {
    const id = v4();
    // @ts-ignore
    setRows((oldRows) => [...oldRows, { id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'minPower' },
    }));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      width: 150,
      editable: false,
      renderHeader: () => {
        return (
          <>
            <Box sx={{ fontWeight: 'bold' }}>{t('admin:range')}</Box>
            <Tooltip title={t('admin:tooltips.power')} className="ml-1.5">
              <Icon path={mdiHelpCircleOutline} size={1} />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'minPower',
      headerName: t('admin:minPower'),
      type: 'number',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueFormatter: (value) => value + ' ' + t('admin:kiloWattPeak'),
    },
    {
      field: 'maxPower',
      headerName: t('admin:maxPower'),
      type: 'number',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueFormatter: (value) => value + ' ' + t('admin:kiloWattPeak'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <div>
        <Box
          sx={{
            height: 380,
            width: 570,
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            autoPageSize
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
              },
            }}
          />
        </Box>
        <div className="mt-4">
          <Button color="primary" variant="adminBlue" onClick={handleAddRange}>
            {t('admin:GlobalSettings.addRange')}
          </Button>
        </div>
      </div>

      <Modal open={!!isDeleteModalOpen}>
        <SModalContent>
          <h2 className="text-center">{t('common:confirmation')}</h2>

          <div className="my-5 flex justify-center">
            <p>{t('admin:areYouSure')}</p>
          </div>

          <div className="mt-6 flex justify-between">
            <SButton onClick={() => setIsDeleteModalOpen(undefined)} variant="adminGrey">
              {t('common:cancel')}
            </SButton>
            <SButton onClick={onConfirmDelete} variant="adminBlue">
              {t('common:validate')}
            </SButton>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const SModalContent = styled(ModalContent)`
  min-height: 50px;
  max-width: 630px;
  padding: 30px 50px 32px;
`;

const SButton = styled(Button)`
  width: 130px;
  margin: 5px;
`;
