import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../style/colors';

export interface ButtonProps {
  color?: string;
  fixedWidth?: boolean;
  name?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const Button = ({
  color,
  children,
  onClick,
  ...props
}: ButtonProps & React.HTMLAttributes<HTMLElement>) => (
  <StyledButton color={color} onClick={onClick} {...props}>
    {children}
  </StyledButton>
);

const StyledButton = styled('button')`
  color: ${(p) => (p.color ? p.color : COLORS.primaryColor)};
  font-size: 1rem;
  width: fit-content;
  border: 1px solid ${COLORS.primaryColor};
  border-radius: 4px;
  padding: 7px 23px;
  cursor: pointer;
  margin-top: 0.625rem;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid ${COLORS.primaryColor};
    background-color: ${COLORS.primaryColor};
  }
`;
