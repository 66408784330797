import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { SalesArea, Sector } from '../../types/AdminManagement';
import {
  salesAreasCollection,
  salesAreasCollectionName,
  sectorsCollection,
  sectorsCollectionName,
  usersCollection,
  usersCollectionName,
} from '../../firebase/firestoreCollections';
import { AppHeader } from '../../components/AppHeader';
import {
  ActionIconContainer,
  Background,
  BigPlusButton,
  Container,
  GoBackButton,
  GridElement,
  GridText,
  Subtitle,
} from './components/AdminComponents';
import { Autocomplete, Button, Divider, Grid, Modal, styled, TextField } from '@mui/material';
import { clientRoutes } from '../../routing/clientRoutes';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalContent } from '../../components/ModalContent';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { setData } from '../../firebase/firestoreQueries';
import { snackSuccess } from '../../util/snacks';
import { User } from '../../types/User';
import { TitleWithLabelEdit } from './components/TitleWithLabelEdit';

export const EditSalesAreas = () => {
  const { t } = useTranslation('admin');
  const { uid } = useParams();

  const [isManagerEditActionOpen, setIsManagerEditActionOpen] = useState(false);
  const [isEditManagerModalOpen, setIsEditManagerModalOpen] = useState(false);
  const [isDeleteSectorModalOpen, setIsDeleteSectorModalOpen] = useState<string>();
  const [isAddSectorModalOpen, setIsAddSectorModalOpen] = useState(false);
  const [isDeleteSectorActionVisible, setIsDeleteSectorActionVisible] = useState<string>();
  const [newManager, setNewManager] = useState<User | null>();
  const [newLinkedSectors, setNewLinkedSectors] = useState<Sector[]>([]);
  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection, {
    subscribe: true,
  });
  const internalUsers = (usersQuery.data || []).filter((u) => u.isInternal);

  const salesAreasQuery = useFirestoreQueryData<SalesArea>(
    [salesAreasCollectionName],
    salesAreasCollection,
    {
      subscribe: true,
    }
  );
  const sectorsQuery = useFirestoreQueryData<Sector>([sectorsCollectionName], sectorsCollection, {
    subscribe: true,
  });
  const sectors = sectorsQuery.data || [];
  const salesArea = salesAreasQuery.data?.find((salesAreas) => salesAreas.uid === uid);
  const linkedSector =
    sectorsQuery.data?.filter((sector) => salesArea?.linkedSectorsUid.includes(sector.uid)) || [];

  const updateSalesAreasLabel = async (newLabel: string) => {
    await setData(salesAreasCollection, { ...salesArea, label: newLabel });
    snackSuccess();
  };

  const updateManager = async () => {
    if (!newManager) {
      return;
    }

    await setData(salesAreasCollection, {
      ...salesArea,
      managerEmail: newManager.email,
      managerFirstName: newManager.firstName,
      managerLastName: newManager.lastName,
    });
    snackSuccess();
    setIsEditManagerModalOpen(false);
  };

  const updateSectors = async () => {
    await setData(salesAreasCollection, {
      ...salesArea,
      linkedSectorsUid: _.uniq(
        salesArea?.linkedSectorsUid.concat((newLinkedSectors || []).map((s) => s.uid))
      ),
    });
    snackSuccess();
    setNewLinkedSectors([]);
    setIsAddSectorModalOpen(false);
  };

  const deleteSector = async (sectorUid?: string) => {
    await setData(salesAreasCollection, {
      ...salesArea,
      linkedSectorsUid: salesArea?.linkedSectorsUid.filter((uid) => uid !== sectorUid),
    });
    snackSuccess();
    setIsDeleteSectorModalOpen(undefined);
  };

  if (!salesArea) {
    return null;
  }

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <TitleWithLabelEdit
            title={t('UsersManagement.salesAreas')}
            label={salesArea?.label || ''}
            updateLabel={updateSalesAreasLabel}
          />
          <Divider />
          <GoBackButton path={clientRoutes.salesAreasManagement} />

          <Subtitle>{t('UsersManagement.salesAreasManager')}</Subtitle>
          <Grid container spacing={2} className="mb-10">
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <GridElement
                className="flex flex-col items-center"
                onMouseEnter={() => setIsManagerEditActionOpen(true)}
                onMouseLeave={() => setIsManagerEditActionOpen(false)}>
                <div className="relative flex w-full justify-center">
                  {isManagerEditActionOpen && (
                    <ActionsContainer>
                      <ActionIconContainer onClick={() => setIsEditManagerModalOpen(true)}>
                        <CreateIcon />
                      </ActionIconContainer>
                    </ActionsContainer>
                  )}
                </div>
                <GridText>{salesArea.managerEmail}</GridText>
                <GridText>
                  {salesArea.managerFirstName} {salesArea.managerLastName}
                </GridText>
              </GridElement>
            </Grid>
          </Grid>

          <Subtitle>{t('UsersManagement.linkedSectors')}</Subtitle>
          <Grid container spacing={2}>
            {linkedSector.map((sector) => (
              <Grid item xs={6} sm={6} md={4} lg={3} key={sector.uid}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setIsDeleteSectorActionVisible(sector.uid)}
                  onMouseLeave={() => setIsDeleteSectorActionVisible(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>{sector.label}</GridText>
                    {isDeleteSectorActionVisible === sector.uid && (
                      <ActionsContainer>
                        <ActionIconContainer onClick={() => setIsDeleteSectorModalOpen(sector.uid)}>
                          <DeleteIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <GridText>
                    {_.upperFirst(sector.managerFirstName)} {_.upperFirst(sector.managerLastName)}{' '}
                    {sector.linkedDepartmentCodes.join('/')}
                  </GridText>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddSectorModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>
        </Container>
      </Background>

      <Modal open={isEditManagerModalOpen}>
        <SModalContent>
          <div className="mb-10">
            <Title className="text-center">{t('UsersManagement.selectNewManager')}</Title>
          </div>

          <div className="mb-20 flex justify-center">
            <Autocomplete
              options={internalUsers}
              noOptionsText={t('all:studiesList.aucunUtilisateurTrouve')}
              getOptionLabel={(option) =>
                option.firstName + ' ' + option.lastName + ' ' + option.email
              }
              sx={{ width: 300 }}
              onChange={(_, value) => setNewManager(value)}
              renderInput={(params) => (
                <TextField {...params} label={t('UsersManagement.searchUser')} />
              )}
            />
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsEditManagerModalOpen(false)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                disabled={!newManager}
                type="submit"
                onClick={updateManager}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={!!isDeleteSectorModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.removeSectorFromSalesAreas')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">{t('UsersManagement.youCanAddItAgain')}</div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsDeleteSectorModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" onClick={() => deleteSector(isDeleteSectorModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={isAddSectorModalOpen}>
        <SModalContent>
          <div className="mb-10">
            <Title className="text-center">{t('UsersManagement.addLinkedSectors')}</Title>
          </div>

          <div className="mb-20 flex justify-center">
            <Autocomplete
              multiple
              noOptionsText={t('all:studiesList.aucunSecteurTrouve')}
              options={sectors.filter((s) => !salesArea.linkedSectorsUid.includes(s.uid))}
              getOptionLabel={(sector) => sector.label}
              sx={{ width: 300 }}
              onChange={(_, value) => setNewLinkedSectors(value)}
              renderInput={(params) => <TextField {...params} label={t('common:search')} />}
            />
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsAddSectorModalOpen(false)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                onClick={updateSectors}
                disabled={!newLinkedSectors?.length}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 70px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const ActionsContainer = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
`;
