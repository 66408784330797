import { withTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { TextField, TextFieldProps, useMediaQuery } from '@mui/material';
import { Zoom } from 'react-awesome-reveal';
import { Control, Controller } from 'react-hook-form';

const Container = styled('div')`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
`;

interface InputProps {
  control: Control<any>;
  errors: any;
  name: string;
  placeholder?: string;
  t: any;
  withoutLabel?: boolean;
  withZeroPossible?: boolean;
  size?: 'small' | 'medium';
  trimValue?: boolean;
}

export const Error = styled('span')`
  display: block;
  font-weight: 600;
  color: ${(props) => props.theme.palette.error.main};
  height: 0;
  font-size: 12px;
`;

export const MyInput = withTranslation()(({
  name,
  placeholder,
  errors,
  control,
  type,
  t,
  withoutLabel,
  errorStyle,
  withZeroPossible,
  size,
  trimValue,
  ...props
}: InputProps &
  TextFieldProps & {
    errorStyle?: React.CSSProperties;
  } & React.InputHTMLAttributes<HTMLInputElement>) => {
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  return (
    <Container>
      <Controller
        name={name}
        render={({ field }) => (
          <TextField
            id={name}
            label={
              !isWeb && !withoutLabel
                ? t(name)
                : placeholder || placeholder === ''
                ? t(placeholder)
                : ''
            }
            error={!!errors[name]}
            placeholder={placeholder || placeholder === '' ? t(placeholder) : ''}
            variant="outlined"
            type={type}
            {...field}
            {...props}
            onChange={(event) =>
              type === 'number'
                ? field.onChange(Number(event.target.value))
                : field.onChange(trimValue ? event.target.value.trim() : event.target.value)
            }
            size={size || 'small'}
            value={!withZeroPossible ? field.value || '' : field.value}
          />
        )}
        control={control}
      />

      {errors[name]?.message && (
        <Zoom direction="left">
          <Error style={errorStyle}>{t(errors[name].message)}</Error>
        </Zoom>
      )}
    </Container>
  );
});
