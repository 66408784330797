// SIRET CANNOT START BY ZERO
export const isSIRET = (siret: number | undefined) => {
  if (!siret) return false;
  return verify(siret, 14);
};

export const isSIREN = (siren: number) => {
  return verify(siren, 9);
};

const verify = (number: number, length: number) => {
  if (isNaN(number) || number.toString().length != length) {
    return false;
  }
  let bal = 0;
  let total = 0;
  for (let i = length - 1; i >= 0; i--) {
    const step = (number.toString().charCodeAt(i) - 48) * (bal + 1);
    total += step > 9 ? step - 9 : step;
    bal = 1 - bal;
  }
  return total % 10 == 0;
};
