import { useEffect, useState } from 'react';
import { devUser } from '../types/User';
import { User, getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import { usersCollectionName } from '../firebase/firestoreCollections';

export type CompleteUser = User & {
  isActive?: boolean;
  isAdmin?: boolean;
  isManager?: boolean;
  idToken?: string;
  isInternal?: string;
};

export const useAuth = (): CompleteUser | null | undefined => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<CompleteUser | null | undefined>();

  useEffect(() => {
    // const isDev =
    //   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const isDev = false;

    if (isDev) {
      // @ts-ignore
      setUser(devUser);
      setInitializing(false);
      return;
    } else {
      const onAuthStateChanged = async (user: User | null) => {
        if (user?.uid) {
          const userDocRef = doc(firestore, usersCollectionName, user?.uid);
          const docSnap = await getDoc(userDocRef);

          const idToken = await user.getIdToken();

          if (docSnap.exists()) {
            setUser({
              ...user,
              isActive: docSnap.data()?.isActive,
              isAdmin: docSnap.data()?.isAdmin,
              isManager: docSnap.data()?.isManager,
              isInternal: docSnap.data()?.isInternal,
              idToken,
            });
          } else {
            setUser(user);
          }
        }

        setInitializing(false);
      };

      return getAuth().onAuthStateChanged(onAuthStateChanged);
    }
  }, [initializing]);

  if (initializing) {
    return null;
  }

  return user;
};
