import * as React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tab, Tabs, Typography, useTheme } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Icon from '@mdi/react';
import { mdiBank, mdiRouterNetwork, mdiTransmissionTower } from '@mdi/js';
import { styled } from '@mui/system';

import { COLORS } from '../../style/colors';
import { TitleWithIcon } from './components/TitleWithIcon';
import { GlobalSettings } from './GlobalSettings';
import { Subsidies } from './Subsidies';
import { TvaSettings } from './TvaSettings';
import { EdfTariffs } from './EdfTariffs';
import { NetworkCosts } from './NetworkCosts';
import { SellPrice } from './SellPrice';
import { PaddingBox } from './components/PaddingBox';
import { AppHeader } from '../../components/AppHeader';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const tabHeight = {
  minHeight: '52px',
  height: '52px',
};

export const ParametersIndex = () => {
  const { t } = useTranslation('admin');
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <>
      <AppHeader isAdminScreen />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            centered
            sx={tabHeight}
            aria-label="full width tabs example">
            <StyledTab
              label={t('appHeader.tab0')}
              icon={<SettingsIcon style={{ marginBottom: 3 }} />}
              iconPosition="start"
              {...a11yProps(0)}
            />
            <StyledTab
              label={
                <MyTabWithIcon>
                  <Icon path={mdiTransmissionTower} size={1} style={{ marginRight: 6 }} />
                  {t('appHeader.tab1')}
                </MyTabWithIcon>
              }
              {...a11yProps(1)}
            />
            <StyledTab
              label={
                <MyTabWithIcon>
                  <Icon
                    path={mdiRouterNetwork}
                    size={1}
                    style={{ marginBottom: 6, marginRight: 6 }}
                  />
                  {t('appHeader.tab2')}
                </MyTabWithIcon>
              }
              {...a11yProps(2)}
            />
            <StyledTab
              label={
                <MyTabWithIcon>
                  <Icon path={mdiBank} size={1} style={{ marginRight: 6 }} />
                  {t('appHeader.tab3')}
                </MyTabWithIcon>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CenterTabContainer>
              <TabContainer>
                <GlobalSettings />
              </TabContainer>
            </CenterTabContainer>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <CenterTabContainer>
              <TabContainer>
                <EdfTariffs />
              </TabContainer>
            </CenterTabContainer>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <CenterTabContainer>
              <TabContainer>
                <NetworkCosts />
              </TabContainer>
            </CenterTabContainer>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <CenterTabContainer>
              <TabContainer>
                <SellPrice />
                <TitleWithIcon icon={<AccountBalanceIcon />} title={t('subsidiesTitle')} />
                <PaddingBox>
                  <Subsidies />
                </PaddingBox>
                <TitleWithIcon icon={<AccountBalanceIcon />} title={t('common:tva')} />
                <TvaSettings />
              </TabContainer>
            </CenterTabContainer>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </>
  );
};

const TabContainer = styled('div')`
  padding: 0 20px 20px 20px;
  max-width: 1420px;
  width: 100%;
`;

const CenterTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MyTabWithIcon = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledTab = styled(Tab)(() => ({
  ...tabHeight,
  '&.Mui-selected': {
    color: COLORS.adminBluePrimary,
    fontWeight: 'bold',
  },
}));
