import React, { MutableRefObject, useRef } from 'react';
import { Button, InputAdornment, styled, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { COLORS } from '../../style/colors';
import { RoofSelectorModal } from './mapRoofSelector/RoofSelectorModal';
import { OrientationIcon } from './components/OrientationIcon';
import { MySelect } from '../../components/MySelect';
import { SlopeIcon } from './components/SlopeIcon';
import { departments } from '../../util/departments';
import { NewStudyConfirmationModal } from './NewStudyConfirmationModal';
import {
  altitudeOptions,
  defaultValues,
  isFormValid,
  newStudyFormOptions,
  orientationOptions,
  periodOptions,
  slopeOptions,
  StudyFormValues,
} from './NewSimulationFormHelper';
import { NewSimulationEquipments } from './NewSimulationEquipments';
import { InputContainer, SelectAndIcon, SInput } from './components/NewSimulationComponents';
import { NewSimulationElectricReference } from './NewSimulationElectricReference';
import { NewSimulationDomesticHotWater } from './NewSimulationDomesticHotWater';
import { NewSimulationAnnexes } from './NewSimulationAnnexes';
import { NewSimulationHeating } from './NewSimulationHeating';
import { FormGridLayout } from './components/FormGridLayout';
import { correctRoofSurfaceWithSlope } from './mapRoofSelector/correctRoofSurfaceWithSlope';
import { FormProgress } from './components/FormProgress';
import { RoofExplanationsModal } from './mapRoofSelector/RoofExplanationModal';
import { getData } from '../../firebase/firestoreQueries';
import { studiesCollection } from '../../firebase/firestoreCollections';
import { AllOrientations } from '../../types/Orientations.enum';
import { useNewStudy } from '../../contexts/NewStudyContext';
import { StudyAppHeader } from '../../components/StudyAppHeader';
import { RoofExplanationsModalMobile } from './mapRoofSelector/RoofExplanationModal.mobile';
import { ScrollToTop } from '../../util/ScrollToTop';

export enum Step {
  form,
  roofExplanation,
  roofSelector,
  edgeSelection,
}

export interface StudyAddress {
  street: string;
  zipCode: string;
  city: string;
  country: string;
}

export const NewSimulation = () => {
  const { t } = useTranslation();
  const { uid: uidParam } = useParams();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const { studyContext, setStudyContext } = useNewStudy();
  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const [googleMapsRoofArea, setGoogleMapsRoofArea] = React.useState<number>();
  const [previousSlopeValue, setPreviousSlopeValue] = React.useState<string>();
  const [roofAreaSetByHuman, setRoofAreaSetByHuman] = React.useState(true);
  const [studyAddress, setStudyAddress] = React.useState<StudyAddress>();

  const [step, setStep] = React.useState<Step>(Step.form);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!uidParam && !studyContext.study?.uid) {
      setStep(Step.roofExplanation);
    }
  }, [uidParam]);

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<StudyFormValues>({
    ...newStudyFormOptions,
    defaultValues,
  });

  React.useEffect(() => {
    (async () => {
      if (
        (uidParam && studyContext.study?.uid !== uidParam) ||
        (!uidParam && studyContext.study?.uid)
      ) {
        const study = await getData(studiesCollection, uidParam || studyContext.study?.uid || '');

        if (study) {
          setStudyContext({
            study: study,
            results: study.results,
            chosenProducts: study.chosenProducts || [],
            economicModels: study.economicModels || [],
            isCustomPowerSelected: !!study.isCustomPowerSelected,
            customPowerResults: study.customPowerResults,
            shouldDisplayPrice: study.shouldDisplayPrice ?? true,
            products: studyContext.products,
          });

          reset({
            ...study,
            altitude: study.altitude.toString(),
            domesticHotWater: [study.domesticHotWater],
            orientation:
              orientationOptions.find((o) => o.label === study.orientation)?.value ||
              AllOrientations.NC,
            annualElectricConsumption: study.annualElectricConsumption
              ? study.annualElectricConsumption.toString()
              : undefined,
            annualElectricBill: study.annualElectricBill
              ? study.annualElectricBill.toString()
              : undefined,
            monthlyElectricBill: study.monthlyElectricBill
              ? study.monthlyElectricBill.toString()
              : undefined,
          });
        }
      }
    })();
  }, [uidParam]);

  const watchedFields = watch();
  const orientationValue = watch('orientation');
  const slopeValue = watch('slope');
  const roofArea = watch('roofArea');

  React.useEffect(() => {
    if (googleMapsRoofArea) {
      if (slopeValue === previousSlopeValue || !roofAreaSetByHuman) {
        setValue('roofArea', correctRoofSurfaceWithSlope(googleMapsRoofArea, slopeValue));
        setRoofAreaSetByHuman(false);
      }
    }
  }, [googleMapsRoofArea, slopeValue]);

  React.useEffect(() => {
    if (slopeValue) {
      setPreviousSlopeValue(slopeValue);
    }
  }, [slopeValue]);

  React.useEffect(() => {
    if (
      !googleMapsRoofArea ||
      (roofArea && correctRoofSurfaceWithSlope(googleMapsRoofArea, previousSlopeValue) !== roofArea)
    ) {
      setRoofAreaSetByHuman(true);
    }
  }, [roofArea]);

  const handleClick = (ref: MutableRefObject<any>, offset: number) => {
    // @ts-ignore
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const onSubmit = (data: StudyFormValues) => {
    setStudyContext({ ...studyContext, formValues: data, studyAddress });
    setIsConfirmationModalOpen(true);
  };

  const Content = (
    <Container>
      <Form>
        <FormProgress watchedFields={watchedFields} />
        <TitleWithIcon onClick={() => handleClick(ref0, 50)} style={{ top: 0 }}>
          <RoofIcon src="/images/toiture.png" alt={t('roof')} />
          <Title>{t('roof')}</Title>
        </TitleWithIcon>
        <div className="m-5 flex justify-center">
          <button
            type="button"
            onClick={() => setStep(Step.roofSelector)}
            className="btn font-bold">
            {t('newStudyAutomaticallyRetrieve')}
          </button>
        </div>
        <div ref={ref0}>
          <FormGridLayout
            tooltip={!orientationValue || orientationValue === 'NC' ? 'orientationTooltip' : ''}
            labelTranslationKey="orientation"
            items={[
              <SelectAndIcon>
                <InputContainer>
                  <SMySelect
                    name="orientation"
                    errors={errors}
                    control={control}
                    items={orientationOptions}
                    withoutLabel={isWeb}
                  />
                </InputContainer>
                {isWeb ? (
                  <OrientationIcon orientation={orientationValue} />
                ) : (
                  <div style={{ width: 80 }} className="ml-4 flex justify-center">
                    <OrientationIcon orientation={orientationValue} />
                  </div>
                )}
              </SelectAndIcon>,
            ]}
          />

          <FormGridLayout
            tooltip={!slopeValue || slopeValue === 'NC' ? 'slopeTooltip' : ''}
            labelTranslationKey="slope"
            items={[
              <SelectAndIcon>
                <InputContainer>
                  <SMySelect
                    name="slope"
                    errors={errors}
                    control={control}
                    items={slopeOptions}
                    withoutLabel={isWeb}
                  />
                </InputContainer>
                {isWeb ? (
                  <SlopeIcon slope={slopeValue} />
                ) : (
                  slopeValue !== 'NC' && (
                    <div style={{ width: 80 }} className="ml-4 flex justify-center">
                      <SlopeIcon slope={slopeValue} />
                    </div>
                  )
                )}
              </SelectAndIcon>,
            ]}
          />

          <ShadowExplanation>
            {t('shadowExplanation0')}
            <br />
            <b>{t('shadowExplanation1')}</b>
          </ShadowExplanation>

          <FormGridLayout
            labelTranslationKey="roofArea"
            items={[
              <SInput
                name="roofArea"
                errors={errors}
                control={control}
                type="number"
                step="1"
                min="0"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t('squareMeterAbbreviation')}</InputAdornment>
                  ),
                }}
              />,
            ]}
          />
        </div>
        <TitleWithIcon onClick={() => handleClick(ref1, 100)} style={{ top: 50 }}>
          <RoofIcon src="/images/logement.png" alt={t('accommodation')} />
          <Title>{t('accommodation')}</Title>
        </TitleWithIcon>
        <div ref={ref1}>
          <FormGridLayout
            labelTranslationKey="department"
            items={[
              <SMySelect
                name="departmentCode"
                errors={errors}
                control={control}
                items={departments.map((department) => ({
                  value: department.num_dep,
                  label: department.num_dep + ' - ' + department.dep_name,
                }))}
                withoutLabel={isWeb}
              />,
            ]}
          />

          <FormGridLayout
            labelTranslationKey="altitude"
            tooltip={
              !watchedFields.altitude || watchedFields.altitude === 'NC' ? 'altitudeTooltip' : ''
            }
            items={[
              <SMySelect
                name="altitude"
                errors={errors}
                control={control}
                items={altitudeOptions}
                withoutLabel={isWeb}
              />,
            ]}
          />

          <FormGridLayout
            labelTranslationKey="constructionPeriod"
            tooltip={
              !watchedFields.constructionPeriod || watchedFields.constructionPeriod === 'NC'
                ? 'periodTooltip'
                : ''
            }
            items={[
              <SMySelect
                name="constructionPeriod"
                errors={errors}
                control={control}
                items={periodOptions}
                withoutLabel={isWeb}
              />,
            ]}
          />

          <FormGridLayout
            labelTranslationKey="livingArea"
            items={[
              <SInput
                name="livingAreaInSquareMeters"
                errors={errors}
                control={control}
                type="number"
                step="1"
                min="0"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t('squareMeterAbbreviation')}</InputAdornment>
                  ),
                }}
              />,
            ]}
          />

          <FormGridLayout
            labelTranslationKey="numberOfResidents"
            tooltip={!watchedFields.numberOfResidents ? 'residentsTooltip' : ''}
            items={[
              <SInput name="numberOfResidents" errors={errors} control={control} type="number" />,
            ]}
          />
        </div>
        <TitleWithIcon onClick={() => handleClick(ref2, 150)} style={{ top: 100 }}>
          <RoofIcon src="/images/consommation.png" alt={t('consumption')} />
          <Title>{t('consumption')}</Title>
        </TitleWithIcon>
        <div ref={ref2}>
          <NewSimulationHeating control={control} getValues={getValues} />
          <NewSimulationAnnexes control={control} getValues={getValues} />
          <NewSimulationDomesticHotWater control={control} getValues={getValues} />
          <NewSimulationEquipments control={control} getValues={getValues} />
          <NewSimulationElectricReference
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        </div>
        <NewStudyConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setIsModalOpen={setIsConfirmationModalOpen}
        />
        <SButton
          type="submit"
          variant="contained"
          disabled={!isFormValid(isValid, watchedFields)}
          onClick={handleSubmit(onSubmit)}>
          {t('startSimulation')}
        </SButton>
      </Form>

      {isWeb ? (
        <RoofExplanationsModal
          isExplanationModalOpen={step === Step.roofExplanation}
          setStep={setStep}
        />
      ) : (
        <RoofExplanationsModalMobile
          isExplanationModalOpen={step === Step.roofExplanation}
          setStep={setStep}
        />
      )}

      <RoofSelectorModal
        step={step}
        setStep={setStep}
        setRoofSurface={(roofSurface) => setGoogleMapsRoofArea(Number(roofSurface))}
        setOrientation={(orientation) => setValue('orientation', orientation)}
        setAltitude={(altitude) => setValue('altitude', altitude)}
        setDepartmentCode={(code) => setValue('departmentCode', code)}
        setStudyAddress={(address) => setStudyAddress(address)}
      />
    </Container>
  );

  return (
    <>
      <ScrollToTop />

      <StudyAppHeader />

      {isWeb ? <Background>{Content}</Background> : <div className="pb-20">{Content}</div>}
    </>
  );
};

const Background = styled('div')`
  background-color: ${COLORS.greyPurple};
  padding: 16px 12px;
`;

const Container = styled('div')`
  background-color: ${COLORS.white};
  border-radius: 10px;
  padding: 30px;
`;

const Form = styled('form')``;

const TitleWithIcon = styled('div')`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.greyPurple};
  margin-top: 5px;
  height: 51px;
  position: sticky;
  z-index: 2;
  background-color: ${COLORS.white};
`;

const RoofIcon = styled('img')`
  height: 32px;
  padding: 0 10px;
`;

const Title = styled('h1')`
  margin-left: 10px;
`;

const SButton = styled(Button)`
  margin-top: 22px;
  margin-bottom: 8px;
  width: 100%;
  font-weight: bold;
`;

const ShadowExplanation = styled('p')`
  border: 1px solid ${COLORS.primaryColor};
  color: ${COLORS.primaryColor};
  border-radius: 5px;
  padding: 5px 10px;
  margin: 15px 0;
`;

const SMySelect = styled(MySelect)`
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 400px;
    width: 100%;
  }
`;
