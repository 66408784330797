import styled from 'styled-components';

import { COLORS } from '../../../style/colors';
import { EconomicModelEnum } from '../../../types/EconomicModel.enum';

export const ColoredTitle = styled.span<{ model: string }>`
  color: ${(props) =>
    props.model === 'selfConsumption'
      ? COLORS.adminBlue1
      : props.model === 'fullResale'
      ? COLORS.adminPurple0
      : COLORS.adminGreen1};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ColoredSquare = styled.div<{ model: string }>`
  background-color: ${(props) =>
    props.model === 'selfConsumption'
      ? COLORS.adminBackgroundBlue
      : props.model === 'fullResale'
      ? COLORS.adminBackgroundPurple
      : COLORS.adminBackgroundGreen};
  margin-top: 12px;
  padding: 12px 24px;
`;

export const allModels = [
  'consumptionAndResale',
  'selfConsumption',
  'fullResale',
] as EconomicModelEnum[];

export const modelsWithoutSelfConsumption = [
  'consumptionAndResale',
  'fullResale',
] as EconomicModelEnum[];
