import React from 'react';
import { clientRoutes } from '../../routing/clientRoutes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { initialContext, useNewStudy } from '../../contexts/NewStudyContext';
import { useMediaQuery } from '@mui/material';

export const NewSimulationButton = ({
  setIsExplanationModalOpen,
}: {
  setIsExplanationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const dontShowExplanation = localStorage.getItem('dontShowExplanation') || false;

  const onClick = () => {
    setStudyContext(initialContext);

    if (dontShowExplanation) {
      navigate(clientRoutes.newSimulation);
    } else {
      setIsExplanationModalOpen(true);
    }
  };

  return (
    <button
      onClick={onClick}
      style={
        isWeb
          ? {
              width: 'fit-content',
            }
          : { marginRight: 10, marginLeft: 10 }
      }>
      {t('newSimulation')}
    </button>
  );
};
