import { Timestamp } from 'firebase/firestore';

import { Results } from './Results';
import { ClientVariables } from './ClientVariables';
import { EconomicModelEnum } from './EconomicModel.enum';
import { ChosenProduct } from './ProductTypes';

export const StudyStatus = [
  'INITIALIZED',
  'COMPLETED',
  'ORDER_COMPLETED',
  'ORDER_NOT_COMPLETED',
] as const;
export type StudyStatus = (typeof StudyStatus)[number];

export type StudyForSearch = {
  authorName: string;
  authorUid: string;
  clientFirstName: string;
  clientLastName: string;
  departmentCode: string;
  economicModels: EconomicModelEnum[];
  status: StudyStatus;
  statusUpdateDate: Timestamp;
  title: string;
  assignment?: {
    uid: string;
    firstName?: string;
    lastName?: string;
  };
  externalId?: string;
  isArchived?: boolean;
  isCustomPowerSelected?: boolean;
  power?: number; //= customPower or results.recommendedPower
  uid?: string;
  zipCode?: string;
};

export type Study = StudyForSearch & {
  id?: string;
  authorUid: string;
  clientEmail: string;
  clientPhoneNumber: string;
  creationDate: Timestamp;
  customPower?: number;
  customPowerResults?: Results;
  heating: string[];
  updatedAt?: Timestamp;
  product?: string;
  region?: string;
  sector?: string;
  departmentCode?: string;
  results?: Results;
  address?: string;
  city?: string;
  country?: string;
  chosenProducts: ChosenProduct[];
  hasAcceptedMarketing: boolean;
  hasAcceptedData: boolean;
  shouldDisplayPrice?: boolean;
};

export type CompleteStudy = Study & ClientVariables;
