export const correctRoofSurfaceWithSlope = (
  roofSurface: number,
  slope: string | undefined
): number => {
  if (!slope) return Math.round(roofSurface);

  let slopeNumber;

  if (slope === 'NC') {
    slopeNumber = 30;
  } else if (slope === '90') {
    slopeNumber = 80;
  } else {
    slopeNumber = Number(slope);
  }

  return Math.round(roofSurface / Math.cos((slopeNumber * Math.PI) / 180));
};
