import React from 'react';
import { ClientFormValues } from './NewStudyConfirmationModalHelper';
import { Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, useMediaQuery } from '@mui/material';
import { v4 } from 'uuid';

import { ClientVariables } from '../../types/ClientVariables';
import { defaultAltitudeWhenUnknown } from '../../util/constants';
import { fromOrientationToFrenchName } from '../../types/Orientations.enum';
import { getData, setData, updateData } from '../../firebase/firestoreQueries';
import {
  studiesCollection,
  studiesForSearchCollection,
  usersCollection,
} from '../../firebase/firestoreCollections';
import { serverRoutes } from '../../routing/serverRoutes';
import { Results } from '../../types/Results';
import { snackSuccess } from '../../util/snacks';
import { clientRoutes } from '../../routing/clientRoutes';
import { useNewStudy } from '../../contexts/NewStudyContext';
import { User } from '../../types/User';
import { useAuth } from '../../auth/authContext';
import theme from '../../style/mui-theme';
import _ from 'lodash';

export const createExternalId = (author: User | undefined, uid: string) => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${author?.firstName?.slice(0, 1)}${author?.lastName}${day}${month}${year}_${uid.slice(
    0,
    4
  )}`;
};

export const SubmitStudyButton = ({
  handleSubmit,
  isValid,
  isPolicy0Accepted,
  isPolicy1Accepted,
  setIsLoading,
  closeModal,
}: {
  handleSubmit: any;
  isValid: boolean;
  isPolicy0Accepted: boolean;
  isPolicy1Accepted: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuth();
  const { studyContext, setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));

  const [author, setAuthor] = React.useState<User>();

  React.useEffect(() => {
    (async () => {
      if (user) {
        const userData = await getData(usersCollection, user.uid);
        setAuthor(userData);
      }
    })();
  }, [user]);

  const onSubmit = async (data: ClientFormValues) => {
    setIsLoading(true);
    if (!studyContext.formValues) {
      return;
    }

    const uid = studyContext.study?.uid || v4();
    // @ts-ignore
    const externalId = studyContext.study?.externalId || createExternalId(author, uid);
    // @ts-ignore
    const authorUid = studyContext.study?.authorUid || author?.uid || '';
    const authorName =
      // @ts-ignore
      studyContext.study?.authorName || `${author?.firstName || ''} ${author?.lastName || ''}`;

    const formValues = _.omit(studyContext.formValues, 'customPower');

    const completeForm = {
      authorUid,
      authorName,
      ...formValues,
      address: data.address,
      country: data.country,
      city: data.city,
      clientFirstName: data.clientFirstName,
      clientLastName: data.clientLastName,
      clientPhoneNumber: data.clientPhoneNumber,
      clientEmail: data.clientEmail,
      externalId,
      creationDate: Timestamp.fromDate(new Date()),
      title: data.title,
      status: 'INITIALIZED' as const,
      statusUpdateDate: Timestamp.fromDate(new Date()),
      updatedAt: Timestamp.fromDate(new Date()),
      zipCode: data.zipCode,
    };

    const study: ClientVariables = {
      ...completeForm,
      uid,
      altitude:
        studyContext.formValues.altitude === 'NC'
          ? defaultAltitudeWhenUnknown
          : Number(studyContext.formValues.altitude),
      annexes: completeForm.annexes || [],
      domesticHotWater: completeForm.domesticHotWater[0] || '',
      hasAirConditioning: !!completeForm.annexes?.includes('airConditioning'),
      hasElectricHeating: completeForm.heating.includes('electricHeating'),
      hasHeatPump: completeForm.heating.includes('heatPump'),
      hasStove:
        completeForm.heating.includes('boiler') || !!completeForm.annexes?.includes('stove'),
      orientation: fromOrientationToFrenchName(studyContext.formValues.orientation),
      hasAcceptedData: isPolicy0Accepted,
      hasAcceptedMarketing: isPolicy1Accepted,
      equipments: {
        ...completeForm.equipments,
        boiler: completeForm.heating.includes('boiler') ? 1 : 0,
      },
      annualElectricConsumption: studyContext.formValues.annualElectricConsumption
        ? Number(studyContext.formValues.annualElectricConsumption)
        : undefined,
      annualElectricBill: studyContext.formValues.annualElectricBill
        ? Number(studyContext.formValues.annualElectricBill)
        : undefined,
      monthlyElectricBill: studyContext.formValues.monthlyElectricBill
        ? Number(studyContext.formValues.monthlyElectricBill)
        : undefined,
    };

    await setData(studiesCollection, study);

    await setData(studiesForSearchCollection, {
      uid,
      externalId,
      status: 'INITIALIZED' as const,
      title: data.title,
      zipCode: data.zipCode,
      clientFirstName: data.clientFirstName,
      clientLastName: data.clientLastName,
      statusUpdateDate: Timestamp.fromDate(new Date()),
      authorUid,
      authorName,
      departmentCode: studyContext.formValues.departmentCode,
      economicModels: [],
    });

    const rawResponse = await fetch(serverRoutes.studyComputation, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(study),
    });
    const results: Results = await rawResponse.json();

    await updateData(studiesCollection, {
      uid,
      results,
      isCustomPowerSelected: false,
      updatedAt: Timestamp.fromDate(new Date()),
    });
    await updateData(studiesForSearchCollection, {
      uid,
      isCustomPowerSelected: false,
      power: results.recommendedPower,
    });
    setStudyContext({
      ...studyContext,
      study: { ...study, uid },
      results,
      isCustomPowerSelected: false,
    });
    setIsLoading(false);
    snackSuccess();
    closeModal();
    navigate(clientRoutes.results);
  };

  return (
    <SButton
      type="submit"
      onClick={handleSubmit(onSubmit)}
      variant="contained"
      sx={isWeb ? {} : { borderRadius: '22px', height: '46px' }}
      fullWidth={!isWeb}
      disabled={!isValid || !isPolicy0Accepted}>
      {t('next')}
    </SButton>
  );
};

const SButton = styled(Button)`
  width: 200px;
`;
