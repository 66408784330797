import React, { useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDimensions from '../util/useDimensions';
import { heightToWidthResultsChartsRatio } from '../util/constants';

export const LineChartProductionOverYears = ({
  cumulatedProductionOverYears,
}: {
  cumulatedProductionOverYears: number[];
}) => {
  const { width } = useDimensions();
  const { t } = useTranslation();
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const chartWidth = isWeb ? width / 2 - 50 : isTablet ? width / 2 - 50 : width - 100;
  const chartHeight = chartWidth * heightToWidthResultsChartsRatio;

  const dots = cumulatedProductionOverYears.map((p) => Math.round(p));

  // This a hack to avoid the chart rendering poorly (line does not fit the dots) when data is updated (by the custom power switch)
  const [data, setData] = useState<number[]>([]);
  React.useEffect(() => {
    (async () => {
      setData(dots);
    })();
  }, [dots]);

  return (
    <div className={isWeb || isTablet ? 'flex flex-col items-center' : ''}>
      <LineChart
        xAxis={[
          {
            label: t('years'),
            data: Array.from({ length: 30 }, (_, i) => i + 1),
            tickInterval: [0, 5, 10, 15, 20, 25, 30],
          },
        ]}
        yAxis={[{ label: t('kwh') }]}
        series={[
          {
            data,
            color: '#303575',
          },
        ]}
        grid={{ vertical: true, horizontal: true }}
        margin={{
          left: isWeb || isTablet ? 74 : 66,
        }}
        sx={{
          '.MuiMarkElement-root': {
            scale: '0.6',
            fill: '#ccc',
            strokeWidth: 3,
          },
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-28px, 0)',
          },
          // '.MuiChartsAxis-label': { fill: 'red' },
        }}
        width={chartWidth}
        height={chartHeight}
      />
    </div>
  );
};
