import React, { createContext, useContext, useState } from 'react';

import { StudyFormValues } from '../studies/newStudy/NewSimulationFormHelper';
import { Results } from '../types/Results';
import { ClientVariables } from '../types/ClientVariables';
import { CompleteStudy } from '../types/Study';
import { ChosenProduct, Product } from '../types/ProductTypes';
import { EconomicModelEnum } from '../types/EconomicModel.enum';
import { StudyAddress } from '../studies/newStudy/NewSimulation';

export interface NewStudyContext {
  formValues?: StudyFormValues;
  results?: Results;
  study?: ClientVariables | CompleteStudy;
  products: Product[];
  currentProduct?: Product;
  chosenProducts: ChosenProduct[];
  economicModels: EconomicModelEnum[];
  customPowerResults?: Results;
  isCustomPowerSelected: boolean;
  studyAddress?: StudyAddress;
  shouldDisplayPrice?: boolean;
}

interface ContextProps {
  studyContext: NewStudyContext;
  setStudyContext: (context: NewStudyContext) => void;
}

export const initialContext: NewStudyContext = {
  products: [],
  chosenProducts: [],
  formValues: undefined,
  study: undefined,
  economicModels: [],
  results: undefined,
  currentProduct: undefined,
  customPowerResults: undefined,
  isCustomPowerSelected: false,
  shouldDisplayPrice: true,
};

const NewStudyContext = createContext<ContextProps>({
  studyContext: initialContext,
  setStudyContext: () => null,
});

export const NewStudyProvider = ({ children }: { children: React.ReactNode }) => {
  const [studyContext, setStudyContext] = useState<NewStudyContext>(initialContext);

  return (
    <NewStudyContext.Provider value={{ studyContext, setStudyContext }}>
      {children}
    </NewStudyContext.Provider>
  );
};

export const useNewStudy = () => {
  const context = useContext(NewStudyContext);

  if (!context) {
    throw new Error('useNewStudyContext must be used within a NewStudyProvider');
  }

  return context;
};
