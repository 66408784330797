const polygonOptions = {
  editable: true,
  strokeColor: '#FF0000',
  strokeOpacity: 0.9,
  strokeWeight: 3,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
};

export const initialDrawingOptions = {
  drawingControl: true,
  polygonOptions,
};

export const polylineOptions = {
  strokeColor: '#fcba03',
  strokeOpacity: 1,
  strokeWeight: 7,
  editable: false,
  draggable: false,
  zIndex: 9,
};
