import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import * as yup from 'yup';
import { Button, InputAdornment, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';

import { PowerRanges } from './PowerRanges';
import { TitleWithIcon } from './components/TitleWithIcon';
import { settingsCollection } from '../../firebase/firestoreCollections';
import { MyInput } from '../../components/MyInput';
import { Label } from './components/Label';
import { PaddingBox } from './components/PaddingBox';
import { getAllData, updateData } from '../../firebase/firestoreQueries';
import { snackError, snackSuccess } from '../../util/snacks';

const schema = yup
  .object()
  .shape({
    annualProducibleLoss: yup
      .number()
      .typeError('mustBeANumber')
      .min(0, 'admin:mustBeBetween0And100')
      .max(100, 'admin:mustBeBetween0And100')
      .required('requiredField'),
  })
  .required();

const levelSchema = yup
  .object()
  .shape({
    selfConsumptionLevelTarget: yup
      .number()
      .typeError('mustBeANumber')
      .min(0, 'admin:mustBeBetween0And100')
      .max(100, 'admin:mustBeBetween0And100')
      .required('requiredField'),
  })
  .required();

type ProducibleLossForm = yup.InferType<typeof schema>;

type SelfConsumptionLevelTargetForm = yup.InferType<typeof levelSchema>;

export const GlobalSettings = () => {
  const { t } = useTranslation('admin');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<ProducibleLossForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit: handleSubmitLevel,
    control: controlLevel,
    reset: resetLevel,
    formState: { errors: errorsLevel, isValid: isLevelValid },
  } = useForm<SelfConsumptionLevelTargetForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(levelSchema),
  });

  React.useEffect(() => {
    (async () => {
      const settings = await getAllData(settingsCollection);
      reset({
        annualProducibleLoss: settings[0].annualProducibleLoss,
      });
      resetLevel({
        selfConsumptionLevelTarget: settings[0].selfConsumptionLevelTarget,
      });
    })();
  }, []);

  const onSubmit = async (data: ProducibleLossForm) => {
    try {
      await updateData(settingsCollection, { ...data, uid: 'uniqueSettingsUid' });
      snackSuccess();
    } catch (error) {
      snackError();
    }
  };

  const onSubmitLevel = async (data: SelfConsumptionLevelTargetForm) => {
    try {
      await updateData(settingsCollection, { ...data, uid: 'uniqueSettingsUid' });
      snackSuccess();
    } catch (error) {
      snackError();
    }
  };

  return (
    <PaddingBox withLessPadding>
      <TitleWithIcon title={t('GlobalSettings.configPowerTitle')} icon={<SettingsIcon />} />
      <PaddingBox>
        <PowerRanges />
      </PaddingBox>

      <TitleWithIcon title={t('GlobalSettings.configProducibleTitle')} icon={<SettingsIcon />} />
      <PaddingBox>
        <div className="flex items-center">
          <SLabel>
            <div className="flex">
              {t('GlobalSettings.annualLoss')}
              <Tooltip title={t('admin:tooltips.annualLoss')} className="ml-1.5">
                <Icon path={mdiHelpCircleOutline} size={1} />
              </Tooltip>
            </div>
          </SLabel>
          <InputContainer>
            <MyInput
              name="annualProducibleLoss"
              errors={errors}
              control={control}
              type="number"
              withoutLabel
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </InputContainer>
        </div>

        <div className="mb-12 mt-5 flex items-center justify-start">
          <Button onClick={handleSubmit(onSubmit)} variant="adminGreen" disabled={!isValid}>
            {t('saveChanges')}
          </Button>
        </div>
      </PaddingBox>

      <TitleWithIcon title={t('GlobalSettings.selfConsumptionLevel')} icon={<SettingsIcon />} />
      <PaddingBox>
        <div className="flex items-center">
          <SLabel>
            <div className="flex">
              {t('GlobalSettings.selfConsumptionLevelTarget')}
              <Tooltip title={t('admin:tooltips.selfConsumptionGoal')} className="ml-1.5">
                <Icon path={mdiHelpCircleOutline} size={1} />
              </Tooltip>
            </div>
          </SLabel>
          <InputContainer>
            <MyInput
              name="selfConsumptionLevelTarget"
              errors={errorsLevel}
              control={controlLevel}
              type="number"
              withoutLabel
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </InputContainer>
        </div>

        <div className="mb-12 mt-5 flex items-center justify-start">
          <Button
            onClick={handleSubmitLevel(onSubmitLevel)}
            variant="adminGreen"
            disabled={!isLevelValid}>
            {t('saveChanges')}
          </Button>
        </div>
      </PaddingBox>
    </PaddingBox>
  );
};

const InputContainer = styled('div')`
  width: 200px;
`;

const SLabel = styled(Label)`
  min-width: 320px;
`;
