import React from 'react';
import Grid from '@mui/material/Grid';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Trans, useTranslation } from 'react-i18next';

import { COLORS } from '../style/colors';
import {
  BluePercentage,
  DoubleRowItem,
  GreenPercentage,
  H2,
  Profit,
  RoundedDiv,
  Icon,
} from './components/ResultsDetailsComponents';

export const ResultsDetailsWeb = ({
  selfConsumptionPercentage,
  annualCoveragePercentage,
  year1AmountSaved,
  year30AmountSaved,
  subsidyAmount,
}: {
  selfConsumptionPercentage?: number;
  annualCoveragePercentage?: number;
  year1AmountSaved?: string;
  year30AmountSaved?: string;
  subsidyAmount?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mb-2 mt-12">{t('details')}</h1>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item md={3} xs={6} alignItems="stretch">
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <RoundedDiv>
                <div className="flex flex-col items-center">
                  <H2>{t('results.details.autoconsommationAnnuelle')}</H2>
                  <div className="flex">
                    <Tooltip
                      title={t('results.details.autoConsoInfos')}
                      sx={{ color: COLORS.primary }}>
                      <InfoIcon />
                    </Tooltip>
                    <Tooltip
                      title={t('results.details.augmenterAutoconsoInfos')}
                      sx={{ color: COLORS.primary }}>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </div>
                <GreenPercentage>{selfConsumptionPercentage}%</GreenPercentage>
              </RoundedDiv>
            </Grid>
            <Grid item xs={12}>
              <RoundedDiv>
                <div className="flex items-center">
                  <H2 className="mr-2">{t('results.details.autoproductionAnnuelle')}</H2>
                  <Tooltip
                    title={t('results.details.autoProdInfos')}
                    sx={{ color: COLORS.primary }}>
                    <InfoIcon />
                  </Tooltip>
                </div>
                <BluePercentage>{annualCoveragePercentage}%</BluePercentage>
              </RoundedDiv>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} xs={6} alignItems="stretch">
          <DoubleRowItem>
            <Icon>{'\ue925'}</Icon>
            <Profit>{year1AmountSaved}€</Profit>
            <h4 className="mb-3">{t('results.details.economieAnnee1Titre')}</h4>
            <p className="text-center">
              <Trans
                i18nKey="results.details.economieAnnee1"
                values={{
                  amountSaved: year1AmountSaved,
                }}
              />
            </p>
          </DoubleRowItem>
        </Grid>
        <Grid item xs={3} alignItems="stretch">
          <DoubleRowItem>
            <Icon>{'\ue926'}</Icon>
            <Profit>{year30AmountSaved}€</Profit>
            <h4 className="mb-3">{t('results.details.economieAnnee30Titre')}</h4>
            <p className="text-center">
              <Trans
                i18nKey="results.details.economieAnnee30"
                values={{
                  amountSaved: year30AmountSaved,
                }}
              />
            </p>
          </DoubleRowItem>
        </Grid>
        <Grid item xs={3} alignItems="stretch">
          <DoubleRowItem>
            <Icon>{'\ue927'}</Icon>
            <Profit>{subsidyAmount}€</Profit>
            <h4 className="mb-3">{t('results.details.subventionsTitre')}</h4>
            <p className="text-center">
              <Trans
                i18nKey="results.details.subventions"
                values={{
                  subsidyAmount,
                }}
              />
            </p>
          </DoubleRowItem>
        </Grid>
      </Grid>
    </>
  );
};
