import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

export const EdgeWarning = () => {
  return (
    <Warning>
      <Trans i18nKey="RoofSelector.selectEdge" />
    </Warning>
  );
};

const Warning = styled('p')`
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
  border-radius: 10px;
  padding: 5px 10px;
  animation: fade-border-in 1s 3 ease-in-out;
  border: 3px solid transparent;
  @keyframes fade-border-in {
    0% {
      border-color: rgba(224, 46, 53, 0);
    }
    50% {
      border-color: rgba(224, 46, 53, 1);
    }
    100% {
      border-color: rgba(224, 46, 53, 0);
    }
  }
`;
