import React from 'react';

const degreesToRadians = (degrees: number) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};

const hauteurTriangleRectangle = (angle: number) => {
  const sinB = Math.sin(degreesToRadians(angle));
  const bc = 1 / Math.cos(degreesToRadians(angle));
  return sinB * bc;
};

export const SlopeIcon = ({ slope }: { slope: string }) => {
  let polygon;
  if (!slope || slope === '90' || slope === '0') {
    polygon = 'polygon(0 40%, 0 60%, 100% 60%, 100% 40%)';
  } else if (Number(slope) <= 45) {
    polygon = `polygon(0 ${
      100 - hauteurTriangleRectangle(Number(slope)) * 100
    }%, 0 100%,  0 100%, 100% 100%)`;
  } else {
    polygon = `polygon(0 0, 0 100%, 0 100%, ${
      hauteurTriangleRectangle(90 - Number(slope)) * 100
    }% 100%)`;
  }

  if (slope === 'NC') {
    return null;
  }

  return <div className="triangle" style={{ clipPath: polygon }}></div>;
};
