import { Autocomplete, Button, Modal, styled, TextField } from '@mui/material';
import React from 'react';
import { ModalContent } from '../../../components/ModalContent';
import { useTranslation } from 'react-i18next';

interface AddLinkedModalProps {
  title: string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  options: any[];
  onChange: (value: any) => void;
  onValidate: () => void;
  disabled: boolean;
  noOptionsText: string;
}

export const AddLinkedModal = ({
  title,
  isModalOpen,
  setIsModalOpen,
  options,
  onChange,
  onValidate,
  disabled,
  noOptionsText,
}: AddLinkedModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={isModalOpen}>
      <SModalContent>
        <div className="mb-5 mt-7">
          <Title className="text-center">{title}</Title>
        </div>

        <div className="flex justify-center">
          <Autocomplete
            multiple
            noOptionsText={noOptionsText}
            options={options}
            getOptionLabel={(option) => option.label}
            sx={{ width: 300 }}
            onChange={(_, value) => onChange(value)}
            renderInput={(params) => <TextField {...params} label={t('common:search')} />}
          />
        </div>

        <div className="mt-8 flex justify-end">
          <div className="mr-1">
            <SButton variant="adminGrey" onClick={() => setIsModalOpen(false)}>
              {t('common:cancel')}
            </SButton>
          </div>
          <div className="ml-1">
            <SButton variant="adminGreen" onClick={onValidate} disabled={disabled}>
              {t('common:validate')}
            </SButton>
          </div>
        </div>
      </SModalContent>
    </Modal>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 15px;
`;

const SButton = styled(Button)`
  width: 250px;
`;
