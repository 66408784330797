import React from 'react';
import { Control, FieldErrors, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, InputAdornment, Tooltip, useMediaQuery } from '@mui/material';
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';

import { ProductForm } from './ProductFormHelper';
import { MyInput } from '../components/MyInput';
import { AddKitButton } from './AddKitButton';
import { useNewStudy } from '../contexts/NewStudyContext';
import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection } from '../firebase/firestoreCollections';
import { Timestamp } from 'firebase/firestore';

export const ProductPrice = ({
  control,
  errors,
  handleSubmit,
  reset,
}: {
  control: Control<ProductForm>;
  errors: FieldErrors<ProductForm>;
  handleSubmit: UseFormHandleSubmit<ProductForm>;
  reset: UseFormReset<ProductForm>;
}) => {
  const { t } = useTranslation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const { studyContext, setStudyContext } = useNewStudy();

  const setDisplayPrices = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateData(studiesCollection, {
      uid: studyContext.study?.uid,
      updatedAt: Timestamp.fromDate(new Date()),
      shouldDisplayPrice: event.target.checked,
    });
    setStudyContext({ ...studyContext, shouldDisplayPrice: event.target.checked });
  };

  return (
    <div>
      <div className="mb-2 mt-7 flex items-center">
        <h2>{t('product:price.displayPrice')}</h2>
        <Tooltip
          title={t('product:price.tooltip')}
          enterTouchDelay={0}
          leaveTouchDelay={6000}
          className="mb-3 mr-7">
          <InfoIcon color="primary" />
        </Tooltip>
        <h3>{t('common:no')}</h3>
        <Switch checked={studyContext.shouldDisplayPrice} onChange={setDisplayPrices} />
        <h3>{t('common:yes')}</h3>
      </div>
      <Grid container spacing={2}>
        {studyContext.shouldDisplayPrice && (
          <Grid item xs={12} md={6}>
            <h2 className="mt-5">{t('product:price.fixezPrixVteManuelle')}</h2>
            <MyInput
              style={isWeb ? { maxWidth: 160 } : {}}
              name="sellPrice"
              errors={errors}
              control={control}
              type="number"
              withoutLabel
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{t('product:price.euroTTC')}</InputAdornment>
                ),
              }}
            />

            <h2 className="mt-5">{t('product:price.prixSbe')}</h2>
            <MyInput
              style={isWeb ? { maxWidth: 160 } : {}}
              name="connectionPrice"
              errors={errors}
              control={control}
              type="number"
              withoutLabel
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{t('product:price.euroTTC')}</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={studyContext.shouldDisplayPrice ? 6 : 12}>
          <AddKitButton handleSubmit={handleSubmit} reset={reset} />
        </Grid>
      </Grid>
    </div>
  );
};
