import React from 'react';
import { withTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { FormControl, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { Zoom } from 'react-awesome-reveal';

const Container = styled(FormControl)`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
`;

export const Error = styled('span')`
  display: block;
  font-weight: 600;
  color: ${(props) => props.theme.palette.error.main};
  height: 0.775rem;
  padding: 0 0.675rem;
  font-size: 14px;
`;

interface SelectItem {
  value: string;
  label: string;
}

interface MySelectProps {
  control: Control<any>;
  errors: any;
  name: string;
  items: SelectItem[];
  label?: string;
  withoutLabel?: boolean;
  t: any;
}

export const MySelect = withTranslation()(({
  name,
  label,
  control,
  errors,
  items,
  withoutLabel,
  t,
  ...props
}: MySelectProps & TextFieldProps) => {
  return (
    <Container>
      <Controller
        name={name}
        render={({ field }) => (
          <TextField
            select
            variant="outlined"
            label={withoutLabel ? '' : label ? t(label) : t(name)}
            size="small"
            fullWidth
            {...props}
            {...field}>
            {items.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {t(item.label)}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
      />

      {errors[name]?.message && (
        <Zoom direction="left">
          <Error>{t(errors[name].message)}</Error>
        </Zoom>
      )}
    </Container>
  );
});
