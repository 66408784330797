import React from 'react';
import { Button, Modal } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

import { ModalContent } from '../components/ModalContent';
import { useNewStudy } from '../contexts/NewStudyContext';
import { clientRoutes } from '../routing/clientRoutes';
import { useNavigate } from 'react-router-dom';

export const ConsumptionAlertModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { studyContext } = useNewStudy();

  const study = studyContext.study;
  const results = studyContext.results;

  const [isModalOpen, setIsModalOpen] = React.useState(
    !!(
      study &&
      results &&
      study.annualElectricConsumption &&
      (study.annualElectricConsumption > 1.3 * results.computedAnnualConsumption ||
        study.annualElectricConsumption * 1.3 < results.computedAnnualConsumption)
    )
  );

  const editConsumption = () => {
    setIsModalOpen(false);
    navigate(clientRoutes.newSimulation);
  };

  if (!results || !study) {
    return null;
  }

  return (
    <Modal open={isModalOpen}>
      <SModalContent>
        <ImageContainer>
          <Image src="/images/warning.png" alt={t('common.warning')} />
        </ImageContainer>

        <div className="mb-3">
          <Title className="text-center">{t('results.warningConsommation.titre')}</Title>
        </div>

        <div className="m-4 flex justify-center">
          <p className="text-justify font-bold">
            <Trans
              i18nKey="results.warningConsommation.contenu"
              values={{
                consoAnnuelle: study.annualElectricConsumption,
                consoEstimee: Math.round(results.computedAnnualConsumption),
              }}
            />
          </p>
        </div>

        <div className="mt-5 flex justify-end">
          <div className="m-2 flex-1">
            <SButton onClick={() => setIsModalOpen(false)} variant="outlined">
              {t('common.confirm')}
            </SButton>
          </div>
          <div className="m-2 flex-1">
            <SButton onClick={editConsumption} variant="contained">
              {t('results.warningConsommation.editConsumption')}
            </SButton>
          </div>
        </div>
      </SModalContent>
    </Modal>
  );
};

const SModalContent = styled(ModalContent)`
  max-width: 100%;
`;

const Title = styled('h2')`
  color: var(--blue);
`;

const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const Image = styled('img')`
  max-width: 150px;
  min-height: 100px;
`;

const SButton = styled(Button)`
  width: 100%;
`;
