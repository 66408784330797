import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from 'react-i18next';

import { snackError, snackSuccess } from '../util/snacks';
import {
  BackgroundImage,
  FormContainer,
  FormContent,
  Line,
  SButton,
  SInput,
  SolarImage,
} from './authStyle';
import { clientRoutes } from '../routing/clientRoutes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { serverRoutes } from '../routing/serverRoutes';

type FormValues = {
  email: string;
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required('requiredField').email('invalidEmailAddress'),
  })
  .required();

export const ForgotPassword = withTranslation()(({ t }) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    const cleanEmail = encodeURIComponent(data.email.trim().toLowerCase());
    try {
      await fetch(serverRoutes.passwordReset(cleanEmail), {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (e) {
      console.log('e', e);

      return snackError();
    }

    return snackSuccess('emailHasBeenSent');
  };

  return (
    <BackgroundImage>
      <FormContainer>
        <FormContent>
          <Header>
            <SolarImage src="/images/login-solar-img.png" alt={t('solarSimulator')} />
            <h1>{t('solarSimulator')}</h1>
          </Header>
          <h3 className="mt-8 self-center">{t('forgotPassword')}</h3>
          <p className="my-8">{t('forgotPasswordDescription')}</p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SInput name="email" placeholder="email" errors={errors} control={control} trimValue />
            <div className="mt-7">
              <Trans i18nKey="cgu">
                <p className="mb-10 mt-2">
                  <a href="https://policies.google.com/privacy" className="underline" />
                  <a href="https://policies.google.com/terms" className="underline" />
                </p>
              </Trans>
            </div>
            <Line>
              <SButton
                className="mr-2"
                style={{ width: '49%' }}
                onClick={() => navigate(clientRoutes.signInChoice)}
                variant="outlinedLogin">
                {t('back')}
              </SButton>
              <ResetButton
                className="ml-2"
                style={{ width: '49%' }}
                type="submit"
                variant="contained"
                endIcon={<ChevronRightIcon />}
                disabled={!isValid}>
                {t('resetPassword')}
              </ResetButton>
            </Line>
          </Form>
        </FormContent>
      </FormContainer>
    </BackgroundImage>
  );
});

export const Form = styled('form')``;

export const ResetButton = styled(SButton)`
  white-space: nowrap;
`;

export const Header = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
`;
