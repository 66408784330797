import React from 'react';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { Trans, withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { createUser } from '../firebase/firestoreQueries';
import { snackError, snackSuccess } from '../util/snacks';
import {
  BackgroundImage,
  Form,
  FormContainer,
  FormContent,
  Line,
  SInput,
  SolarImage,
} from './authStyle';
import { isSIRET } from '../util/formValidators/siretValidator';
import { isPhoneNumber } from '../util/formValidators/phoneNumberValidator';
import styled from 'styled-components';
import { MySelect } from '../components/MySelect';
import { isFrenchZipCode } from '../util/formValidators/frenchZipCodeValidator';
import { isPasswordValid } from '../util/formValidators/passwordValidator';
import { Button } from '@mui/material';
import { clientRoutes } from '../routing/clientRoutes';

export const EmailSignUp = withTranslation()(({ t }) => {
  const navigate = useNavigate();

  yup.addMethod(yup.number, 'siret', function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
      const { path, createError } = this;

      return isSIRET(value) || createError({ path, message: errorMessage });
    });
  });

  const schema = yup
    .object()
    .shape({
      address: yup.string(),
      city: yup.string().required('requiredField'),
      companyName: yup.string().required('requiredField'),
      country: yup.string(),
      email: yup.string().required('requiredField').email('invalidEmailAddressLongVersion'),
      firstName: yup.string().required('requiredField'),
      gender: yup.string().required('requiredField'), //.oneOf(['male', 'female']),
      lastName: yup.string().required('requiredField'),
      password: yup
        .string()
        .required('requiredField')
        .test('password', 'invalidPassword', (value) => isPasswordValid(value)),
      passwordConfirm: yup
        .string()
        .required('requiredField')
        .oneOf([yup.ref('password')], t('passwordsMustMatch')),
      phoneNumber: yup
        .string()
        .test(
          'phoneNumber',
          'invalidPhoneNumber',
          (value) =>
            value === undefined || value === '' || isPhoneNumber((value || '').replace(/ /g, ''))
        ),
      siret: yup
        .number()
        .typeError('mustBeANumber')
        .required('requiredField')
        .test('siret', 'invalidSiret', (value) => isSIRET(value)),
      zipCode: yup
        .string()
        .required('requiredField')
        .test('zipCode', 'invalidZipCode', (value) => isFrenchZipCode(value)),
    })
    .required();

  type FormValues = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      address: '',
      city: '',
      companyName: '',
      country: undefined,
      email: '',
      firstName: '',
      gender: undefined,
      lastName: '',
      password: '',
      passwordConfirm: '',
      phoneNumber: '',
      siret: undefined,
      zipCode: '',
    },
  });

  const onSubmit = (data: FormValues) => {
    createUserWithEmailAndPassword(getAuth(), data.email, data.password)
      .then(async (userCredential) => {
        const user = { ...data, uid: userCredential.user.uid };
        delete (user as { password?: string }).password;
        delete (user as { passwordConfirm?: string }).passwordConfirm;
        // @ts-ignore
        await createUser(user);
        snackSuccess('accountCreated');
        return navigate(clientRoutes.home);
      })
      .catch((error) => {
        if (error.code === 'auth/emailAlreadyInUse') {
          return snackError('emailAlreadyInUse');
        } else {
          return snackError();
        }
      });
  };

  return (
    <BackgroundImage>
      <SFormContainer>
        <FormContent>
          <div className="flex flex-row items-center">
            <SolarImage src="/images/login-solar-img.png" alt={t('solarSimulator')} />
            <h1>{t('solarSimulator')}</h1>
          </div>
          <h3 className="my-10 text-center font-bold">{t('registrationRequest')}</h3>
          <SH4>{t('personalInformation')}</SH4>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SInput
              placeholder="companyName"
              name="companyName"
              errors={errors}
              control={control}
              required
            />
            <SInput placeholder="siret" name="siret" errors={errors} control={control} required />
            <SelectContainer>
              <MySelect
                name="gender"
                errors={errors}
                control={control}
                items={[
                  { value: 'male', label: 'maleAbbreviation' },
                  { value: 'female', label: 'femaleAbbreviation' },
                ]}
                required
              />
            </SelectContainer>
            <SInput
              name="firstName"
              placeholder="firstName"
              errors={errors}
              control={control}
              required
            />
            <SInput
              name="lastName"
              placeholder="lastName"
              errors={errors}
              control={control}
              required
            />
            <SInput
              name="phoneNumber"
              placeholder="phoneNumber"
              errors={errors}
              control={control}
            />
            <SInput name="address" placeholder="address" errors={errors} control={control} />
            <SInput
              name="zipCode"
              placeholder="zipCode"
              errors={errors}
              control={control}
              required
            />
            <SInput name="city" placeholder="city" errors={errors} control={control} required />
            <SInput name="country" placeholder="country" errors={errors} control={control} />
            <SH4>{t('connectionInfo')}</SH4>
            <SInput placeholder="email" name="email" errors={errors} control={control} required />
            <SInputWithMoreMargin
              placeholder="password"
              name="password"
              type="password"
              errors={errors}
              control={control}
              required
            />
            <SInputWithMoreMargin
              placeholder="passwordConfirm"
              name="passwordConfirm"
              type="password"
              errors={errors}
              control={control}
              required
            />

            <Trans i18nKey="signUPCgu">
              <p className="mt-8 text-sm" style={{ lineHeight: 1.1 }}>
                <a href="mailto:donneespersonnelles@edilians.com" className="underline" />
                <a
                  href={clientRoutes.policies}
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                />
              </p>
            </Trans>

            <Trans i18nKey="cgu">
              <p className="my-10 text-sm" style={{ lineHeight: 1.1 }}>
                <a href="https://policies.google.com/privacy" className="underline" />
                <a href="https://policies.google.com/terms" className="underline" />
              </p>
            </Trans>

            <Line>
              <SButton onClick={() => navigate(clientRoutes.signInChoice)} variant="outlinedLogin">
                {t('back')}
              </SButton>
              <SButton type="submit" variant="contained" disabled={!isValid}>
                {t('createAccount')}
              </SButton>
            </Line>
          </Form>
        </FormContent>
      </SFormContainer>
    </BackgroundImage>
  );
});

const SFormContainer = styled(FormContainer)`
  padding-bottom: 10px;
`;

const SH4 = styled('h4')`
  align-self: flex-start;
  margin-left: 6px;
  margin-top: 20px;
  font-weight: bold;
`;

const SButton = styled(Button)`
  width: 200px;
`;

const SelectContainer = styled('div')`
  width: 100%;
  margin-top: 7px;
`;

const SInputWithMoreMargin = styled(SInput)`
  margin-top: 17px !important;
`;
