export const NonActivatedUser = () => {
  return (
    <div>
      <h1 className="mb-10 text-center">Compte non activé</h1>
      <h4 className="m-10">
        Votre compte n'est pas activé. Un administrateur doit l'activer pour que vous puissiez vous
        connecter
      </h4>
    </div>
  );
};
