import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection } from '../firebase/firestoreCollections';
import { Timestamp } from 'firebase/firestore';
import { clientRoutes } from '../routing/clientRoutes';
import { NewStudyContext } from '../contexts/NewStudyContext';
import { NavigateFunction } from 'react-router-dom';

export const goToSynthesis = async (studyContext: NewStudyContext, navigate: NavigateFunction) => {
  const uid = studyContext.study?.uid;
  if (!uid) {
    return;
  }

  const updateStudyData = {
    uid,
    updatedAt: Timestamp.fromDate(new Date()),
    chosenProducts: studyContext.chosenProducts,
    shouldDisplayPrice: !!studyContext.shouldDisplayPrice,
  };

  await updateData(studiesCollection, updateStudyData);
  navigate(clientRoutes.synthesis);
};
