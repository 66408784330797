import React from 'react';
import { LessThan4KitsModal } from './LessThan4KitsModal';
import { snackError } from '../util/snacks';
import { useNewStudy } from '../contexts/NewStudyContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { goToSynthesis } from './goToSynthesis';

export const ProductsSubmitButton = () => {
  const { studyContext } = useNewStudy();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLessThan4KitModalOpen, setIsLessThan4KitModalOpen] = React.useState(false);

  const shouldShow4KitsModal = !(localStorage.getItem('dontShow4KitsAlert') === 'true');

  const onClickSubmit = async () => {
    const chosenProducts = studyContext.chosenProducts;

    if (!chosenProducts.length) {
      snackError(t('product:kits.atLeastOneKit'));
    } else if (shouldShow4KitsModal && chosenProducts.length < 4) {
      setIsLessThan4KitModalOpen(true);
    } else {
      return goToSynthesis(studyContext, navigate);
    }
  };

  return (
    <>
      <SButton onClick={onClickSubmit} variant="contained">
        {t('product:consultSynthesis')}
      </SButton>

      <LessThan4KitsModal
        isOpen={isLessThan4KitModalOpen}
        close={() => setIsLessThan4KitModalOpen(false)}
      />
    </>
  );
};

const SButton = styled(Button)`
  width: 100%;
  margin-top: 25px !important;
`;
