import React from 'react';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, useMediaQuery } from '@mui/material';

import { bestModelAndSaving } from './modelsComputations';
import { EconomicModelEnum } from '../types/EconomicModel.enum';
import { useNewStudy } from '../contexts/NewStudyContext';
import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection, studiesForSearchCollection } from '../firebase/firestoreCollections';
import { Timestamp } from 'firebase/firestore';

export const EconomicModels = () => {
  const { t } = useTranslation();
  const { studyContext, setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const results = studyContext.isCustomPowerSelected
    ? studyContext.customPowerResults
    : studyContext.results;

  const changeEconomicModel = async (model: EconomicModelEnum) => {
    if (studyContext.study) {
      await updateData(studiesCollection, {
        uid: studyContext.study.uid,
        economicModels: [model],
        updatedAt: Timestamp.fromDate(new Date()),
      });
      await updateData(studiesForSearchCollection, {
        uid: studyContext.study.uid,
        economicModels: [model],
      });
    }
    setStudyContext({ ...studyContext, economicModels: [model] });
  };

  React.useEffect(() => {
    if (results && studyContext.economicModels.length === 0) {
      const bestModel = bestModelAndSaving(results.models).bestModel;
      updateData(studiesCollection, {
        uid: studyContext.study?.uid || undefined,
        economicModels: [bestModel],
        updatedAt: Timestamp.fromDate(new Date()),
      });
      updateData(studiesForSearchCollection, {
        uid: studyContext.study?.uid || undefined,
        economicModels: [bestModel],
      });
      setStudyContext({
        ...studyContext,
        economicModels: [bestModel],
      });
    } else if (studyContext.economicModels.length === 0) {
      const defaultModels = ['consumptionAndResale' as const];
      updateData(studiesCollection, {
        uid: studyContext.study?.uid || undefined,
        economicModels: defaultModels,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      updateData(studiesForSearchCollection, {
        uid: studyContext.study?.uid || undefined,
        economicModels: defaultModels,
      });
      setStudyContext({
        ...studyContext,
        economicModels: defaultModels,
      });
    }
  }, [results]);

  if (!results || studyContext.economicModels.length === 0) {
    return null;
  }

  return (
    <>
      <h1 className={isWeb ? 'pb-10' : 'pb-3 pt-4'}>{t('results.economicModel.titre')}</h1>
      <Grid container spacing={2}>
        {isWeb && (
          <Grid item xs={12} md={6} className="flex">
            <ChosenModel>
              <Trans
                i18nKey="results.economicModel.presentation1"
                values={{
                  economicModel: t(
                    `results.economicModel.${bestModelAndSaving(results.models).bestModel}`
                  ),
                }}
              />
            </ChosenModel>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <ChosenModel>
            <Trans
              i18nKey="results.economicModel.presentation2"
              values={{
                amountSaved: bestModelAndSaving(results.models)[studyContext.economicModels[0]]
                  .saving,
                depenseAnnee30: Math.round(results.costOfElectricityPurchaseOver30Years),
              }}>
              <span className="red" />
              <span className="info" />
            </Trans>
          </ChosenModel>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
        <Grid item md={4} xs={12}>
          <ModelButton
            onClick={() => changeEconomicModel('selfConsumption')}
            variant={
              studyContext.economicModels[0] === 'selfConsumption' ? 'contained' : 'outlined'
            }
            sx={isWeb ? { padding: 2 } : {}}>
            {t('results.economicModel.selfConsumption')}{' '}
            {!isWeb &&
              bestModelAndSaving(results.models).bestModel === 'selfConsumption' &&
              t('results.economicModel.recommande')}
          </ModelButton>
        </Grid>
        <Grid item md={4} xs={12}>
          <ModelButton
            onClick={() => changeEconomicModel('consumptionAndResale')}
            variant={
              studyContext.economicModels[0] === 'consumptionAndResale' ? 'contained' : 'outlined'
            }
            sx={isWeb ? { padding: 2 } : {}}>
            {t('results.economicModel.consumptionAndResale')}{' '}
            {!isWeb &&
              bestModelAndSaving(results.models).bestModel === 'consumptionAndResale' &&
              t('results.economicModel.recommande')}
          </ModelButton>
        </Grid>
        <Grid item md={4} xs={12}>
          <ModelButton
            onClick={() => changeEconomicModel('fullResale')}
            variant={studyContext.economicModels[0] === 'fullResale' ? 'contained' : 'outlined'}
            sx={isWeb ? { padding: 2 } : {}}>
            {t('results.economicModel.fullResale')}{' '}
            {!isWeb &&
              bestModelAndSaving(results.models).bestModel === 'fullResale' &&
              t('results.economicModel.recommande')}
          </ModelButton>
        </Grid>
      </Grid>
    </>
  );
};

const ChosenModel = styled('div')`
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
`;

const ModelButton = styled(Button)`
  font-weight: normal !important;
  width: 100%;
`;
