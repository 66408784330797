import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import styled from 'styled-components';

import { monthIndexToName } from '../util/monthIndexToName';
import { Results } from '../types/Results';
import useDimensions from '../util/useDimensions';
import { heightToWidthResultsChartsRatio } from '../util/constants';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useNewStudy } from '../contexts/NewStudyContext';

export const BarChartOverMonths = ({ results }: { results: Results }) => {
  const { t } = useTranslation();
  const { width } = useDimensions();
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const { studyContext } = useNewStudy();

  const valueFormatter = (value: number | null) => `${value}Kwh`;

  const selfConsumptionSeries = {
    dataKey: 'selfConsumption',
    label: t('results.charts.selfConsumption'),
    valueFormatter,
    stack: 'total',
    color: '#5d906e',
  };
  const mustBeBoughtSeries = {
    dataKey: 'mustBeBought',
    label: t('mustBeBought'),
    valueFormatter,
    stack: 'total',
    color: '#af1e23',
  };
  const remainingSeries = {
    dataKey: 'remaining',
    label: t('remaining'),
    valueFormatter,
    stack: 'total',
    color: '#0b5c94',
  };

  const initialSeries = [selfConsumptionSeries, mustBeBoughtSeries, remainingSeries];

  const [series, setSeries] = React.useState(initialSeries);

  const chartWidth = isWeb ? width / 2 - 50 : isTablet ? width / 2 - 50 : width - 90;
  const chartHeight = chartWidth * heightToWidthResultsChartsRatio;

  const formattedMonths = Object.values(results.monthlyResults).map((month, index) => {
    return {
      month: t(monthIndexToName[index]),
      remaining:
        studyContext.economicModels[0] === 'fullResale'
          ? Math.round(month.remaining + month.selfConsumption)
          : Math.round(month.remaining),
      ...(!(studyContext.economicModels[0] === 'fullResale') && {
        selfConsumption: Math.round(month.selfConsumption),
      }),
      mustBeBought: Math.round(month.mustBeBought),
    };
  });

  const chartSetting = {
    slotProps: { legend: { hidden: true } },
    yAxis: [
      {
        label: 'Kwh',
      },
    ],
    width: chartWidth,
    height: chartHeight,
  };

  const onLegendClick = (model: string) => {
    if (series.map((s) => s.dataKey).indexOf(model) > -1) {
      setSeries(series.filter((s) => s.dataKey !== model));
    } else {
      setSeries([...series, initialSeries.filter((s) => s.dataKey === model)[0]]);
    }
  };

  return (
    <div className={isWeb || isTablet ? 'flex flex-col items-center' : ''}>
      <Legends>
        {!(studyContext.economicModels[0] === 'fullResale') && (
          <Legend onClick={() => onLegendClick('selfConsumption')} style={{ marginRight: 6 }}>
            <GreenSquare></GreenSquare>
            {t('results.charts.selfConsumption')}
          </Legend>
        )}
        <Legend onClick={() => onLegendClick('mustBeBought')} style={{ marginRight: 6 }}>
          <RedSquare></RedSquare>
          {t('mustBeBought')}
        </Legend>
        <Legend onClick={() => onLegendClick('remaining')}>
          <BlueSquare></BlueSquare>
          {t('remaining')}
        </Legend>
      </Legends>

      <BarChart
        margin={{
          left: isWeb || isTablet ? 66 : 56,
        }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-16px, 0)',
          },
        }}
        dataset={formattedMonths}
        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
        series={series}
        grid={{ vertical: true, horizontal: true }}
        {...chartSetting}
      />
    </div>
  );
};

const Legends = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: -15px;
`;

const Legend = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
`;

const Square = styled('div')`
  height: 15px;
  width: 35px;
  margin-right: 4px;
`;

const RedSquare = styled(Square)`
  background-color: #af1e23;
`;

const BlueSquare = styled(Square)`
  background-color: #0b5c94;
`;

const GreenSquare = styled(Square)`
  background-color: #5d906e;
`;
