import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Box, Button, InputAdornment, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { Timestamp } from 'firebase/firestore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';

import { TitleWithIcon } from './components/TitleWithIcon';
import { MyInput } from '../../components/MyInput';
import { Label } from './components/Label';
import { PaddingBox } from './components/PaddingBox';
import { getAllData, updateData } from '../../firebase/firestoreQueries';
import { edfCostsCollection } from '../../firebase/firestoreCollections';
import { snackError, snackSuccess } from '../../util/snacks';

const edfTariffsSchema = yup
  .object()
  .shape({
    validityDate: yup.date().required('requiredField'),
    buyPrice: yup.number().required('requiredField'),
    annualIncreaseNetwork: yup
      .number()
      .min(0, 'common:formErrors.positiveNumber')
      .required('requiredField'),
    annualIncreaseBuyback: yup
      .number()
      .min(0, 'common:formErrors.positiveNumber')
      .required('requiredField'),
  })
  .required();

type EdfTariffsFormValues = yup.InferType<typeof edfTariffsSchema>;

export const EdfTariffs = () => {
  const { t } = useTranslation('admin');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<EdfTariffsFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(edfTariffsSchema),
  });

  React.useEffect(() => {
    (async () => {
      const edfCosts = await getAllData(edfCostsCollection);
      reset({ ...edfCosts[0], validityDate: edfCosts[0]?.validityDate?.toDate() });
    })();
  }, []);

  const onSubmit = async (data: EdfTariffsFormValues) => {
    try {
      await updateData(edfCostsCollection, {
        ...data,
        validityDate: Timestamp.fromDate(data.validityDate),
        uid: 'uniqueSettingsUid',
      });
      snackSuccess();
    } catch (error) {
      console.error(error);
      snackError();
    }
  };

  return (
    <PaddingBox withLessPadding>
      <TitleWithIcon title={t('EdfTariffs.title')} icon={<PaymentsIcon />} />

      <PaddingBox>
        <Box className="flex w-full items-center justify-between" style={{ maxWidth: '480px' }}>
          <Label>{t('EdfTariffs.validityDate')}</Label>
          <InputContainer>
            <Controller
              control={control}
              name="validityDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    value={moment(field.value)}
                    inputRef={field.ref}
                    onChange={(date) => field.onChange(date)}
                    slotProps={{
                      textField: { placeholder: '', size: 'small' },
                    }}
                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                  />
                );
              }}
            />
          </InputContainer>
        </Box>
        <div className="flex w-full items-center justify-between" style={{ maxWidth: '480px' }}>
          <Label>{t('EdfTariffs.buyPrice')}</Label>
          <InputContainer>
            <MyInput
              name="buyPrice"
              errors={errors}
              control={control}
              type="number"
              withoutLabel
              withZeroPossible
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">€/kWh</InputAdornment>,
              }}
              style={{
                backgroundColor: 'white',
                marginLeft: '-5px',
              }}
            />
          </InputContainer>
        </div>
        <div
          className={
            errors.annualIncreaseNetwork
              ? 'flex w-full items-center justify-between pb-5'
              : 'flex w-full items-center justify-between'
          }
          style={{ maxWidth: '480px' }}>
          <Label>
            <div className="flex">
              {t('EdfTariffs.annualIncreaseNetwork')}
              <Tooltip title={t('admin:tooltips.networkIncrease')} className="ml-1.5">
                <Icon path={mdiHelpCircleOutline} size={1} />
              </Tooltip>
            </div>
          </Label>
          <InputContainer>
            <MyInput
              name="annualIncreaseNetwork"
              errors={errors}
              control={control}
              type="number"
              withoutLabel
              fullWidth
              withZeroPossible
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={{
                backgroundColor: 'white',
                marginLeft: '-5px',
              }}
            />
          </InputContainer>
        </div>
        <div className="flex w-full items-center justify-between" style={{ maxWidth: '480px' }}>
          <Label>
            <div className="flex">
              {t('EdfTariffs.annualIncreaseBuyback')}
              <Tooltip title={t('admin:tooltips.sellIncrease')} className="ml-1.5">
                <Icon path={mdiHelpCircleOutline} size={1} />
              </Tooltip>
            </div>
          </Label>
          <InputContainer>
            <MyInput
              name="annualIncreaseBuyback"
              errors={errors}
              control={control}
              type="number"
              withoutLabel
              withZeroPossible
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={{
                backgroundColor: 'white',
                marginLeft: '-5px',
              }}
            />
          </InputContainer>
        </div>

        <div className="mb-12 mt-5 flex items-center justify-start">
          <Button onClick={handleSubmit(onSubmit)} variant="adminGreen" disabled={!isValid}>
            {t('saveChanges')}
          </Button>
        </div>
      </PaddingBox>
    </PaddingBox>
  );
};

const InputContainer = styled('div')`
  width: 162px;
`;
