import React from 'react';
import styled from 'styled-components';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';

import { COLORS } from '../style/colors';
import { useNewStudy } from '../contexts/NewStudyContext';
import { PowerAndModel } from './PowerAndModel';
import { defaultValues, ProductForm } from './ProductFormHelper';
import { WhiteContainer } from './components/WhiteContainer';
import { Composition } from './Composition';
import { ProductPrice } from './ProductPrice';
import { SelectedProducts } from './SelectedProducts';
import { ProductsSubmitButton } from './ProductsSubmitButton';
import { MaxKitsAlertModal } from './MaxKitsAlertModal';
import { StudyAppHeader } from '../components/StudyAppHeader';
import { ScrollToTop } from '../util/ScrollToTop';
import { useParams } from 'react-router-dom';
import { getData } from '../firebase/firestoreQueries';
import { studiesCollection } from '../firebase/firestoreCollections';

export const ProductsChoice = () => {
  const { uid: uidParam } = useParams();
  const { studyContext, setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  React.useEffect(() => {
    (async () => {
      if (uidParam && studyContext.study?.uid !== uidParam) {
        const study = await getData(studiesCollection, uidParam);

        if (study) {
          setStudyContext({
            study: study,
            results: study.results,
            chosenProducts: study.chosenProducts || [],
            economicModels: study.economicModels || [],
            isCustomPowerSelected: !!study.isCustomPowerSelected,
            customPowerResults: study.customPowerResults,
            shouldDisplayPrice: study.shouldDisplayPrice ?? true,
            products: studyContext.products,
          });
        }
      }
    })();
  }, [uidParam]);

  const study = studyContext.study;
  const results = studyContext.results;

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ProductForm>({ defaultValues });

  if (!study || !results) {
    return null;
  }

  return (
    <>
      <ScrollToTop />

      <Box sx={{ flexGrow: 1 }}>
        <StudyAppHeader />

        <Background>
          <PowerAndModel />
          <SelectedProducts />
          <form>
            <WhiteContainer>
              <Composition
                control={control}
                setValue={setValue}
                watch={watch}
                slope={study.slope}
              />
              <Divider sx={isWeb ? {} : { marginTop: 2, marginBottom: 1 }} />
              <ProductPrice
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                reset={reset}
              />
            </WhiteContainer>
          </form>
          <ProductsSubmitButton />
        </Background>

        <MaxKitsAlertModal />
      </Box>
    </>
  );
};

const Background = styled('div')`
  background-color: ${COLORS.greyPurple};
  padding: 16px 12px 64px;
`;
