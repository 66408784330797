import { Box } from '@mui/material';
import React from 'react';

export const PaddingBox = ({
  children,
  withLessPadding,
}: {
  children: React.ReactNode;
  withLessPadding?: boolean;
}) => {
  return (
    <Box
      sx={{
        paddingLeft: { md: withLessPadding ? '60px' : '120px' },
      }}>
      {children}
    </Box>
  );
};
