import React from 'react';
import { Button, Checkbox, FormControlLabel, Modal } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { clientRoutes } from '../../routing/clientRoutes';
import styled from 'styled-components';
import { ModalContent } from '../../components/ModalContent';
import { initialContext, useNewStudy } from '../../contexts/NewStudyContext';

export const NewSimulationExplanationsModal = ({
  isExplanationModalOpen,
  setIsExplanationModalOpen,
}: {
  isExplanationModalOpen: boolean;
  setIsExplanationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setStudyContext } = useNewStudy();

  const [dontShowExplanationCheckBox, setDontShowExplanationCheckBox] = React.useState(
    localStorage.getItem('dontShowExplanation') === 'true'
  );

  const setDontShowExplanation = (value: boolean) => {
    setDontShowExplanationCheckBox(value);
    if (value) {
      localStorage.setItem('dontShowExplanation', 'true');
    } else {
      localStorage.removeItem('dontShowExplanation');
    }
  };

  const onClickNext = () => {
    close();
    setStudyContext(initialContext);
    navigate(clientRoutes.newSimulation);
  };

  const close = () => setIsExplanationModalOpen(false);

  return (
    <Modal open={isExplanationModalOpen} onClose={close}>
      <ModalContent>
        <ImageContainer>
          <Image src="/images/checklist.png" alt={t('checkList')} />
        </ImageContainer>

        <div className="mb-6">
          <h2 className="text-center">{t('NewSimulationExplanationsModal.title')}</h2>
        </div>

        <Trans i18nKey="NewSimulationExplanationsModal.content" />

        <div className="mb-5 mt-3 flex justify-center">
          <FormControlLabel
            control={
              <Checkbox
                value={dontShowExplanationCheckBox}
                onChange={() => setDontShowExplanation(!dontShowExplanationCheckBox)}
              />
            }
            label={t('NewSimulationExplanationsModal.noLongerDisplay')}
          />
        </div>

        <div className="flex">
          <div className="m-2 w-full">
            <SButton onClick={close} variant="outlined">
              {t('common:previous')}
            </SButton>
          </div>
          <div className="m-2 w-full">
            <SButton onClick={onClickNext}>{t('next')}</SButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Image = styled('img')`
  max-width: 150px;
  min-height: 100px;
`;

const SButton = styled(Button)`
  width: 100%;
`;
