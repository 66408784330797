import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <Center>
      <CircularProgress />
      <div>
        <h3 className="py-10 text-center">{t('loader.title')}</h3>
        <p className="py-5 text-center">{t('loader.subtitle')}</p>
      </div>
    </Center>
  );
};

const Center = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;
