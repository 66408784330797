import * as yup from 'yup';
import { ProductColor, ProductType, TileType } from '../types/ProductTypes';

export const schema = yup
  .object()
  .shape({
    type: yup.string().required('requiredField'),
    productModel: yup.string().required('requiredField'),
    color: yup.string().required('requiredField'),
    sellPrice: yup.number().typeError('mustBeANumber').required('requiredField'),
    connectionPrice: yup.number().typeError('mustBeANumber').required('requiredField'),
  })
  .required();

export type ProductForm = yup.InferType<typeof schema>;

export const defaultValues = {
  type: ProductType.TuilesSolaires,
  productModel: TileType.TerreCuiteSolaire,
  color: ProductColor.Noir,
  sellPrice: undefined,
  connectionPrice: undefined,
};
