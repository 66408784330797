import React from 'react';
import { Equipment } from './components/Equipment';
import { Controller } from 'react-hook-form';
import { FormGridLayout } from './components/FormGridLayout';
import styled from 'styled-components';
import { Error } from '../../components/MySelect';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

export const NewSimulationEquipments = ({
  control,
  getValues,
}: {
  control: any;
  getValues: any;
}) => {
  const { t } = useTranslation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const isOnError =
    getValues('equipments')?.swimmingPool === 1 &&
    getValues('equipments')?.heatedSwimmingPool === 1;

  const onDecrease = (field: any, equipmentName: string) => {
    const currentValues = getValues(field.name);
    const currentValue = currentValues[equipmentName] || 1;
    const newValue = { ...currentValues, [equipmentName]: currentValue - 1 };
    field.onChange(newValue);
  };

  const onIncrease = (field: any, equipmentName: string) => {
    const currentValues = getValues(field.name);
    const currentValue = currentValues[equipmentName] || 0;
    const newValue = { ...currentValues, [equipmentName]: currentValue + 1 };
    field.onChange(newValue);
  };

  const onSelectSelectableOnlyOnce = (field: any, subField: string, getValues: any) => {
    const currentFieldValue = getValues(field.name) || {};
    const currentSubFieldValue = currentFieldValue[subField] || 0;
    const newValue = currentSubFieldValue === 0 ? 1 : 0;
    field.onChange({ ...currentFieldValue, [subField]: newValue });
  };

  return (
    <Controller
      name="equipments"
      render={({ field }) => (
        <>
          <FormGridLayout
            withMobileLabel
            labelTranslationKey={isWeb ? '' : 'newStudy.equipments'}
            items={[
              <Equipment
                equipmentName="vacuum"
                initialCount={getValues('equipments')?.vacuum ?? 1}
                onIncrease={() => onIncrease(field, 'vacuum')}
                onDecrease={() => onDecrease(field, 'vacuum')}
                icon="\ue908"
              />,
              <Equipment
                equipmentName="kettle"
                initialCount={getValues('equipments')?.kettle ?? 1}
                icon="\ue909"
                onIncrease={() => onIncrease(field, 'kettle')}
                onDecrease={() => onDecrease(field, 'kettle')}
              />,
              <Equipment
                equipmentName="coffeemaker"
                initialCount={getValues('equipments')?.coffeemaker ?? 1}
                icon="\ue90a"
                onIncrease={() => onIncrease(field, 'coffeemaker')}
                onDecrease={() => onDecrease(field, 'coffeemaker')}
              />,
              <Equipment
                equipmentName="freezer"
                initialCount={getValues('equipments')?.freezer ?? 1}
                icon="\ue90b"
                onIncrease={() => onIncrease(field, 'freezer')}
                onDecrease={() => onDecrease(field, 'freezer')}
              />,
              <Equipment
                equipmentName="iron"
                initialCount={getValues('equipments')?.iron ?? 1}
                icon="\ue90c"
                onIncrease={() => onIncrease(field, 'iron')}
                onDecrease={() => onDecrease(field, 'iron')}
              />,
              <Equipment
                equipmentName="toaster"
                initialCount={getValues('equipments')?.toaster ?? 1}
                icon="\ue90d"
                onIncrease={() => onIncrease(field, 'toaster')}
                onDecrease={() => onDecrease(field, 'toaster')}
              />,
              <Equipment
                equipmentName="washingMachine"
                initialCount={getValues('equipments')?.washingMachine ?? 1}
                icon="\ue90e"
                onIncrease={() => onIncrease(field, 'washingMachine')}
                onDecrease={() => onDecrease(field, 'washingMachine')}
              />,
              <Equipment
                equipmentName="dishwasher"
                initialCount={getValues('equipments')?.dishwasher ?? 1}
                icon="\ue90f"
                onIncrease={() => onIncrease(field, 'dishwasher')}
                onDecrease={() => onDecrease(field, 'dishwasher')}
              />,
              <Equipment
                equipmentName="microwave"
                initialCount={getValues('equipments')?.microwave ?? 1}
                icon="\ue910"
                onIncrease={() => onIncrease(field, 'microwave')}
                onDecrease={() => onDecrease(field, 'microwave')}
              />,
              <Equipment
                equipmentName="fridge"
                initialCount={getValues('equipments')?.fridge ?? 1}
                icon="\ue90b"
                onIncrease={() => onIncrease(field, 'fridge')}
                onDecrease={() => onDecrease(field, 'fridge')}
              />,
              <Equipment
                equipmentName="dryer"
                initialCount={getValues('equipments')?.dryer ?? 1}
                icon="\ue911"
                onIncrease={() => onIncrease(field, 'dryer')}
                onDecrease={() => onDecrease(field, 'dryer')}
              />,
              <Equipment
                equipmentName="internetBox"
                initialCount={getValues('equipments')?.internetBox ?? 1}
                icon="\ue912"
                onIncrease={() => onIncrease(field, 'internetBox')}
                onDecrease={() => onDecrease(field, 'internetBox')}
              />,
              <Equipment
                equipmentName="television"
                initialCount={getValues('equipments')?.television ?? 1}
                icon="\ue913"
                onIncrease={() => onIncrease(field, 'television')}
                onDecrease={() => onDecrease(field, 'television')}
              />,
            ]}
          />
          <FormGridLayout
            labelTranslationKey="newStudy.otherEquipments"
            withMobileLabel
            items={[
              <Equipment
                equipmentName="CMV"
                initialCount={getValues('equipments')?.CMV ?? 0}
                onIncrease={() => onIncrease(field, 'CMV')}
                onDecrease={() => onDecrease(field, 'CMV')}
                icon="\ue914"
              />,
              <Equipment
                equipmentName="swimmingPool"
                isSelectedAtStart={getValues('equipments').swimmingPool > 0}
                onClick={() => onSelectSelectableOnlyOnce(field, 'swimmingPool', getValues)}
                icon="\ue915"
              />,
              <Equipment
                equipmentName="heatedSwimmingPool"
                isSelectedAtStart={getValues('equipments').heatedSwimmingPool > 0}
                onClick={() => onSelectSelectableOnlyOnce(field, 'heatedSwimmingPool', getValues)}
                icon="\ue916"
              />,
              <Equipment
                equipmentName="hammam"
                isSelectedAtStart={getValues('equipments').hammam > 0}
                onClick={() => onSelectSelectableOnlyOnce(field, 'hammam', getValues)}
                icon="\ue917"
              />,
              <Equipment
                equipmentName="electricCar"
                isSelectedAtStart={getValues('equipments').electricCar > 0}
                onClick={() => onSelectSelectableOnlyOnce(field, 'electricCar', getValues)}
                icon="\ue918"
              />,
              <Equipment
                equipmentName="electricBike"
                initialCount={getValues('equipments').electricBike ?? 0}
                onIncrease={() => onIncrease(field, 'electricBike')}
                onDecrease={() => onDecrease(field, 'electricBike')}
                icon="\ue919"
              />,
              <Equipment
                equipmentName="electricScooter"
                initialCount={getValues('equipments').electricScooter ?? 0}
                onIncrease={() => onIncrease(field, 'electricScooter')}
                onDecrease={() => onDecrease(field, 'electricScooter')}
                icon="\ue91a"
              />,
            ]}
          />
          <FormGridLayout
            items={[isOnError ? <SError>{t('newStudy.maxOneSwimmingPool')}</SError> : <SError />]}
          />
        </>
      )}
      control={control}
    />
  );
};

const SError = styled(Error)`
  margin-top: -8px;
  margin-bottom: 4px;
`;
