import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {
  BackgroundImage,
  FormContainer,
  FormContent,
  Line,
  SButton,
  SInput,
  SolarImage,
} from './authStyle';
import * as yup from 'yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmPasswordReset } from 'firebase/auth';

import { isPasswordValid } from '../util/formValidators/passwordValidator';
import { clientRoutes } from '../routing/clientRoutes';
import { Form, Header, ResetButton } from './ForgotPassword';
import { auth } from '../firebase/firebase';
import { snackError, snackSuccess } from '../util/snacks';

export const ConfirmPasswordReset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode');

  const schema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required('requiredField')
        .test('password', 'invalidPassword', (value) => isPasswordValid(value)),
      passwordConfirm: yup
        .string()
        .required('requiredField')
        .oneOf([yup.ref('password')], t('passwordsMustMatch')),
    })
    .required();

  type FormValues = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    if (!oobCode) {
      return;
    }
    try {
      await confirmPasswordReset(auth, oobCode, data.password);
      snackSuccess('passwordHasBeenReset');
      navigate(clientRoutes.signIn);
    } catch (error) {
      console.error(error);
      snackError();
    }
  };

  return (
    <BackgroundImage>
      <FormContainer>
        <FormContent>
          <Header>
            <SolarImage src="/images/login-solar-img.png" alt={t('solarSimulator')} />
            <h1>{t('solarSimulator')}</h1>
          </Header>
          <h3 className="mt-8 self-center">{t('resetThePassword')}</h3>
          <p className="mt-8 self-start">{t('enterNewPassword')}</p>
          <Form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="mb-12 mt-6 w-full">
              <SInput
                placeholder="password"
                name="password"
                type="password"
                errors={errors}
                control={control}
                required
                fullWidth
              />
            </div>
            <div className="mb-11 w-full">
              <SInput
                placeholder="passwordConfirm"
                name="passwordConfirm"
                type="password"
                errors={errors}
                control={control}
                required
                fullWidth
              />
            </div>
            <Line>
              <SButton
                className="mr-2"
                style={{ width: '49%' }}
                onClick={() => navigate(clientRoutes.signInChoice)}
                variant="outlinedLogin">
                {t('back')}
              </SButton>
              <ResetButton
                className="ml-2"
                style={{ width: '49%' }}
                type="submit"
                variant="contained"
                endIcon={<ChevronRightIcon />}
                disabled={!isValid}>
                {t('reset')}
              </ResetButton>
            </Line>
          </Form>
        </FormContent>
      </FormContainer>
    </BackgroundImage>
  );
};

export const FormGroup = styled('form')`
  width: 100%;
  max-width: 520px;

  @media only screen and (max-width: 1045px) {
    max-width: 100%;
    margin-top: 2rem;
  }
`;
