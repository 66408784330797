import { CompleteStudy } from '../../types/Study';
import { headers } from './exportSearchAsCsvHelper';
import { departments } from '../../util/departments';
import i18n from '../../locales/i18n';
import { bestModelAndSaving } from '../../studyResults/modelsComputations';
import * as XLSX from 'xlsx';

const year1AmountSaved = (study: CompleteStudy) => {
  if (
    !study.results ||
    !study.economicModels?.length ||
    !study.results.models[study.economicModels[0]]?.profitsOverYears
  ) {
    return '';
  }
  return Math.round(study.results.models[study.economicModels[0]].profitsOverYears[0]);
};

const year30AmountSaved = (study: CompleteStudy) => {
  if (
    !study.results?.models ||
    !study.results.models.selfConsumption.profitsOverYears ||
    !study.economicModels?.length
  ) {
    return '';
  }
  return bestModelAndSaving(study.results.models)[study.economicModels[0]].saving;
};

const subsidyAmount = (study: CompleteStudy) => {
  if (
    !study.results?.models ||
    !study.results.models.selfConsumption.profitsOverYears ||
    !study.economicModels?.length
  ) {
    return '';
  }
  return bestModelAndSaving(study.results.models)[study.economicModels[0]].totalSubsidies;
};

const totalExpensesWithoutSolarPanel = (study: CompleteStudy) => {
  if (!study.results?.costOfElectricityPurchaseOver30Years) {
    return '';
  }
  return Math.round(study.results.costOfElectricityPurchaseOver30Years);
};

const selfConsumptionYear0 = (study: CompleteStudy) => {
  if (!study.results || !study.economicModels?.length) {
    return '';
  }
  return Object.values(study.results.monthlyResults).reduce(
    (acc, val) => acc + val.selfConsumption,
    0
  );
};

const mustBeBoughtYear0 = (study: CompleteStudy) => {
  if (!study.results || !study.economicModels?.length) {
    return '';
  }
  return Object.values(study.results.monthlyResults).reduce(
    (acc, val) => acc + val.mustBeBought,
    0
  );
};

const remainingYear0 = (study: CompleteStudy) => {
  if (!study.results || !study.economicModels?.length) {
    return '';
  }
  return Object.values(study.results.monthlyResults).reduce((acc, val) => acc + val.remaining, 0);
};

const annualCoveragePercentage = (study: CompleteStudy) => {
  if (!study.results || !study.economicModels?.length) {
    return '';
  }

  const selfConsumption = selfConsumptionYear0(study) || 0;
  const remaining = remainingYear0(study) || 0;

  return (
    (selfConsumption + remaining) /
    // @ts-ignore
    (selfConsumption + mustBeBoughtYear0(study))
  ).toFixed(5);
};

const selfConsumptionPercentage = (study: CompleteStudy) => {
  const selfConsumption = selfConsumptionYear0(study) || 0;
  const remaining = remainingYear0(study);
  if (!selfConsumption && !remaining) {
    return '';
  }

  // @ts-ignore
  return (selfConsumption / (selfConsumption + remaining)).toFixed(5);
};

const studiesToCSV = (studies: CompleteStudy[]) => {
  const t = i18n.t;

  let rows: any[] = [];
  (studies || []).forEach((study) => {
    let row;
    try {
      row = [
        study.externalId,
        t(study.status),
        // @ts-ignore
        new Date(study.creationDate._seconds * 1000).toLocaleDateString(),
        // @ts-ignore
        new Date((study.statusUpdateDate?._seconds || 0) * 1000).toLocaleDateString(),
        study.title,
        study.authorName,
        study.region || '',
        study.sector || '',
        study.assignment || '',
        departments.find((d) => d.num_dep === study.departmentCode)?.dep_name || '',
        study.clientLastName,
        study.clientFirstName,
        study.address,
        study.zipCode,
        study.city,
        study.country,
        study.clientPhoneNumber,
        study.clientEmail,
        study.hasAcceptedData ? t('common.yes') : t('common.no'),
        study.hasAcceptedMarketing ? t('common.yes') : t('common.no'),
        study.customPower || study.results?.recommendedPower,
        study.customPower ? t('csvExport.customized') : t('csvExport.recommended'),
        study.economicModels?.length ? t(study.economicModels[0]) : '',
        year1AmountSaved(study),
        year30AmountSaved(study),
        totalExpensesWithoutSolarPanel(study),
        subsidyAmount(study),
        selfConsumptionPercentage(study),
        annualCoveragePercentage(study),
        study.results?.cumulatedProductionOverYears?.[0]
          ? Math.round(study.results.cumulatedProductionOverYears[0])
          : '',
        Math.round(study.results?.cumulatedProductionOverYears[29] || 0),
        t(`constructionPeriods.${[study.constructionPeriod]}`),
        study.altitude,
        study.livingAreaInSquareMeters,
        study.roofArea,
        study.numberOfResidents,
        study.orientation,
        study.slope,
        study.annualElectricConsumption,
        study.monthlyElectricBill,
        study.annualElectricBill,
        study.hasElectricHeating ? 1 : 0,
        study.hasHeatPump ? 1 : 0,
        study.annexes?.includes('stove') ? 1 : 0,
        study.hasAirConditioning ? 1 : 0,
        study.domesticHotWater === 'heatPumpDoubleService' ? 1 : 0,
        study.heating.indexOf('boiler') > -1 ? 1 : 0,
        study.domesticHotWater === 'electricWaterHeater' ? 1 : 0,
        study.domesticHotWater === 'heatPumpDoubleService' ? 1 : 0,
        study.domesticHotWater === 'boiler' ? 1 : 0,
        study.equipments.vacuum,
        study.equipments.kettle,
        study.equipments.coffeemaker,
        study.equipments.freezer,
        study.equipments.iron,
        study.equipments.toaster,
        study.equipments.washingMachine,
        study.equipments.dishwasher,
        study.equipments.microwave,
        study.equipments.fridge,
        study.equipments.dryer,
        study.equipments.internetBox,
        study.equipments.television,
        study.equipments.CMV,
        study.equipments.swimmingPool,
        study.equipments.heatedSwimmingPool,
        study.equipments.hammam,
        study.equipments.electricCar,
        study.equipments.electricBike,
        study.equipments.electricScooter,
        study.chosenProducts?.[0]?.name || '',
        study.chosenProducts?.[0]?.listPrice,
        study.chosenProducts?.[0]?.sellPrice &&
          study.chosenProducts?.[0]?.sellPrice + (study.chosenProducts?.[0]?.connectionPrice || 0),
        study.chosenProducts?.[0]?.sellPrice,
        study.chosenProducts?.[0]?.connectionPrice,
        study.chosenProducts?.[1]?.name || '',
        study.chosenProducts?.[1]?.listPrice,
        study.chosenProducts?.[1]?.sellPrice &&
          study.chosenProducts?.[1]?.sellPrice + (study.chosenProducts[1]?.connectionPrice || 0),
        study.chosenProducts?.[1]?.sellPrice,
        study.chosenProducts?.[1]?.connectionPrice,
        study.chosenProducts?.[2]?.name || '',
        study.chosenProducts?.[2]?.listPrice,
        study.chosenProducts?.[2]?.sellPrice &&
          study.chosenProducts?.[2]?.sellPrice + (study.chosenProducts[2]?.connectionPrice || 0),
        study.chosenProducts?.[2]?.sellPrice,
        study.chosenProducts?.[2]?.connectionPrice,
        study.chosenProducts?.[3]?.name || '',
        study.chosenProducts?.[3]?.listPrice,
        study.chosenProducts?.[3]?.sellPrice &&
          study.chosenProducts?.[3]?.sellPrice + (study.chosenProducts[3]?.connectionPrice || 0),
        study.chosenProducts?.[3]?.sellPrice,
        study.chosenProducts?.[3]?.connectionPrice,
      ];
    } catch (e) {
      console.error(`Error exporting row: ${study.uid}`, e);
    }
    if (row) {
      rows = rows.concat([row]);
    }
  });
  return rows;
};

export const exportSearchAsCsv = (studies: CompleteStudy[]): void => {
  const fileName = 'simulations-edilians.xlsx';
  const csvContent = studiesToCSV(studies);

  const worksheet = XLSX.utils.json_to_sheet(csvContent);
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
