import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { CompleteStudy, StudyForSearch, StudyStatus } from '../../types/Study';
import { useAuth } from '../../auth/authContext';
import { AppHeader } from '../../components/AppHeader';
import { StudyListItem } from './StudyListItem';
import { NewSimulationButton } from './NewSimulationButton';
import { NewSimulationExplanationsModal } from './NewSimulationExplanationsModal';
import { exportSearchAsCsv } from './exportSearchAsCsv';
import { EconomicModelEnum } from '../../types/EconomicModel.enum';
import { departments } from '../../util/departments';
import { SalesArea, Sector } from '../../types/AdminManagement';
import _ from 'lodash';
import moment from 'moment';
import { serverRoutes } from '../../routing/serverRoutes';

export const StudiesList = () => {
  const { t } = useTranslation();
  const me = useAuth();

  const isAdmin = me?.isAdmin || me?.isManager;

  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const [allVisibleToMeStudies, setAllVisibleToMeStudies] = useState<StudyForSearch[]>([]);
  const [salesAreas, setSalesAreas] = useState<SalesArea[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [numberOfStudiesToDisplay, setNumberOfStudiesToDisplay] = React.useState(40);
  const [isExplanationModalOpen, setIsExplanationModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<string>();
  const [duplicatedStudies, setDuplicatedStudies] = React.useState<StudyForSearch[]>([]);
  const [isPeriodFilterActive, setIsPeriodFilterActive] = React.useState<boolean>(true);
  const beginningOfLastYear = moment().startOf('year'); // Beginning of last year: moment([moment().year() - 1]).startOf('year')
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(beginningOfLastYear);
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(null);
  const [isPowerFilterActive, setIsPowerFilterActive] = React.useState(false);
  const [power, setPower] = React.useState<string>('');
  const [isEconomicModelActive, setIsEconomicModelActive] = React.useState(false);
  const [economicModel, setEconomicModel] = React.useState<EconomicModelEnum | string>('');
  const [isDepartmentActive, setIsDepartmentActive] = React.useState(false);
  const [departmentCode, setDepartmentCode] = React.useState('');
  const [isSalesAreaActive, setIsSalesAreaActive] = React.useState(false);
  const [salesAreaUid, setSalesAreaUid] = React.useState('');
  const [isSectorActive, setIsSectorActive] = React.useState(false);
  const [sectorUid, setSectorUid] = React.useState('');
  const [archivedStudies, setArchivedStudies] = React.useState<string[]>([]);
  const [status, setStatus] = React.useState<StudyStatus | string>('');
  const [isStatusFilterActive, setIsStatusFilterActive] = React.useState<boolean>(false);
  const [dateSorter, setDateSorter] = React.useState<'DESC' | 'ASC'>('DESC');
  const [ofWhichIAmAuthor, setOfWhichIAmAuthor] = React.useState<boolean>(false);
  const [ofWhichIAmNotAuthor, setOfWhichIAmNotAuthor] = React.useState<boolean>(false);
  const [showArchived, setShowArchived] = React.useState(false);
  const [showAssigned, setShowAssigned] = React.useState(false);
  const [showNotAssigned, setShowNotAssigned] = React.useState(false);
  const [search, setSearch] = React.useState<string>();
  const [isExportLoading, setIsExportLoading] = useState(false);

  const isSectorManager = !!(
    me?.uid &&
    allVisibleToMeStudies.some(
      (s) =>
        (s.authorUid !== me.uid && s.assignment?.uid !== me.uid) ||
        (s.assignment?.uid !== undefined && s.authorUid !== me.uid && s.assignment?.uid !== me.uid)
    )
  );

  React.useEffect(() => {
    (async () => {
      if (!me?.idToken) {
        return;
      }
      const rawResponse = await fetch(
        serverRoutes.myStudiesSalesAreasAndSectors(
          me.idToken,
          isPeriodFilterActive ? startDate?.toDate() || new Date(0) : new Date(0),
          true
        ),
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      const { salesAreas, sectors, studies } = await rawResponse.json();

      setSalesAreas(salesAreas);
      setSectors(sectors);
      setAllVisibleToMeStudies(studies);
    })();
  }, [me, startDate, isPeriodFilterActive]);

  const salesAreasWithSectors = salesAreas.map((sa) => ({
    ...sa,
    sectors: sectors.filter((s) => sa.linkedSectorsUid.includes(s.uid)),
  }));

  const selectedSaleArea =
    salesAreaUid && isSalesAreaActive && salesAreasWithSectors.find((s) => s.uid === salesAreaUid);
  const sectorsBySalesArea = selectedSaleArea
    ? sectors
        .filter((s) => selectedSaleArea.linkedSectorsUid.includes(s.uid))
        .filter((s) => (isAdmin ? true : s.managerEmail === me?.email))
    : sectors.filter((s) => (isAdmin ? true : s.managerEmail === me?.email));

  const onPowerChange = (event: SelectChangeEvent) => {
    if (!isPowerFilterActive && event.target.value) {
      setIsPowerFilterActive(true);
    }
    setPower(event.target.value);
  };
  const onEconomicModelChange = (event: SelectChangeEvent) => {
    if (!isEconomicModelActive && event.target.value) {
      setIsEconomicModelActive(true);
    }
    setEconomicModel(event.target.value as EconomicModelEnum);
  };
  const onDepartmentChange = (event: SelectChangeEvent) => {
    if (!isDepartmentActive && event.target.value) {
      setIsDepartmentActive(true);
    }
    setDepartmentCode(event.target.value);
  };
  const onSalesAreaChange = (event: SelectChangeEvent) => {
    if (!isSalesAreaActive && event.target.value) {
      setIsSalesAreaActive(true);
    }
    setSalesAreaUid(event.target.value);
  };
  const onSectorChange = (event: SelectChangeEvent) => {
    if (!isSectorActive && event.target.value) {
      setIsSectorActive(true);
    }
    setSectorUid(event.target.value);
  };
  const onStatusChange = (event: SelectChangeEvent) => {
    if (!isStatusFilterActive && event.target.value) {
      setIsStatusFilterActive(true);
    }
    setStatus(event.target.value as StudyStatus);
  };
  const onDateSorterChange = (event: SelectChangeEvent) => {
    setDateSorter(event.target.value as 'DESC' | 'ASC');
  };
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filterStudies = (allVisibleToMeStudies: StudyForSearch[] | CompleteStudy[]) => {
    return (
      allVisibleToMeStudies
        // @ts-ignore
        .concat(duplicatedStudies)
        .filter(
          (s) =>
            (showArchived && (s.isArchived || archivedStudies.includes(s.uid || ''))) ||
            (!showArchived && !s.isArchived && !archivedStudies.includes(s.uid || ''))
        )
        .sort((a, b) => {
          // @ts-ignore
          const aDate = a.statusUpdateDate?._seconds || a.creationDate?._seconds || 0;
          // @ts-ignore
          const bDate = b.statusUpdateDate?._seconds || b.creationDate?._seconds || 0;

          if (dateSorter === 'ASC') {
            return aDate - bDate;
          }
          return bDate - aDate;
        })
        .filter((study) => {
          if (isPeriodFilterActive && (startDate || endDate)) {
            // @ts-ignore
            const updateDate = study.statusUpdateDate?._seconds || 0;

            return (
              updateDate >= (startDate?.unix() || 0) &&
              updateDate <= (endDate?.unix() || moment().unix())
            );
          } else {
            return true;
          }
        })
        .filter(
          (study) =>
            !isPowerFilterActive ||
            !power ||
            (study.power === Number(power) && !study.isCustomPowerSelected) ||
            study.power === Number(power)
        )
        .filter(
          (study) =>
            !isEconomicModelActive ||
            !economicModel ||
            (study.economicModels || []).includes(
              economicModel as 'selfConsumption' | 'fullResale' | 'consumptionAndResale'
            )
        )
        .filter((study) => !isStatusFilterActive || !status || study.status === status)
        .filter(
          (study) =>
            !isDepartmentActive || !departmentCode || study.departmentCode === departmentCode
        )
        .filter((study) => {
          if (ofWhichIAmAuthor && !ofWhichIAmNotAuthor) {
            return study.authorUid === me?.uid;
          } else if (!ofWhichIAmAuthor && ofWhichIAmNotAuthor) {
            return study.authorUid !== me?.uid;
          } else {
            return true;
          }
        })
        .filter((study) => {
          if (search) {
            return (
              (study.uid || '') +
              (study.externalId || '') +
              study.authorName +
              study.title +
              (study.clientFirstName + ' ') +
              (study.clientLastName + ' ') +
              study.clientFirstName +
              study.zipCode
            )
              .toLowerCase()
              .includes(search.toLowerCase());
          } else {
            return true;
          }
        })
        .filter((study) => {
          if (showAssigned && !showNotAssigned) {
            return study.assignment;
          } else {
            return true;
          }
        })
        .filter((study) => {
          if (showNotAssigned && !showAssigned) {
            return !study.assignment;
          } else {
            return true;
          }
        })
        .filter((study) => {
          if (isSalesAreaActive) {
            const salesAreasDepartmentsCode =
              salesAreasWithSectors
                .find((sa) => sa.uid === salesAreaUid)
                ?.sectors.flatMap((s) => s.linkedDepartmentCodes) || [];

            return salesAreasDepartmentsCode.includes(study.departmentCode);
          } else {
            return true;
          }
        })
        .filter((study) => {
          if (isSectorActive) {
            const sectorsDepartmentsCode =
              sectors.find((s) => s.uid === sectorUid)?.linkedDepartmentCodes || [];

            return sectorsDepartmentsCode.includes(study.departmentCode);
          } else {
            return true;
          }
        })
    );
  };

  const studies = filterStudies(allVisibleToMeStudies);

  const exportSearch = async () => {
    setIsExportLoading(true);
    const rawResponse = await fetch(
      serverRoutes.myStudiesSalesAreasAndSectors(
        me?.idToken || '',
        startDate?.toDate() || new Date(0),
        false
      ),
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const { studies } = await rawResponse.json();
    const studiesToExport = filterStudies(studies);
    // @ts-ignore
    exportSearchAsCsv(studiesToExport);
    setIsExportLoading(false);
  };

  const filteredDepartments = departments.filter((d) => {
    if (isSalesAreaActive && sectorsBySalesArea.length) {
      return sectorsBySalesArea.find((s) => s.linkedDepartmentCodes.includes(d.num_dep));
    } else if (isSectorActive && sectorUid) {
      return sectors.find((s) => s.uid === sectorUid)?.linkedDepartmentCodes.includes(d.num_dep);
    } else {
      return true;
    }
  });

  const economicModels: EconomicModelEnum[] = [
    'consumptionAndResale',
    'selfConsumption',
    'fullResale',
  ];

  const resetFilters = () => {
    setDateSorter('DESC');
    setIsPeriodFilterActive(false);
    setStartDate(null);
    setEndDate(null);
    setIsPowerFilterActive(false);
    setPower('');
    setIsEconomicModelActive(false);
    setEconomicModel('');
    setIsStatusFilterActive(false);
    setStatus('');
    setOfWhichIAmAuthor(false);
    setOfWhichIAmNotAuthor(false);
    setSearch(undefined);
    setShowArchived(false);
    setIsDepartmentActive(false);
    setDepartmentCode('');
  };

  const addDuplicatedStudy = (study: StudyForSearch) => {
    setDuplicatedStudies(duplicatedStudies.concat([study]));
  };

  const padding = isWeb ? { padding: '0 15%' } : { padding: 0 };

  return (
    <div>
      <NewSimulationExplanationsModal
        isExplanationModalOpen={isExplanationModalOpen}
        setIsExplanationModalOpen={setIsExplanationModalOpen}
      />

      <AppHeader />

      <div
        className="home-welcome"
        style={
          isWeb
            ? {
                padding: '20px 15%',
              }
            : { padding: '20px 4px', textAlign: 'center' }
        }>
        <h1 className="mb-4">{t('welcome')}</h1>
        <NewSimulationButton setIsExplanationModalOpen={setIsExplanationModalOpen} />
      </div>

      <div
        className={'flex flex-row items-center justify-between ' + (isWeb ? 'mb-4 mt-5' : 'mt-3')}
        style={isWeb ? { padding: '0 15%' } : { padding: '0 12px' }}>
        <h1 className="mb-3">{t('studiesList.studiesList')}</h1>
        <Tooltip title={t('fullTextTooltip')}>
          <TextField
            onChange={onSearchChange}
            variant="standard"
            placeholder={t('search')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowRightAltIcon />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </div>

      <div className="mb-4" style={padding}>
        <Divider />
      </div>

      {!isWeb && (
        <div className="mt-4 flex items-center" style={{ padding: '0 4px', marginBottom: '-4px' }}>
          <Checkbox checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
          <Label>{t('studiesList.showArchivedStudies')}</Label>
        </div>
      )}

      <ListAndFilters style={padding}>
        <SList>
          {studies
            ?.slice(0, numberOfStudiesToDisplay)
            .map((study) => (
              <StudyListItem
                study={study}
                onArchive={(uid) => setArchivedStudies(archivedStudies.concat([uid]))}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                addDuplicatedStudy={addDuplicatedStudy}
                isSectorManager={isSectorManager}
                key={study.uid}
              />
            ))}

          <Center>
            <SButton
              onClick={() => setNumberOfStudiesToDisplay(numberOfStudiesToDisplay + 40)}
              variant="contained"
              disabled={studies.length <= numberOfStudiesToDisplay}>
              {t('studiesList.seeMore')}
            </SButton>
          </Center>
        </SList>
        {isWeb && (
          <Filters>
            <span className="my-2 font-bold">{t('sortBy')}</span>
            <Line>
              <Select value={dateSorter} onChange={onDateSorterChange} size="small" fullWidth>
                <MenuItem value="ASC">{t('lessRecent')}</MenuItem>
                <MenuItem value="DESC">{t('mostRecent')}</MenuItem>
              </Select>
            </Line>

            <SDivider />

            <span className="mt-4 font-bold">{t('displayOnly')}</span>

            <FormControlLabel
              control={
                <Checkbox
                  checked={isPeriodFilterActive}
                  onChange={() => setIsPeriodFilterActive(!isPeriodFilterActive)}
                />
              }
              label={t('period')}
            />
            <Line>
              <div className="mr-1">
                <DatePicker
                  slotProps={{ textField: { placeholder: t('datePlaceholder'), size: 'small' } }}
                  label={t('startDate')}
                  value={startDate}
                  onChange={(d) => {
                    setIsPeriodFilterActive(true);
                    setStartDate(d);
                  }}
                />
              </div>
              <div className="ml-1">
                <DatePicker
                  slotProps={{ textField: { placeholder: t('datePlaceholder'), size: 'small' } }}
                  label={t('endDate')}
                  value={endDate}
                  onChange={(d) => {
                    setIsPeriodFilterActive(true);
                    setEndDate(d);
                  }}
                />
              </div>
            </Line>

            <Line className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPowerFilterActive}
                    onChange={() => setIsPowerFilterActive(!isPowerFilterActive)}
                  />
                }
                label={t('power')}
              />
              <Select
                value={power}
                defaultValue=""
                onChange={onPowerChange}
                size="small"
                sx={{ width: 185 }}>
                {[1200, 1800, 2400, 3000, 3600, 4500, 4800, 6000, 9000, 12000].map((pw) => (
                  <MenuItem value={pw} key={pw}>
                    {pw}
                  </MenuItem>
                ))}
              </Select>
            </Line>

            <Line className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEconomicModelActive}
                    onChange={() => setIsEconomicModelActive(!isEconomicModelActive)}
                  />
                }
                label={t('economicModel')}
              />
              <Select
                value={economicModel}
                onChange={onEconomicModelChange}
                size="small"
                sx={{ width: 185 }}>
                {economicModels.map((e) => (
                  <MenuItem value={e} key={e}>
                    {t(`studiesList.filters.${e}`)}
                  </MenuItem>
                ))}
              </Select>
            </Line>

            <Line className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDepartmentActive}
                    onChange={() => setIsDepartmentActive(!isDepartmentActive)}
                  />
                }
                label={t('common:department')}
              />
              <Select
                value={departmentCode}
                onChange={onDepartmentChange}
                size="small"
                sx={{ width: 185 }}>
                {filteredDepartments.map((d) => (
                  <MenuItem value={d.num_dep} key={d.num_dep}>
                    {d.num_dep} - {d.dep_name}
                  </MenuItem>
                ))}
              </Select>
            </Line>
            {salesAreas?.length ? (
              <Line className="mt-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSalesAreaActive}
                      onChange={() => setIsSalesAreaActive((p) => !p)}
                    />
                  }
                  label={t('studiesList.filters.salesArea')}
                />
                <Select
                  value={salesAreaUid}
                  onChange={onSalesAreaChange}
                  size="small"
                  sx={{ width: 185 }}>
                  {salesAreas.map((s) => (
                    <MenuItem value={s.uid} key={s.uid}>
                      {_.upperFirst(s.label)} - {s.managerLastName} {s.managerFirstName}
                    </MenuItem>
                  ))}
                </Select>
              </Line>
            ) : null}

            <Line className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSectorActive}
                    onChange={() => setIsSectorActive((p) => !p)}
                  />
                }
                label={t('studiesList.filters.sector')}
              />
              <Select value={sectorUid} onChange={onSectorChange} size="small" sx={{ width: 185 }}>
                {sectorsBySalesArea.map((s) => (
                  <MenuItem value={s.uid} key={s.uid}>
                    {_.upperFirst(s.label)}
                  </MenuItem>
                ))}
              </Select>
            </Line>

            <Line className="mt-2">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isStatusFilterActive}
                    onChange={() => setIsStatusFilterActive(!isStatusFilterActive)}
                  />
                }
                label={t('status')}
              />
              <Select value={status} onChange={onStatusChange} size="small" sx={{ width: 185 }}>
                {Object.values(StudyStatus).map((st) => (
                  <MenuItem value={st} key={st}>
                    {t(st)}
                  </MenuItem>
                ))}
              </Select>
            </Line>

            <FormControlLabel
              control={
                <Checkbox
                  checked={ofWhichIAmAuthor}
                  onChange={() => setOfWhichIAmAuthor(!ofWhichIAmAuthor)}
                />
              }
              label={t('ofWhichIAmAuthor')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={ofWhichIAmNotAuthor}
                  onChange={() => setOfWhichIAmNotAuthor(!ofWhichIAmNotAuthor)}
                />
              }
              label={t('ofWhichIAmNotAuthor')}
            />

            <FormControlLabel
              control={
                <Checkbox checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
              }
              label={t('archivedStudies')}
            />

            <FormControlLabel
              control={
                <Checkbox checked={showAssigned} onChange={() => setShowAssigned(!showAssigned)} />
              }
              label={t('studiesList.assignedStudy')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showNotAssigned}
                  onChange={() => setShowNotAssigned(!showNotAssigned)}
                />
              }
              label={t('studiesList.notAssignedStudy')}
            />

            <Buttons>
              <EraseTextButton onClick={resetFilters}>{t('common:erase')}</EraseTextButton>
              <SButton
                onClick={exportSearch}
                variant="contained"
                disabled={!studies?.length || isExportLoading}>
                {t('studiesList.exportSearch')}
              </SButton>
            </Buttons>
          </Filters>
        )}
      </ListAndFilters>
    </div>
  );
};

const Line = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Center = styled('div')`
  display: flex;
  justify-content: center;
`;

const ListAndFilters = styled('div')`
  display: flex;
`;

const SDivider = styled(Divider)`
  padding-bottom: 10px;
  padding-top: 10px;
`;

const SList = styled(List)`
  flex: 1;
`;

const Filters = styled('div')`
  width: 330px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const Buttons = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const SButton = styled(Button)`
  width: 165px;
`;

const EraseTextButton = styled('span')`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: var(--primary-color);
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
`;

const Label = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  margin-left: 1px;
`;
