import styled from 'styled-components';
import { Button } from '@mui/material';
import { MyInput } from '../components/MyInput';

export const BackgroundImage = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(/images/login-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
`;

export const FormContainer = styled('div')`
  background-color: #fff;
  border-radius: 20px;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0 0 15px #0000001a;
`;

export const FormContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  max-width: 540px;
`;

export const SolarImage = styled('img')`
  width: 70px;
  height: 70px;
  margin-right: 20px;
`;

export const Line = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LineWithMargin = styled(Line)`
  margin-top: 10px;
  margin-bottom: 30px;
  text-decoration: underline;
`;

export const SButton = styled(Button)`
  width: 160px;
`;

export const Form = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SInput = styled(MyInput)`
  width: 100%;
  margin-top: 7px !important;
`;
