import React from 'react';
import { useMediaQuery } from '@mui/material';

import { bestModelAndSaving } from './modelsComputations';
import { useNewStudy } from '../contexts/NewStudyContext';
import { ResultsDetailsMobile } from './ResultsDetailsMobile';
import { ResultsDetailsWeb } from './ResultsDetailsWeb';
import { addThousandSpaces } from '../util/addThousandSpaces';

export const ResultsDetails = () => {
  const { studyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const results = studyContext.isCustomPowerSelected
    ? studyContext.customPowerResults
    : studyContext.results;

  if (!results || studyContext.economicModels.length === 0) {
    return null;
  }

  const selfConsumptionYear0 = Object.values(results.monthlyResults).reduce(
    (acc, val) => acc + val.selfConsumption,
    0
  );
  const mustBeBoughtYear0 = Object.values(results.monthlyResults).reduce(
    (acc, val) => acc + val.mustBeBought,
    0
  );
  const remainingYear0 = Object.values(results.monthlyResults).reduce(
    (acc, val) => acc + val.remaining,
    0
  );

  const selfConsumptionPercentage = Math.round(
    (selfConsumptionYear0 / (selfConsumptionYear0 + remainingYear0)) * 100
  );

  const annualProduction = selfConsumptionYear0 + remainingYear0;
  const totalHouseConsumption =
    studyContext.study?.annualElectricConsumption || selfConsumptionYear0 + mustBeBoughtYear0;

  const annualCoveragePercentage = Math.round((annualProduction / totalHouseConsumption) * 100);

  const year1AmountSaved = Math.round(
    results.models[studyContext.economicModels[0]].profitsOverYears[0]
  );
  const year30AmountSaved = Math.round(
    bestModelAndSaving(results.models)[studyContext.economicModels[0]].saving
  );
  const subsidyAmount = Math.round(
    bestModelAndSaving(results.models)[studyContext.economicModels[0]].totalSubsidies
  );

  return isWeb ? (
    <ResultsDetailsWeb
      annualCoveragePercentage={annualCoveragePercentage}
      selfConsumptionPercentage={selfConsumptionPercentage}
      year1AmountSaved={addThousandSpaces(year1AmountSaved)}
      year30AmountSaved={addThousandSpaces(year30AmountSaved)}
      subsidyAmount={addThousandSpaces(subsidyAmount)}
    />
  ) : (
    <ResultsDetailsMobile
      annualCoveragePercentage={annualCoveragePercentage}
      selfConsumptionPercentage={selfConsumptionPercentage}
      year1AmountSaved={addThousandSpaces(year1AmountSaved)}
      year30AmountSaved={addThousandSpaces(year30AmountSaved)}
      subsidyAmount={addThousandSpaces(subsidyAmount)}
    />
  );
};
