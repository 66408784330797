import { CollectionReference, collection } from 'firebase/firestore';

import { User } from '../types/User';
import { firestore } from './firebase';
import { CompleteStudy, StudyForSearch } from '../types/Study';
import { Subsidy } from '../types/Subsidy';
import {
  ConnectionCost,
  EdfCosts,
  PowerRange,
  SellPriceByKwhInEuro,
  Settings,
  TurpeCost,
  Tva,
} from '../types/AdminSettings';
import { SalesArea, Sector, SolarSector } from '../types/AdminManagement';

export const usersCollectionName = 'users';
export const usersCollection = collection(
  firestore,
  usersCollectionName
) as CollectionReference<User>;

export const studiesCollectionName = 'studies';
export const studiesCollection = collection(
  firestore,
  studiesCollectionName
) as CollectionReference<CompleteStudy>;

export const studiesForSearchCollectionName = 'studiesForSearch';
export const studiesForSearchCollection = collection(
  firestore,
  studiesForSearchCollectionName
) as CollectionReference<StudyForSearch>;

export const subsidiesCollectionName = 'subsidies';
export const subsidiesCollection = collection(
  firestore,
  subsidiesCollectionName
) as CollectionReference<Subsidy>;

export const powerRangesCollectionName = 'powerRanges';
export const powerRangesCollection = collection(
  firestore,
  powerRangesCollectionName
) as CollectionReference<PowerRange>;

export const tvaCollectionName = 'tva';
export const tvaCollection = collection(firestore, tvaCollectionName) as CollectionReference<Tva>;

export const turpeCostsCollectionName = 'turpeCosts';
export const turpeCostsCollection = collection(
  firestore,
  turpeCostsCollectionName
) as CollectionReference<TurpeCost>;

export const connectionCostsCollectionName = 'connectionCosts';
export const connectionCostsCollection = collection(
  firestore,
  connectionCostsCollectionName
) as CollectionReference<ConnectionCost>;

export const sellPriceByKwhInEuroCollectionName = 'sellPriceByKwhInEuro';
export const sellPriceByKwhInEuroCollection = collection(
  firestore,
  sellPriceByKwhInEuroCollectionName
) as CollectionReference<SellPriceByKwhInEuro>;

export const settingsCollectionName = 'settings';
export const settingsCollection = collection(
  firestore,
  settingsCollectionName
) as CollectionReference<Settings>;

export const edfCostsCollectionName = 'edfCosts';
export const edfCostsCollection = collection(
  firestore,
  edfCostsCollectionName
) as CollectionReference<EdfCosts>;

export const sectorsCollectionName = 'sectors';
export const sectorsCollection = collection(
  firestore,
  sectorsCollectionName
) as CollectionReference<Sector>;

export const salesAreasCollectionName = 'salesAreas';
export const salesAreasCollection = collection(
  firestore,
  salesAreasCollectionName
) as CollectionReference<SalesArea>;

export const solarSectorsCollectionName = 'solarSectors';
export const solarSectorsCollection = collection(
  firestore,
  solarSectorsCollectionName
) as CollectionReference<SolarSector>;
