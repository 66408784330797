import styled from 'styled-components';

export const RoundedDiv = styled('div')`
  height: 100%;
  background-color: white;
  box-shadow: 0 0 5px #d4d4e5;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DoubleRowItem = styled(RoundedDiv)``;

export const Icon = styled('span')`
  font-family: solaire, sans-serif;
  font-size: 80px;
  text-align: center;
  color: var(--primary-color);
`;

export const H2 = styled('h3')`
  text-align: center;
`;

export const Profit = styled('span')`
  color: var(--lightGreen);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 15px;
`;

export const Percentage = styled('div')`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: var(--tertiary-color);
  width: 100%;
`;

export const GreenPercentage = styled(Percentage)`
  background-color: var(--result-success);
`;

export const BluePercentage = styled(Percentage)`
  background-color: var(--result-primary);
`;
