import React from 'react';
import styled from 'styled-components';
import { Box, Button, Checkbox, FormControlLabel, Modal, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { COLORS } from '../../style/colors';
import { MyInput } from '../../components/MyInput';
import { Appendix0, Appendix1, ClientFormValues, schema } from './NewStudyConfirmationModalHelper';
import { Loader } from './components/Loader';
import { useNewStudy } from '../../contexts/NewStudyContext';
import { SubmitStudyButton } from './SubmitStudyButton';
import theme from '../../style/mui-theme';

export const NewStudyConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { studyContext } = useNewStudy();
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));

  const [appendix, setAppendix] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);

  const [isPolicy0Accepted, setIsPolicy0Accepted] = React.useState(false);
  const [isPolicy1Accepted, setIsPolicy1Accepted] = React.useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ClientFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    const addressFromMaps = {
      address: studyContext.studyAddress?.street || '',
      zipCode: studyContext.studyAddress?.zipCode || '',
      city: studyContext.studyAddress?.city || '',
      country: studyContext.studyAddress?.country || '',
    };

    if (studyContext.study) {
      reset({
        ...addressFromMaps,
        ...studyContext.study,
      });

      if (studyContext.study.hasAcceptedData) {
        setIsPolicy0Accepted(true);
      }
      if (studyContext.study.hasAcceptedMarketing) {
        setIsPolicy1Accepted(true);
      }
    } else {
      reset(addressFromMaps);
    }
  }, [studyContext.study, studyContext.studyAddress]);

  const closeModal = () => setIsModalOpen(false);

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <ModalContent style={isWeb ? { borderRadius: '10px' } : { height: '100%' }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              overflowY: 'auto',
              maxHeight: '100%',
            }}>
            {appendix === 0 ? (
              <Appendix0 setAppendix={setAppendix} />
            ) : appendix === 1 ? (
              <Appendix1 setAppendix={setAppendix} />
            ) : (
              <>
                <CheckIconContainer>
                  <CheckIcon src="/images/check.png" />
                </CheckIconContainer>
                <h3 className="text-center font-bold">{t('confirmationModalTitle')}</h3>
                <SInput
                  name="title"
                  placeholder="title"
                  errors={errors}
                  control={control}
                  style={{ marginTop: 20 }}
                />
                <Flex>
                  <SInput
                    name="clientLastName"
                    placeholder="clientLastName"
                    errors={errors}
                    control={control}
                  />
                  <SInput
                    name="clientFirstName"
                    placeholder="clientFirstName"
                    errors={errors}
                    control={control}
                  />
                </Flex>
                <SInput name="address" placeholder="address" errors={errors} control={control} />
                {isWeb ? (
                  <Flex>
                    <SInput
                      name="zipCode"
                      placeholder="zipCode"
                      errors={errors}
                      control={control}
                    />
                    <SInput name="city" placeholder="city" errors={errors} control={control} />
                    <SInput
                      name="country"
                      placeholder="country"
                      errors={errors}
                      control={control}
                    />
                  </Flex>
                ) : (
                  <>
                    <SInput
                      name="zipCode"
                      placeholder="zipCode"
                      errors={errors}
                      control={control}
                    />
                    <SInput name="city" placeholder="city" errors={errors} control={control} />
                    <SInput
                      name="country"
                      placeholder="country"
                      errors={errors}
                      control={control}
                    />
                  </>
                )}
                {isWeb ? (
                  <Flex>
                    <SInput
                      name="clientPhoneNumber"
                      placeholder="clientPhoneNumber"
                      errors={errors}
                      control={control}
                    />
                    <SInput
                      name="clientEmail"
                      placeholder="email"
                      errors={errors}
                      control={control}
                    />
                  </Flex>
                ) : (
                  <>
                    <SInput
                      name="clientPhoneNumber"
                      placeholder="clientPhoneNumber"
                      errors={errors}
                      control={control}
                    />
                    <SInput
                      name="clientEmail"
                      placeholder="email"
                      errors={errors}
                      control={control}
                    />
                  </>
                )}
                <CheckBoxWithLabel>
                  <SFormControlLabel
                    control={
                      <Checkbox
                        value={isPolicy0Accepted}
                        checked={isPolicy0Accepted}
                        onChange={() => setIsPolicy0Accepted(!isPolicy0Accepted)}
                      />
                    }
                    label=""
                  />
                  <Label onClick={() => setAppendix(0)}>{t('newStudyPolicies0')}</Label>
                </CheckBoxWithLabel>
                <CheckBoxWithLabel>
                  <SFormControlLabel
                    control={
                      <Checkbox
                        value={isPolicy1Accepted}
                        checked={isPolicy1Accepted}
                        onChange={() => setIsPolicy1Accepted(!isPolicy1Accepted)}
                      />
                    }
                    label=""
                  />
                  <Label onClick={() => setAppendix(1)}>{t('newStudyPolicies1')}</Label>
                </CheckBoxWithLabel>

                {isWeb ? (
                  <Line>
                    <SButton onClick={closeModal} variant="outlined">
                      {t('cancel')}
                    </SButton>
                    <div className="pl-2">
                      <SubmitStudyButton
                        handleSubmit={handleSubmit}
                        isValid={isValid}
                        isPolicy0Accepted={isPolicy0Accepted}
                        isPolicy1Accepted={isPolicy1Accepted}
                        setIsLoading={setIsLoading}
                        closeModal={closeModal}
                      />
                    </div>
                  </Line>
                ) : (
                  <div className="my-5">
                    <SButton
                      onClick={closeModal}
                      variant="outlined"
                      fullWidth
                      sx={{ borderRadius: '22px', height: '46px', marginBottom: 2 }}>
                      {t('cancel')}
                    </SButton>
                    <SubmitStudyButton
                      handleSubmit={handleSubmit}
                      isValid={isValid}
                      isPolicy0Accepted={isPolicy0Accepted}
                      isPolicy1Accepted={isPolicy1Accepted}
                      setIsLoading={setIsLoading}
                      closeModal={closeModal}
                    />
                  </div>
                )}
              </>
            )}
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled('div')`
  background-color: ${COLORS.white};
  width: 650px;
  max-width: 100%;
  min-height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px 0 20px;
`;

const CheckIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
`;

const CheckIcon = styled('img')`
  height: 62px;
  padding: 0 10px;
`;

const SInput = styled(MyInput)`
  width: 100%;
`;

const Flex = styled('div')`
  display: flex;
`;

const SFormControlLabel = styled(FormControlLabel)`
  margin-top: 8px;
`;

const Line = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CheckBoxWithLabel = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 14px;
`;

const Label = styled('span')`
  margin-left: -8px;
  text-decoration: underline;
  cursor: pointer;
  line-height: 1.05;
`;

const SButton = styled(Button)`
  width: 200px;
`;
