import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { clientRoutes } from './clientRoutes';
import { AuthenticationGuard } from './AuthenticationGuard';
import { EmailSignUp } from '../auth/EmailSignUp';
import { EmailSignIn } from '../auth/EmailSignIn';
import { ForgotPassword } from '../auth/ForgotPassword';
import { SignInChoice } from '../auth/SignInChoice';
import { Policies } from '../policies/Policies';
import { NewSimulation } from '../studies/newStudy/NewSimulation';
import { StudiesList } from '../studies/studiesList/StudiesList';
import { ProductsChoice } from '../productsChoice/ProductsChoice';
import { StudyResult } from '../studyResults/StudyResult';
import { Synthesis } from '../synthesis/Synthesis';
import { UsersManagementIndex } from '../admin/usersManagment/UsersManagementIndex';
import { ConfirmPasswordReset } from '../auth/ConfirmPasswordReset';
import { ParametersIndex } from '../admin/parameters/ParametersIndex';
import { FirstSamlConnectionSignUp } from '../auth/FirstSamlConnectionSignUp';
import { AdminsManagement } from '../admin/usersManagment/AdminsManagement';
import { ProfessionalUsersManagement } from '../admin/usersManagment/ProfessionalUsersManagement';
import { SectorsManagement } from '../admin/usersManagment/SectorsManagement';
import { SalesAreasManagement } from '../admin/usersManagment/SalesAreasManagement';
import { ManagersManagement } from '../admin/usersManagment/ManagersManagement';
import { SolarSectorsManagement } from '../admin/usersManagment/SolarSectorsManagement';
import { EditSector } from '../admin/usersManagment/EditSector';
import { EditSalesAreas } from '../admin/usersManagment/EditSalesAreas';
import { EditSolarSector } from 'admin/usersManagment/EditSolarSector';

export const router = createBrowserRouter([
  {
    path: clientRoutes.home,
    element: <AuthenticationGuard component={<StudiesList />} />,
  },
  {
    path: clientRoutes.signInChoice,
    element: <SignInChoice />,
  },
  {
    path: clientRoutes.signIn,
    element: <EmailSignIn />,
  },
  {
    path: clientRoutes.firstSamlConnectionSignUp,
    element: <FirstSamlConnectionSignUp />,
  },
  {
    path: clientRoutes.signUp,
    element: <EmailSignUp />,
  },
  {
    path: clientRoutes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: clientRoutes.confirmPasswordReset,
    element: <ConfirmPasswordReset />,
  },
  {
    path: clientRoutes.policies,
    element: <Policies />,
  },
  {
    path: clientRoutes.newSimulation,
    element: <AuthenticationGuard component={<NewSimulation />} />,
  },
  {
    path: clientRoutes.editSimulationRouter,
    element: <AuthenticationGuard component={<ProductsChoice />} />,
  },
  {
    path: clientRoutes.results,
    element: <AuthenticationGuard component={<StudyResult />} />,
  },
  {
    path: clientRoutes.simulationProducts,
    element: <AuthenticationGuard component={<ProductsChoice />} />,
  },
  {
    path: clientRoutes.synthesis,
    element: <AuthenticationGuard component={<Synthesis />} />,
  },
  {
    path: clientRoutes.readStudyRouter,
    element: <AuthenticationGuard component={<Synthesis />} />,
  },
  {
    path: clientRoutes.admin,
    element: <AuthenticationGuard component={<ParametersIndex />} />,
  },
  {
    path: clientRoutes.usersManagement,
    element: <AuthenticationGuard component={<UsersManagementIndex />} />,
  },
  {
    path: clientRoutes.adminsManagement,
    element: <AuthenticationGuard component={<AdminsManagement />} />,
  },
  {
    path: clientRoutes.professionalManagement,
    element: <AuthenticationGuard component={<ProfessionalUsersManagement />} />,
  },
  {
    path: clientRoutes.sectorsManagement,
    element: <AuthenticationGuard component={<SectorsManagement />} />,
  },
  {
    path: clientRoutes.editSectorRouter,
    element: <AuthenticationGuard component={<EditSector />} />,
  },
  {
    path: clientRoutes.salesAreasManagement,
    element: <AuthenticationGuard component={<SalesAreasManagement />} />,
  },
  {
    path: clientRoutes.editSalesAreaRouter,
    element: <AuthenticationGuard component={<EditSalesAreas />} />,
  },
  {
    path: clientRoutes.editSolarSectorRouter,
    element: <AuthenticationGuard component={<EditSolarSector />} />,
  },
  {
    path: clientRoutes.solarSectorsManagement,
    element: <AuthenticationGuard component={<SolarSectorsManagement />} />,
  },
  {
    path: clientRoutes.managersManagement,
    element: <AuthenticationGuard component={<ManagersManagement />} />,
  },
]);
