import React from 'react';
import styled from 'styled-components';

export const Icon = ({
  icon,
  ...props
}: { icon: string } & React.InputHTMLAttributes<HTMLInputElement>) => {
  const unicodeCharacter = String.fromCharCode(parseInt(icon.replace(/\\u/, ''), 16));

  return <SIcon {...props}>{unicodeCharacter}</SIcon>;
};

const SIcon = styled('span')`
  font-family: solaire, sans-serif;
`;
