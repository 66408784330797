import { TextField } from '@mui/material';
import { ActionIconContainer } from './AdminComponents';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import React, { useState } from 'react';

interface TitleWithLabelEditProps {
  title: string;
  label: string;
  updateLabel: (label: string) => void;
}

export const TitleWithLabelEdit = ({ title, label, updateLabel }: TitleWithLabelEditProps) => {
  const [isUpdateLabelOpen, setIsUpdateLabelOpen] = useState(false);
  const [newLabel, setNewLabel] = useState(label);

  const update = () => {
    setIsUpdateLabelOpen(false);
    updateLabel(newLabel);
  };

  return (
    <h1 className="mb-8 flex flex-row items-center">
      <span>{title} </span>
      {isUpdateLabelOpen ? (
        <>
          <TextField
            size="small"
            variant="outlined"
            onChange={(event) => setNewLabel(event.target.value)}
            value={newLabel}
            sx={{ marginLeft: 2 }}
          />
          <ActionIconContainer onClick={update} className="ml-6">
            <CheckIcon style={{ marginBottom: 3 }} />
          </ActionIconContainer>
        </>
      ) : (
        <span className="ml-1.5">
          {newLabel}
          <CreateIcon onClick={() => setIsUpdateLabelOpen(true)} className="ml-5" />
        </span>
      )}
    </h1>
  );
};
