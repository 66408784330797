import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, IconButton, ListItem, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import moment from 'moment';
import styled from 'styled-components';
import { Timestamp } from 'firebase/firestore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';

import { StudyForSearch, StudyStatus } from '../../types/Study';
import { updateData } from '../../firebase/firestoreQueries';
import { studiesCollection, studiesForSearchCollection } from '../../firebase/firestoreCollections';
import { COLORS } from '../../style/colors';
import { StudyStatusColor } from './StudyStatusColor';
import { snackSuccess } from '../../util/snacks';
import { DuplicateStudyConfirmationModal } from './DuplicateStudyConfirmationModal';
import { clientRoutes } from '../../routing/clientRoutes';
import { initialContext, useNewStudy } from '../../contexts/NewStudyContext';
import { AssignModal } from './AssignModal';
import _ from 'lodash';
import { useAuth } from '../../auth/authContext';

const updateStudyStatus = async (uid: string, newStatus: StudyStatus, toastMessage: string) => {
  await updateData(studiesCollection, {
    uid,
    status: newStatus,
    statusUpdateDate: Timestamp.fromDate(new Date()),
  });
  await updateData(studiesForSearchCollection, {
    uid,
    status: newStatus,
    statusUpdateDate: Timestamp.fromDate(new Date()),
  });
  snackSuccess(toastMessage);
};

export const StudyListItem = ({
  study,
  onArchive,
  selectedItem,
  setSelectedItem,
  addDuplicatedStudy,
  isSectorManager,
}: {
  study: StudyForSearch;
  onArchive: (uid: string) => void;
  selectedItem: string | undefined;
  setSelectedItem: React.Dispatch<React.SetStateAction<string | undefined>>;
  addDuplicatedStudy: (study: StudyForSearch) => void;
  isSectorManager: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const me = useAuth();
  const { setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updatedStatus, setUpdatedStatus] = React.useState<StudyStatus | undefined>();
  const [updatedAssignedCompleteName, setUpdatedAssignedCompleteName] = React.useState<
    string | undefined | 'UNAFFECTED'
  >();
  const [assignModal, setAssignModal] = React.useState<StudyForSearch>();
  const [duplicationModalStudy, setDuplicationModalStudy] = React.useState<StudyForSearch>();

  const isAdmin = me?.isAdmin || me?.isManager;

  const open = Boolean(anchorEl);

  const archive = async () => {
    await updateData(studiesCollection, {
      uid: study.uid,
      isArchived: true,
    });
    await updateData(studiesForSearchCollection, {
      uid: study.uid,
      isArchived: true,
    });
    snackSuccess(t('studiesList.studyArchived'));
    handleClose();
    onArchive(study?.uid || '');
  };

  // @ts-ignore
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <div>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              setStudyContext(initialContext);
              navigate(clientRoutes.readStudy(study.uid));
            }}>
            {t('read')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setStudyContext(initialContext);
              navigate(clientRoutes.editSimulation(study.uid));
            }}>
            {t('edit')}
          </MenuItem>
          {isAdmin || isSectorManager ? (
            <MenuItem onClick={() => setAssignModal(study)}>{t('assign')}</MenuItem>
          ) : null}
          <MenuItem
            onClick={() => {
              setStudyContext(initialContext);
              setDuplicationModalStudy(study);
            }}>
            {t('duplicate')}
          </MenuItem>
          <MenuItem onClick={archive}>{t('common:archive')}</MenuItem>
          <NestedMenuItem label={t('status')} parentMenuOpen={open} defaultValue="">
            {Object.values(StudyStatus).map((st) => (
              <MenuItem
                value={st}
                key={st}
                onClick={async () => {
                  await updateStudyStatus(study.uid || '', st, t('studiesList.statusUpdated'));
                  setUpdatedStatus(st);
                  handleClose();
                }}>
                {study.status === st ? (
                  <span className="font-bold"> {t(st)}</span>
                ) : (
                  <span> {t(st)}</span>
                )}
              </MenuItem>
            ))}
          </NestedMenuItem>
        </Menu>
      </div>
      <List
        sx={{
          '&:hover': {
            backgroundColor: COLORS.greyPurple,
          },
          ...(selectedItem === study.uid
            ? { backgroundColor: COLORS.greyPurple }
            : { backgroundColor: 'transparent' }),
        }}
        dense>
        <ListItem
          onClick={() => setSelectedItem(study.uid)}
          secondaryAction={
            <IconButton onClick={(e) => handleClick(e)} edge="end" aria-label={t('more')}>
              <MoreHorizIcon />
            </IconButton>
          }>
          <ItemContent>
            <div className="flex flex-col items-start justify-around" style={{ flex: 3 }}>
              <h3 className="pb-0.5">{study.title}</h3>
              <span className="text-blue">
                {t('studiesList.createdBy')} {study.authorName.toUpperCase()}
              </span>
              {updatedAssignedCompleteName === 'UNAFFECTED' ? null : updatedAssignedCompleteName ? (
                <span>
                  {t('studiesList.assignedTo')} {updatedAssignedCompleteName}
                </span>
              ) : study.assignment ? (
                <span>
                  {t('studiesList.assignedTo')} {_.upperCase(study?.assignment.firstName)}{' '}
                  {_.upperCase(study?.assignment.lastName)}
                </span>
              ) : null}
              <span className="pt-0.5">
                <Icon>{'\ue91f'}</Icon>
                {study.clientFirstName} {study.clientLastName}
              </span>
            </div>

            {isWeb && (
              <div
                className="flex flex-col items-start  justify-around"
                style={{ color: COLORS.primaryColor, flex: 2 }}>
                <span className="text-xl">
                  <Icon>{'\ue920'}</Icon>
                  {study.isCustomPowerSelected
                    ? t('studiesList.personnalisee')
                    : t('studiesList.conseillee')}
                  <span className="font-bold">
                    {study.power || 'N/A'} {t('wattPeak')}
                  </span>
                </span>
              </div>
            )}

            <div className="flex flex-col items-end justify-around" style={{ flex: 2 }}>
              <StudyStatusColor status={updatedStatus || study.status} />
              <UpdateDate>
                {/*@ts-ignore*/}
                {moment((study.statusUpdateDate?._seconds || 0) * 1000).format('L')}
              </UpdateDate>
            </div>
          </ItemContent>
        </ListItem>
      </List>

      <Divider />

      <AssignModal
        study={assignModal}
        setStudy={setAssignModal}
        setUpdatedAssignedCompleteName={setUpdatedAssignedCompleteName}
      />

      <DuplicateStudyConfirmationModal
        study={duplicationModalStudy}
        setStudy={setDuplicationModalStudy}
        addDuplicatedStudy={addDuplicatedStudy}
      />
    </div>
  );
};

const ItemContent = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: space-evenly;
`;

const Icon = styled('span')`
  font-family: solaire, sans-serif;
  margin-right: 4px;
`;

const UpdateDate = styled('span')`
  color: ${COLORS.grey};
`;
