import React from 'react';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { LineChartProductionOverYears } from './LineChartProductionOverYears';
import { BarChartOverMonths } from './BarChartOverMonths';
import { useNewStudy } from '../contexts/NewStudyContext';

export const ResultsCharts = () => {
  const { t } = useTranslation();
  const { studyContext } = useNewStudy();

  const results = studyContext.isCustomPowerSelected
    ? studyContext.customPowerResults
    : studyContext.results;

  if (!results) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <GraphContainer>
          <div className="p-3">
            <h2 style={{ marginBottom: -20 }}>{t('results.charts.lineChartName')}</h2>
            <LineChartProductionOverYears
              cumulatedProductionOverYears={results.cumulatedProductionOverYears}
            />
            <div>
              <p>
                <Trans
                  i18nKey="results.charts.productionAnnee1"
                  values={{ production: Math.round(results.cumulatedProductionOverYears[0]) }}>
                  <b></b>
                </Trans>
              </p>
              <p>
                <Trans
                  i18nKey="results.charts.productionAnnee30"
                  values={{ production: Math.round(results.cumulatedProductionOverYears[29]) }}>
                  <b></b>
                </Trans>
              </p>
            </div>
          </div>
        </GraphContainer>
      </Grid>
      <Grid item xs={12} md={6} alignItems="stretch">
        <GraphContainer>
          <div className="p-3">
            <h2>{t('results.charts.stackedChartName')}</h2>
            <BarChartOverMonths results={results} />
          </div>
        </GraphContainer>
      </Grid>
    </Grid>
  );
};

const GraphContainer = styled('div')`
  background-color: white;
  border-radius: 10px;
  height: 100%;
`;
