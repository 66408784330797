import { UseQueryResult } from 'react-query';
import { CollectionReference, doc, writeBatch } from 'firebase/firestore';

import { snackError, snackSuccess } from '../../util/snacks';
import { allModels } from './components/ColoredSquare';
import { getModelPowerRangesValue } from './getModelPowerRangesValue';
import { firestore } from '../../firebase/firebase';
import { PowerRange } from '../../types/AdminSettings';

export const onSubmitValueForPowerRanges = async (
  data: any,
  powerRangesQuery: UseQueryResult<PowerRange[]>,
  collectionToSave: CollectionReference<any>,
  valueName: string
) => {
  if (Object.values(data).some((d: any) => !d && d !== 0)) {
    snackError('admin:missingEntry');
  } else {
    const dataToSave = Object.entries(data).map(([modelAndRangeUid, value]) => {
      for (const model of allModels) {
        if (modelAndRangeUid.includes(model)) {
          return getModelPowerRangesValue(
            model,
            powerRangesQuery,
            modelAndRangeUid,
            valueName,
            value
          );
        }
      }
    });

    const batch = writeBatch(firestore);

    dataToSave.forEach((item) => {
      if (!item?.powerRangeUid) {
        return;
      }
      const docRef = doc(collectionToSave, item.model + item.powerRangeUid);
      batch.set(docRef, item);
    });

    try {
      await batch.commit();
      snackSuccess('admin:changesSaved');
    } catch (error) {
      snackError();
    }
  }
};
