import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';

import {
  powerRangesCollection,
  powerRangesCollectionName,
  tvaCollection,
} from '../../firebase/firestoreCollections';
import { PowerRange } from '../../types/AdminSettings';
import { getAllData } from '../../firebase/firestoreQueries';
import { allModels } from './components/ColoredSquare';
import { ColoredSquareAndModel } from './components/ColoredSquareAndModel';
import { onSubmitValueForPowerRanges } from './onSubmitValueForPowerRanges';

type FormData<T extends number[]> = {
  tva: T;
};

export const TvaSettings = () => {
  const { t } = useTranslation('admin');

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData<number[]>>({});

  React.useEffect(() => {
    (async () => {
      const tva = await getAllData(tvaCollection);

      tva.forEach((t) => {
        // @ts-ignore
        setValue(t.model + t.powerRangeUid, t.tva);
      });
    })();
  }, []);

  const powerRangesQuery = useFirestoreQueryData<PowerRange>(
    [powerRangesCollectionName],
    powerRangesCollection
  );

  return (
    <div className="pl-5">
      <Grid container spacing={6}>
        {allModels.map((model) => (
          <Grid item xs={12} md={4} key={model}>
            <ColoredSquareAndModel
              model={model}
              errors={errors}
              control={control}
              inputLabel={t('common:tva')}
              inputAdornment="%"
            />
          </Grid>
        ))}
      </Grid>

      <div className="mb-12 mt-5 flex items-center justify-start">
        <SButton
          onClick={handleSubmit((data) =>
            onSubmitValueForPowerRanges(data, powerRangesQuery, tvaCollection, 'tva')
          )}
          variant="adminGreen">
          {t('saveChanges')}
        </SButton>
      </div>
    </div>
  );
};

const SButton = styled(Button)``;
