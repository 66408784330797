const editSimulation = '/editer-simulation/';
const synthesis = '/nouvelle-simulation/synthese';
const editSector = '/parametrages/editer-secteur/';
const editSalesArea = '/parametrages/editer-regions-commerciales/';
const editSolarSector = '/parametrages/editer-secteur-solaires/';

export const clientRoutes = {
  login: '/connexion',
  signIn: '/connexion',
  firstSamlConnectionSignUp: '/premiere-connexion-saml',
  signUp: '/inscription',
  forgotPassword: '/mot-de-passe-oublie',
  confirmPasswordReset: '/reinitialiser-mot-de-passe',
  policies: '/politique-de-confidentialite',
  home: '/',
  signInChoice: '/choix-connexion',
  newSimulation: '/nouvelle-simulation',
  editSimulationRouter: `${editSimulation}:uid`,
  editSimulation: (uid?: string) => `${editSimulation}${uid || ''}`,
  readStudyRouter: `${synthesis}/:uid`,
  readStudy: (uid?: string) => `${synthesis}/${uid || ''}`,
  results: '/nouvelle-simulation/choix-puissance',
  simulationProducts: '/nouvelle-simulation/produits',
  synthesis: synthesis,
  admin: '/admin',
  usersManagement: '/parametrages/utilisateurs',
  adminsManagement: '/parametrages/administrateurs',
  professionalManagement: '/parametrages/clients-pro',
  sectorsManagement: '/parametrages/secteur',
  editSectorRouter: `${editSector}:uid`,
  editSector: (uid?: string) => `${editSector}${uid || ''}`,
  salesAreasManagement: '/parametrages/regions-commerciales',
  editSalesAreaRouter: `${editSalesArea}:uid`,
  editSalesArea: (uid?: string) => `${editSalesArea}${uid || ''}`,
  solarSectorsManagement: '/parametrages/secteurs-solaires',
  managersManagement: '/parametrages/managers',
  editSolarSectorRouter: `${editSolarSector}:uid`,
  editSolarSector: (uid?: string) => `${editSolarSector}${uid || ''}`,
};
