import React from 'react';
import { DrawingManager, GoogleMap } from '@react-google-maps/api';
import styled from 'styled-components';
import { Box, Button, Modal, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { findClosestEdge } from '../../../googleMaps/findClosestEdge';
import { Step, StudyAddress } from '../NewSimulation';
import { computeOrientation, getAltitude } from './mapsComputations';
import UnsupportedOrientationAlert from './UnsupportedOrientationAlert';
import { AddressAutocomplete } from './AddressAutocomplete';
import { EdgeWarning } from './EdgeWarning';
import { initialDrawingOptions, polylineOptions } from './PolygonOptions';
import { AllOrientations, Orientation } from '../../../types/Orientations.enum';
import { ModalContent } from '../../../components/ModalContent';
import theme from '../../../style/mui-theme';
import { useGoogleMaps } from '../../../googleMaps/useGoogleMaps';

const franceCenter = { lat: 46.2276, lng: 2.2137 };

export const RoofSelectorModal = ({
  step,
  setStep,
  setRoofSurface,
  setOrientation,
  setAltitude,
  setDepartmentCode,
  setStudyAddress,
}: {
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  setRoofSurface: (roofSurface: number) => void;
  setOrientation: (orientation: Orientation) => void;
  setAltitude: (altitude: string) => void;
  setDepartmentCode: (code: string) => void;
  setStudyAddress: (studyAddress: StudyAddress) => void;
}) => {
  const { t } = useTranslation();
  const mapRef = React.useRef<google.maps.Map | null>(null);
  const polygonRef = React.useRef<google.maps.Polygon | null>(null);
  const selectedEdgeRef = React.useRef<google.maps.Polyline | null>(null);
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));

  const [drawingManagerOptions, setDrawingManagerOptions] =
    React.useState<google.maps.drawing.DrawingManagerOptions>(initialDrawingOptions);
  const [drawingMode, setDrawingMode] = React.useState<any>();
  const [isSelectionValid, setIsSelectionValid] = React.useState(false);
  const [isHighestEdgeSelected, setIsHighestEdgeSelected] = React.useState(false);
  const [zoom, setZoom] = React.useState(5);
  const [openUnsupportedAlert, setOpenUnsupportedAlert] = React.useState(false);
  const [center, setCenter] = React.useState(franceCenter);
  const [reload, setReload] = React.useState(false);

  const GMapsApiStatus: 'ready' | 'notReady' = useGoogleMaps(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,drawing&callback=Function.prototype&loading=async`,
    reload
  );

  const initialize = () => {
    polygonRef.current = null;
    setIsSelectionValid(false);
    setIsHighestEdgeSelected(false);
    setDrawingMode(google.maps.drawing?.OverlayType.POLYGON);
    setDrawingManagerOptions({
      ...initialDrawingOptions,
      drawingControlOptions: {
        position: google.maps.ControlPosition?.TOP_CENTER,
        drawingModes: [google.maps.drawing?.OverlayType.POLYGON],
      },
    });
    if (google.maps.Polyline) {
      selectedEdgeRef.current = new google.maps.Polyline({
        map: mapRef.current,
        ...polylineOptions,
      });
    }
  };

  React.useEffect(() => {
    setReload(false);
    if (GMapsApiStatus === 'ready' && google.maps.drawing) {
      initialize();
    } else {
      setTimeout(() => {
        setReload(true);
      }, 2000);
    }
  }, [GMapsApiStatus, reload]);

  const onCancel = () => {
    initialize();
    setStep(Step.form);
  };

  const returnToPolygonSelection = () => {
    polygonRef.current?.setMap(null);
    selectedEdgeRef.current?.setMap(null);
    initialize();
    setStep(Step.roofSelector);
  };

  const onNextClick = () => {
    if (step === Step.edgeSelection) {
      initialize();
      setStep(Step.form);
    } else {
      if (!polygonRef.current) {
        return;
      }
      google.maps.event.addListener(polygonRef.current, 'click', async (event: any) => {
        if (!polygonRef.current) {
          return;
        }
        const closestEdge: google.maps.LatLng[] = findClosestEdge(polygonRef.current, event.latLng);
        const altitude = await getAltitude(polygonRef.current);
        if (altitude) {
          setAltitude(altitude);
        }
        const orientation = computeOrientation(polygonRef.current, closestEdge);
        setOrientation(orientation);

        if (orientation === AllOrientations.NC) {
          setOpenUnsupportedAlert(true);
        } else {
          setIsSelectionValid(true);
          setIsHighestEdgeSelected(true);
        }

        selectedEdgeRef.current?.setOptions({
          map: mapRef.current,
          path: closestEdge,
          ...polylineOptions,
        });
      });
      setDrawingManagerOptions({
        drawingControl: false,
        drawingControlOptions: null,
        polygonOptions: {
          editable: true,
        },
      });
      polygonRef.current?.setEditable(false);
      setDrawingMode(null);
      setStep(Step.edgeSelection);
      setIsSelectionValid(false);
    }
  };

  const setArea = (polygon: google.maps.Polygon) => {
    const area = google.maps.geometry.spherical.computeArea(polygon.getPath());
    setRoofSurface(area);
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon;
    setDrawingManagerOptions({
      drawingControl: false,
      drawingControlOptions: null,
    });
    setDrawingMode(null);
    setArea(polygon);
    setIsSelectionValid(true);

    const path = polygon.getPath();
    google.maps.event.addListener(path, 'set_at', () => {
      setArea(polygon);
    });
    google.maps.event.addListener(path, 'insert_at', () => {
      setArea(polygon);
    });
  };

  const onAddressChange = (place: any) => {
    setCenter(place);
    setZoom(19);
  };

  if (GMapsApiStatus !== 'ready') {
    return null;
  }

  return (
    <Modal open={step === Step.roofSelector || step === Step.edgeSelection}>
      <SModalContent style={isWeb ? {} : { height: '100%' }}>
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: '100%',
            padding: isWeb ? 0 : 1,
          }}>
          <h3 className="my-5 text-center">{t('RoofExplanationsModal.title')}</h3>
          {step === Step.edgeSelection && !isHighestEdgeSelected && <EdgeWarning />}
          {step === Step.roofSelector && (
            <>
              <p className="py-4">
                <Trans i18nKey="RoofSelector.explanations" />
              </p>
              <h6>{t('RoofSelector.address')}</h6>
              <AddressAutocomplete
                gMapsApiStatus={GMapsApiStatus === 'ready'}
                setAddress={onAddressChange}
                setDepartmentCode={setDepartmentCode}
                setStudyAddress={setStudyAddress}
              />
            </>
          )}

          <Compass src="/images/compass.svg" alt={t('compass')} />
          <GoogleMap
            onLoad={(map) => {
              mapRef.current = map;
            }}
            mapContainerStyle={{
              height: '450px',
              maxWidth: '800px',
              width: '100%',
            }}
            zoom={zoom}
            center={center}
            tilt={0}
            options={{
              streetViewControl: false,
              mapTypeId: 'satellite',
              rotateControl: false,
              disableDefaultUI: !isWeb,
              mapTypeControl: false,
            }}>
            <DrawingManager
              onPolygonComplete={onPolygonComplete}
              options={drawingManagerOptions}
              drawingMode={drawingMode}
            />
          </GoogleMap>
          <div className="mb-5 mt-3 flex justify-end">
            <div className="mr-5">
              {step === Step.roofSelector && (
                <Button onClick={onCancel} variant="outlined" style={isWeb ? { width: 220 } : {}}>
                  {t('cancel')}
                </Button>
              )}
              {step === Step.edgeSelection && (
                <Button
                  onClick={returnToPolygonSelection}
                  variant="outlined"
                  style={isWeb ? { width: 220 } : {}}>
                  {t('common:previous')}
                </Button>
              )}
            </div>
            {step === Step.roofSelector && (
              <Button
                onClick={onNextClick}
                variant="contained"
                disabled={!isSelectionValid}
                style={isWeb ? { width: 220 } : {}}>
                {t('next')}
              </Button>
            )}
            {step === Step.edgeSelection && (
              <Button
                onClick={onNextClick}
                variant="contained"
                disabled={!isHighestEdgeSelected}
                style={isWeb ? { width: 220 } : {}}>
                {t('ok')}
              </Button>
            )}
          </div>
          <UnsupportedOrientationAlert
            open={openUnsupportedAlert}
            setOpen={setOpenUnsupportedAlert}
          />
        </Box>
      </SModalContent>
    </Modal>
  );
};

const SModalContent = styled(ModalContent)`
  width: 100%;
  max-width: 650px;
`;

const Compass = styled('img')`
  max-width: 150px;
  min-height: 100px;
  position: absolute;
  top: 350px;
  left: 10px;
  z-index: 2;
`;
