import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@mui/material';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ModalContent } from '../../components/ModalContent';
import { SInput } from '../../auth/authStyle';
import { useForm } from 'react-hook-form';
import { getData, setData } from '../../firebase/firestoreQueries';
import {
  studiesCollection,
  studiesForSearchCollection,
  usersCollection,
} from '../../firebase/firestoreCollections';
import { CompleteStudy, StudyForSearch } from '../../types/Study';
import { v4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { useAuth } from '../../auth/authContext';
import { User } from '../../types/User';
import { snackError, snackSuccess } from '../../util/snacks';
import { createExternalId } from '../newStudy/SubmitStudyButton';

const schema = yup
  .object()
  .shape({
    newName: yup.string().required('requiredField'),
  })
  .required();

type FormValues = yup.InferType<typeof schema>;

export const DuplicateStudyConfirmationModal = ({
  addDuplicatedStudy,
  study,
  setStudy,
}: {
  addDuplicatedStudy: (study: StudyForSearch) => void;
  study?: StudyForSearch;
  setStudy: React.Dispatch<React.SetStateAction<StudyForSearch | undefined>>;
}) => {
  const { t } = useTranslation();
  const user = useAuth();

  const [author, setAuthor] = React.useState<User>();

  React.useEffect(() => {
    (async () => {
      if (user) {
        const userData = await getData(usersCollection, user.uid);
        setAuthor(userData);
      }
    })();
  }, [user]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const close = () => setStudy(undefined);

  const onSubmit = async (data: FormValues) => {
    if (study) {
      try {
        const uid = v4();
        const authorName = `${author?.firstName || ''} ${author?.lastName || ''}`;
        const authorUid = author?.uid || '';
        const externalId = createExternalId(author, uid);
        const title = data.newName;

        const newData = {
          uid,
          authorName,
          authorUid,
          externalId,
          title,
        };

        const completeStudy = await getData(studiesCollection, study.uid || '');

        if (!completeStudy) {
          return snackError();
        }

        const duplicatedStudy: CompleteStudy = {
          ...completeStudy,
          ...newData,
          creationDate: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
        };
        await setData(studiesCollection, duplicatedStudy);

        const forSearchDuplicatedStudy = {
          ...study,
          ...newData,
          statusUpdateDate: Timestamp.fromDate(new Date()),
        };

        await setData(studiesForSearchCollection, forSearchDuplicatedStudy);
        addDuplicatedStudy(forSearchDuplicatedStudy);
        snackSuccess('studiesList.duplicated');
      } catch (e) {
        return snackError();
      }
    }
    close();
  };

  return (
    <Modal open={!!study} onClose={close}>
      <SModalContent>
        <div>
          <div className="font-bold">{t('studiesList.duplicateTitle')}</div>

          <div className="mb-5 mt-3 flex justify-center">
            <SInput name="newName" placeholder="newName" errors={errors} control={control} />
          </div>
        </div>

        <div className="flex">
          <div className="m-2 w-full">
            <SButton variant="outlined" onClick={close}>
              {t('cancel')}
            </SButton>
          </div>
          <div className="m-2 w-full">
            <SButton variant="contained" disabled={!isValid} onClick={handleSubmit(onSubmit)}>
              {t('validate')}
            </SButton>
          </div>
        </div>
      </SModalContent>
    </Modal>
  );
};

const SButton = styled(Button)`
  width: 100%;
`;

const SModalContent = styled(ModalContent)`
  height: 200px;
  min-height: 200px;
`;
