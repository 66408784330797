export const COLORS = {
  primary: '#27236d',
  primaryLight: '#15779a',
  primaryDark: '#2d2d83',
  secondary: '#0c5b96',
  tertiary: 'rgb(39, 35, 109)',
  error: '#af1e23',
  white: '#fff',
  black: '#000000',

  textPrimary: '#333333',
  disabledContainedButton: '#9290b5',

  // official colors
  blue: '#0b5c94',
  grey: '#939598',
  red: '#af1e23',
  purple: '#27236d',
  primaryColor: '#27236d',
  lightPurple: '#1f3167',
  brown: '#8b5351',
  darkerBlue: '#005baa',
  greyBlue: '#395a85',
  lightGreyBlue: '#4e6ba0',
  cyanBlue: '#1a5b91',

  // other colors
  green: '#5d906e',
  darkGreen: '#49825c',
  greyPurple: '#e8e8f1',
  lighterGrey: '#d4d4e5',
  darkerGrey: '#333333',
  lighterGreyPurple: '#f3f3f8',
  flashyRed: '#e02e35',
  lightGreen: '#a8ce60',
  orange: '#ff9d00',
  borderGrey: '#ccc',
  boxShadow: '0px 0px 5px #d4d4e5',
  outlinedButtonHover: '#D0D0D8',

  // Admin colors
  adminBluePrimary: '#255AA4',
  adminDarkGrey: '#333333',
  adminLightGrey: '#D4D4E3',
  adminBlue4: '#2D2E83',
  adminBlue3: '#1A418B',
  adminBlue2: '#3879A1',
  adminBlue1: '#40ACB6',
  adminBlue0: '#8CCDD3',
  adminPurple1: '#582D83',
  adminPurple0: '#821A8B',
  adminGreen2: '#38A16F',
  adminGreen1: '#40B677',
  adminGreen0: '#8CD3AD',
  adminRed1: '#B64040',
  adminRed0: '#DE7A6A',
  adminYellow1: '#CC9936',
  adminYellow0: '#DECB6A',
  adminBackgroundBlue: '#ECF7F8',
  adminBackgroundPurple: '#F2E8F3',
  adminBackgroundGreen: '#ECF8F1',
};
