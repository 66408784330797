import React from 'react';
import { Autocomplete, Button, Divider, Grid, Modal, styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';

import { AppHeader } from '../../components/AppHeader';
import { usersCollection, usersCollectionName } from '../../firebase/firestoreCollections';
import { User } from '../../types/User';
import {
  Background,
  BigPlusButton,
  Container,
  GoBackButton,
  GridElement,
  GridText,
} from './components/AdminComponents';
import { ModalContent } from '../../components/ModalContent';
import { snackError, snackSuccess } from '../../util/snacks';
import { serverRoutes } from '../../routing/serverRoutes';
import { useAuth } from '../../auth/authContext';

const schema = yup
  .object()
  .shape({
    manager: yup
      .object()
      .shape({ email: yup.string().required(), lastName: yup.string(), firstName: yup.string() })
      .required('requiredField'),
  })
  .required();

type FormValues = yup.InferType<typeof schema>;

export const ManagersManagement = () => {
  const { t } = useTranslation('admin');
  const me = useAuth();

  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<string>();
  const [displayTrash, setDisplayTrash] = React.useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection, {
    subscribe: true,
  });

  const managers = (usersQuery.data || []).filter((user) => user.isManager);
  const nonManagersUsers = (usersQuery.data || []).filter(
    (user) => !user.isManager && user.isInternal
  );

  const onSubmit = async (data: FormValues) => {
    const idToken = me?.idToken;
    if (idToken) {
      fetch(serverRoutes.createManager(data.manager.email || '', idToken), {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          snackSuccess();
          setIsAddModalOpen(false);
        })
        .catch(() => {
          return snackError();
        });
    }
  };

  const deleteManager = async (userUid?: string) => {
    if (!userUid) {
      return;
    }

    if (me?.idToken) {
      fetch(serverRoutes.removeManager(userUid, me.idToken), {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          snackSuccess();
          setIsDeleteModalOpen('');
        })
        .catch(() => {
          return snackError();
        });
    }
  };

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <h1 className="mb-8">{t('UsersManagement.managers')}</h1>

          <Divider />

          <GoBackButton />

          <Grid container spacing={2} className="mb-10">
            {managers.map((user) => (
              <Grid item xs={6} lg={4} key={user.uid}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setDisplayTrash(user.uid)}
                  onMouseLeave={() => setDisplayTrash(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>{_.upperFirst(user.firstName)}</GridText>
                    {displayTrash === user.uid && (
                      <DeleteIconContainer
                        onClick={() => setIsDeleteModalOpen(user.uid)}
                        className="cursor-pointer">
                        <DeleteIcon />
                      </DeleteIconContainer>
                    )}
                  </div>
                  <GridText>{_.upperFirst(user.lastName)}</GridText>
                  <GridText>{user.email}</GridText>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>
        </Container>
      </Background>

      <Modal open={isAddModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.addManager')}</Title>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-20 mt-10 flex justify-center ">
              <Controller
                name="manager"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={nonManagersUsers}
                    getOptionLabel={(option) =>
                      _.upperFirst(option.firstName) +
                      ' ' +
                      _.upperFirst(option.lastName) +
                      ' ' +
                      option.email
                    }
                    noOptionsText={t('all:studiesList.aucunUtilisateurTrouve')}
                    sx={{ width: 420 }}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField {...params} label={t('UsersManagement.searchUser')} />
                    )}
                  />
                )}
              />
            </div>

            <div className="mt-8 flex justify-end">
              <div className="mr-1">
                <SButton variant="adminGrey" onClick={() => setIsAddModalOpen(false)}>
                  {t('common:cancel')}
                </SButton>
              </div>
              <div className="ml-1">
                <SButton variant="adminGreen" disabled={!isValid} type="submit">
                  {t('common:validate')}
                </SButton>
              </div>
            </div>
          </form>
        </SModalContent>
      </Modal>

      <Modal open={!!isDeleteModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.confirmDeleteManager')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">
            {t('UsersManagement.confirmDeleteManagerDesc')}
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsDeleteModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" onClick={() => deleteManager(isDeleteModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 15px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const DeleteIconContainer = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  background-color: var(--background-secondary-color);
  border-radius: 5px;
`;
