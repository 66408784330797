import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Equipment } from './components/Equipment';
import { FormGridLayout } from './components/FormGridLayout';
import { handleMultipleSelect } from '../../util/formMultipleSelect';
import { Error } from '../../components/MySelect';

export const NewSimulationDomesticHotWater = ({
  control,
  getValues,
}: {
  control: any;
  getValues: any;
}) => {
  const { t } = useTranslation();

  const isOnError = getValues('domesticHotWater')?.length > 1;

  return (
    <>
      <Controller
        name="domesticHotWater"
        render={({ field }) => (
          <FormGridLayout
            withMobileLabel
            labelTranslationKey="domesticHotWater"
            items={[
              <Equipment
                equipmentName="electric"
                icon="\ue905"
                isSelectedAtStart={getValues('domesticHotWater')?.includes('electricWaterHeater')}
                onClick={() => handleMultipleSelect(field, 'electricWaterHeater', getValues)}
              />,
              <Equipment
                equipmentName="thermodynamicBalloon"
                icon="\ue906"
                isSelectedAtStart={getValues('domesticHotWater')?.includes('thermodynamicBalloon')}
                onClick={() => handleMultipleSelect(field, 'thermodynamicBalloon', getValues)}
              />,
              <Equipment
                equipmentName="boiler"
                icon="\ue907"
                isSelectedAtStart={getValues('domesticHotWater')?.includes('boiler')}
                onClick={() => handleMultipleSelect(field, 'boiler', getValues)}
              />,
              <Equipment
                equipmentName="heatPumpDoubleService"
                icon="\ue903"
                isSelectedAtStart={getValues('domesticHotWater')?.includes('heatPumpDoubleService')}
                onClick={() => handleMultipleSelect(field, 'heatPumpDoubleService', getValues)}
              />,
            ]}
          />
        )}
        control={control}
      />
      <FormGridLayout
        items={[isOnError ? <SError>{t('newStudy.maxOneWaterHeating')}</SError> : <SError />]}
      />
    </>
  );
};

const SError = styled(Error)`
  margin-top: -8px;
`;
