export enum TileType {
  TerreCuiteSolaire = 'PHOTC',
  SolaireMax = 'PHOMAX',
  EasyroofAccess = 'EREVOL',
  EasyroofIntegration = 'ERINTE',
  EasyroofTop = 'ERTOP',
  EasyroofFlat = 'ERFLAT',
}

export type Product = {
  color: string;
  tileType: TileType;
  catalogueReference: string;
  listPrice: number;
  power: number;
  productQuantity: number;
  surface: number;
  minPriceRatio: number;
  maxPriceRatio: number;
  minPrice: number;
  maxPrice: number;
  name: string;
  extensionPrice: number; //OPT_RAL (prix option rallonge)
  consuelPrice: number; //OPT_SBE (16)
  totalPower: number; // PUISSANCE_R_TOTALE
  extension50mPrice: number; //TARIF_OPT_RACCORD_50M (PrixOptionDistanceRaccordement)
  erpPrice: number; //TARIF_OPT_ERP (PrixEtablissementRecevantPublic)
};

export enum ProductType {
  TuilesSolaires = 'TuilesSolaires',
  PanneauxSolaires = 'PanneauxSolaires',
}

export enum ProductColor {
  Noir = 'NOIR',
  Rouge = 'ROUGE',
}

export type ChosenProduct = Product & {
  sellPrice?: number;
  connectionPrice?: number;
};
