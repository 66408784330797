import { styled } from '@mui/material/styles';

import { MyInput } from '../../../components/MyInput';

export const SInput = styled(MyInput)`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 400px;
  }
`;

export const InputContainer = styled('div')`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 450px;
  }
`;

export const SelectAndIcon = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
`;
