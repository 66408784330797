import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useNewStudy } from '../contexts/NewStudyContext';
import { WhiteContainer } from './components/WhiteContainer';
import { KitsBox } from '../components/KitsBox';
import { useMediaQuery } from '@mui/material';

export const SelectedProducts = () => {
  const { t } = useTranslation();
  const { studyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  return (
    <WhiteContainer>
      <h2 className="mb-5">{t('product:kits.title')}</h2>
      <div className={isWeb ? 'flex' : 'flex flex-wrap items-center justify-center'}>
        {studyContext.chosenProducts.length ? (
          <KitsBox withDelete></KitsBox>
        ) : (
          <AddKitBox>{t('product:kits.add')}</AddKitBox>
        )}
      </div>
    </WhiteContainer>
  );
};

const KitBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 260px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 20px;
  padding: 18px;
`;

const AddKitBox = styled(KitBox)`
  border: 2px dashed #000000;
  height: 250px;
`;
