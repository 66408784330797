import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, IconButton, InputAdornment, Modal } from '@mui/material';
import styled from 'styled-components';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { ModalContent } from '../../components/ModalContent';
import { deleteData, setData } from '../../firebase/firestoreQueries';
import {
  powerRangesCollection,
  powerRangesCollectionName,
  subsidiesCollection,
  subsidiesCollectionName,
} from '../../firebase/firestoreCollections';
import { snackError, snackSuccess } from '../../util/snacks';
import { MySelect } from '../../components/MySelect';
import { Subsidy } from '../../types/Subsidy';
import { PowerRange } from '../../types/AdminSettings';
import { allModels } from './components/ColoredSquare';
import { Label } from './components/Label';
import { MyInput } from '../../components/MyInput';

const schema = yup
  .object()
  .shape({
    economicModel: yup.string().oneOf(allModels).required('requiredField'),
    powerRange: yup.string().required('requiredField'),
    year1: yup.number(),
    year2: yup.number(),
    year3: yup.number(),
    year4: yup.number(),
    year5: yup.number(),
    subsidyInEuroPerKwc: yup.number().required('requiredField'),
  })
  .required();

export type SubsidyFormValues = yup.InferType<typeof schema>;

export const Subsidies = () => {
  const { t } = useTranslation('admin');

  const [modalSubsidyUid, setModalSubsidyUid] = React.useState<string>('');

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<SubsidyFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const subsidiesQuery = useFirestoreQueryData<Subsidy>(
    [subsidiesCollectionName],
    subsidiesCollection,
    { subscribe: true }
  );

  const powerRangesQuery = useFirestoreQueryData<PowerRange>(
    [powerRangesCollectionName],
    powerRangesCollection
  );

  const formattedPowerRanges = (powerRangesQuery.data || [])
    .sort((pr0, pr1) => pr0.minPower - pr1.minPower)
    .map((pr) => ({
      label: pr.minPower + ' à ' + pr.maxPower + ' kWc',
      value: pr.uid,
      powerRangeMin: pr.minPower,
      powerRangeMax: pr.maxPower,
    }));

  const computePercentage = (index: number, updated: Subsidy, totalSubsidies: number) => {
    const existingSubsidy = (subsidiesQuery.data || []).find(
      (s) =>
        s.economicModel === updated.economicModel &&
        s.powerRangeUid === updated.powerRangeUid &&
        s.year === index
    )?.subsidyInEuroPerKwc;

    return existingSubsidy ? (existingSubsidy / totalSubsidies) * 100 : undefined;
  };

  React.useEffect(() => {
    if (modalSubsidyUid !== '1') {
      const updated = (subsidiesQuery.data || []).find((s) => s.uid === modalSubsidyUid);

      if (updated) {
        const totalSubsidies = (subsidiesQuery.data || [])
          .filter(
            (s) =>
              s.economicModel === updated.economicModel && s.powerRangeUid === updated.powerRangeUid
          )
          .reduce((acc, s) => acc + s.subsidyInEuroPerKwc, 0);

        reset({
          economicModel: updated.economicModel,
          subsidyInEuroPerKwc: Math.round(totalSubsidies),
          powerRange: formattedPowerRanges.find((pr) => pr.value === updated.powerRangeUid)?.value,
          year1: computePercentage(1, updated, totalSubsidies),
          year2: computePercentage(2, updated, totalSubsidies),
          year3: computePercentage(3, updated, totalSubsidies),
          year4: computePercentage(4, updated, totalSubsidies),
          year5: computePercentage(5, updated, totalSubsidies),
        });
      }
    } else if (modalSubsidyUid === '1' || modalSubsidyUid === '') {
      reset({
        year1: undefined,
        year2: undefined,
        year3: undefined,
        year4: undefined,
        year5: undefined,
        subsidyInEuroPerKwc: undefined,
        powerRange: undefined,
        economicModel: undefined,
      });
    }
  }, [modalSubsidyUid]);

  const onSubmit = async (data: SubsidyFormValues) => {
    const selectedPowerRange = formattedPowerRanges.find((pr) => pr.value === data.powerRange);
    if (!selectedPowerRange) {
      return;
    }

    if (
      (data.year1 || 0) +
        (data.year2 || 0) +
        (data.year3 || 0) +
        (data.year4 || 0) +
        (data.year5 || 0) !==
      100
    ) {
      snackError('admin:Subsidies.sumNot100');
      return;
    }

    const definedYears = [
      { percentage: data.year1 || 0, y: 1 },
      { percentage: data.year2 || 0, y: 2 },
      { percentage: data.year3 || 0, y: 3 },
      { percentage: data.year4 || 0, y: 4 },
      { percentage: data.year5 || 0, y: 5 },
    ];
    const subsidies: Subsidy[] = definedYears.map((y) => ({
      uid:
        data.economicModel +
        '-' +
        selectedPowerRange?.powerRangeMin +
        '-' +
        selectedPowerRange?.powerRangeMax +
        '-' +
        y.y,
      economicModel: data.economicModel,
      powerRangeUid: selectedPowerRange.value,
      powerRangeMin: selectedPowerRange.powerRangeMin,
      powerRangeMax: selectedPowerRange.powerRangeMax,
      year: y.y,
      subsidyInEuroPerKwc: data.subsidyInEuroPerKwc * ((y.percentage || 0) / 100),
    }));
    try {
      await Promise.all(subsidies.map((s) => setData(subsidiesCollection, s)));
      setModalSubsidyUid('');
      snackSuccess();
    } catch (e) {
      snackError();
    }
  };

  const groupedByModel = _.groupBy(subsidiesQuery.data || [], 'economicModel');
  const groupedByModelAndPower = _.mapValues(groupedByModel, (group) =>
    _.groupBy(group, 'powerRangeUid')
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}> {t('economicModel')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="right">
                {t('powerRange')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="right">
                {t('distribution')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="right">
                {t('subsidy')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="right">
                {t('actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(groupedByModelAndPower).map((row) =>
              Object.values(row).map((row2) => (
                <TableRow
                  key={row2[0].uid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {t('all:' + row2[0].economicModel)}
                  </TableCell>
                  <TableCell align="right">
                    {row2[0].powerRangeMin}-{row2[0].powerRangeMax}
                  </TableCell>
                  <TableCell align="right">
                    {_.truncate(
                      row2
                        .filter((r) => r.subsidyInEuroPerKwc)
                        .map(
                          (r) =>
                            Math.round(
                              (r.subsidyInEuroPerKwc /
                                row2.reduce((acc, r) => acc + r.subsidyInEuroPerKwc, 0)) *
                                100
                            ) +
                            '% A' +
                            r.year.toString()
                        )
                        .join(', '),
                      {
                        length: 26,
                        omission: '...',
                      }
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row2.reduce((acc, r) => acc + r.subsidyInEuroPerKwc, 0)} €/kWc
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => row2.map((r) => deleteData(subsidiesCollection, r.uid))}
                      edge="end"
                      aria-label={t('common:delete')}>
                      <DeleteIcon sx={{ color: 'black' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => setModalSubsidyUid(row2[0].uid)}
                      edge="end"
                      aria-label={t('common:edit')}>
                      <EditIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="mt-5">
        <SButton type="submit" variant="adminBlue" onClick={() => setModalSubsidyUid('1')}>
          {t('addASubsidy')}
        </SButton>
      </div>

      <Modal open={!!modalSubsidyUid} onClose={() => setModalSubsidyUid('')}>
        <SModalContent>
          <h2 className="text-center">{t('Subsidies.add')}</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5 flex flex-col">
              <div className="flex items-center">
                <SLabel>{t('economicModel')}</SLabel>
                <div className="ml-3 flex flex-1">
                  <MySelect
                    name="economicModel"
                    withoutLabel
                    errors={errors}
                    control={control}
                    defaultValue=""
                    items={[
                      { value: 'selfConsumption', label: 'selfConsumption' },
                      { value: 'consumptionAndResale', label: 'consumptionAndResale' },
                      { value: 'fullResale', label: 'fullResale' },
                    ]}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <SLabel>{t('powerRange')}</SLabel>
                <div className="ml-3 flex flex-1">
                  <MySelect
                    name="powerRange"
                    label="admin:Subsidies.powerRange"
                    errors={errors}
                    control={control}
                    defaultValue=""
                    items={formattedPowerRanges}
                    required
                    withoutLabel
                  />
                </div>
              </div>
              <div className="flex items-center">
                <SLabel>{t('subsidy')}</SLabel>
                <div className="ml-3 flex flex-1">
                  <SInput
                    name="subsidyInEuroPerKwc"
                    placeholder={t('amount')}
                    errors={errors}
                    control={control}
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€/kWc</InputAdornment>,
                    }}
                    withoutLabel
                    fullWidth
                  />
                </div>
              </div>
              {[1, 2, 3, 4, 5].map((year) => (
                <div className="flex items-center">
                  <SLabel className="ml-3">
                    {'\u2022'} {t('year')} {year}
                  </SLabel>
                  <div className="flex flex-1">
                    <MySelect
                      name={'year' + year}
                      label="common:year"
                      errors={errors}
                      control={control}
                      defaultValue=""
                      items={Array.from({ length: 21 }, (_, i) => i * 5).map((i) => ({
                        value: i.toString(),
                        label: i.toString() + '%',
                      }))}
                      withoutLabel
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-8 flex justify-end">
              <div className="mr-1">
                <SButton variant="adminGrey" onClick={() => setModalSubsidyUid('')}>
                  {t('common:cancel')}
                </SButton>
              </div>
              <div className="ml-1">
                <SButton variant="adminGreen" disabled={!isValid} type="submit">
                  {modalSubsidyUid === '1' ? t('common:add') : t('common:modify')}
                </SButton>
              </div>
            </div>
          </form>
        </SModalContent>
      </Modal>
    </>
  );
};

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 70px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const SLabel = styled(Label)`
  flex: 1;
`;

const SInput = styled(MyInput)`
  flex: 1;
`;
