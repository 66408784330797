import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from '../../../components/Icon';

export const Equipment = ({
  equipmentName,
  initialCount,
  icon,
  onClick,
  onIncrease,
  onDecrease,
  isSelectedAtStart,
}: {
  equipmentName: string;
  initialCount?: number;
  icon: string;
  onClick?: () => void;
  onIncrease?: () => void;
  onDecrease?: () => void;
  isSelectedAtStart?: boolean;
}) => {
  const { t } = useTranslation();
  const [count, setCount] = React.useState(0);
  const [isSelected, setIsSelected] = React.useState(!!initialCount);

  React.useEffect(() => {
    setCount(initialCount || 0);
    if (isSelectedAtStart || (initialCount && initialCount > 0)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [initialCount, isSelectedAtStart]);

  const toggleSelected = () => {
    onClick && onClick();
    if (count > 0) {
      setCount(0);
      for (let i = 0; i < count; i++) {
        onDecrease && onDecrease();
      }
    } else {
      setCount(1);
      onIncrease && onIncrease();
    }
    setIsSelected(!isSelected);
  };

  const onClickMinus = () => {
    const newCount = count - 1;
    setCount(newCount);
    if (newCount > 0) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
    onDecrease && onDecrease();
  };

  const onClickPlus = () => {
    const newCount = count + 1;
    setCount(newCount);
    if (newCount > 0) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
    onIncrease && onIncrease();
  };

  return (
    <Container className={'option ' + (isSelected ? 'selected' : '')} onClick={toggleSelected}>
      <Icon icon={icon} style={{ height: 40 }} />
      <EquipmentName>{t(equipmentName)}</EquipmentName>
      <div className="compteur">
        {initialCount || initialCount === 0 ? (
          <>
            <button
              type="button"
              className="minus-plus-btn"
              disabled={count === 0}
              onClick={(e) => {
                e.stopPropagation();
                onClickMinus();
              }}>
              -
            </button>
            <p>{count}</p>
            <button
              type="button"
              className="minus-plus-btn"
              disabled={count >= 5}
              onClick={(e) => {
                e.stopPropagation();
                onClickPlus();
              }}>
              +
            </button>
          </>
        ) : null}
      </div>
    </Container>
  );
};

const Container = styled('div')`
  height: 150px;
  width: 140px;
  margin: 5px;
`;

const EquipmentName = styled('p')`
  font-size: 14px;
  display: flex;
  flex: 1;
  align-items: center;
`;
