import React from 'react';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const FormGridLayout = ({
  items,
  labelTranslationKey,
  tooltip,
  withMobileLabel,
}: {
  items: React.ReactElement[];
  labelTranslationKey?: string;
  tooltip?: string;
  withMobileLabel?: boolean;
}) => {
  const { t } = useTranslation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  return (
    <Grid container sx={{ marginTop: 2 }}>
      {isWeb ? (
        <Grid item xs={2} md={2} sx={{ alignSelf: 'center' }}>
          <LabelAndTooltip>
            <Label>{labelTranslationKey && t(labelTranslationKey).toUpperCase()}</Label>
            {tooltip && (
              <Tooltip title={t(tooltip)}>
                <InfoIcon />
              </Tooltip>
            )}
          </LabelAndTooltip>
        </Grid>
      ) : (
        withMobileLabel && (
          <Grid item xs={12} sx={{ alignSelf: 'center' }}>
            <div>
              <span className="mr-4 font-bold">
                {labelTranslationKey && t(labelTranslationKey)}
              </span>
              {tooltip && (
                <Tooltip title={t(tooltip)}>
                  <InfoIcon />
                </Tooltip>
              )}
            </div>
          </Grid>
        )
      )}
      <Grid item xs={12} lg={8}>
        <Items>{items.map((item, index) => React.cloneElement(item, { key: index }))}</Items>
      </Grid>
    </Grid>
  );
};

const Items = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const LabelAndTooltip = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Label = styled('div')`
  text-align: end;
  padding-right: 10px;
`;
