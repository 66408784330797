import PaymentsIcon from '@mui/icons-material/Payments';
import { TitleWithIcon } from './components/TitleWithIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';

import { modelsWithoutSelfConsumption } from './components/ColoredSquare';
import { ColoredSquareAndModel } from './components/ColoredSquareAndModel';
import { onSubmitValueForPowerRanges } from './onSubmitValueForPowerRanges';
import {
  powerRangesCollection,
  powerRangesCollectionName,
  sellPriceByKwhInEuroCollection,
} from '../../firebase/firestoreCollections';
import { getAllData } from '../../firebase/firestoreQueries';
import { PowerRange } from '../../types/AdminSettings';
import { PaddingBox } from './components/PaddingBox';

type FormData<T extends number[]> = {
  sellPriceByKwhInEuro: T;
};

export const SellPrice = () => {
  const { t } = useTranslation('admin');

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData<number[]>>({});

  React.useEffect(() => {
    (async () => {
      const sellPrices = await getAllData(sellPriceByKwhInEuroCollection);

      sellPrices.forEach((t) => {
        // @ts-ignore
        setValue(t.model + t.powerRangeUid, t.sellPriceByKwhInEuro);
      });
    })();
  }, []);

  const powerRangesQuery = useFirestoreQueryData<PowerRange>(
    [powerRangesCollectionName],
    powerRangesCollection
  );

  return (
    <>
      <TitleWithIcon icon={<PaymentsIcon />} title={t('SellPrice.title')} />
      <PaddingBox>
        <Grid container spacing={6}>
          {modelsWithoutSelfConsumption.map((model) => (
            <Grid item xs={12} md={4} key={model}>
              <ColoredSquareAndModel
                model={model}
                errors={errors}
                control={control}
                inputLabel={t('SellPrice.buyBackPrice')}
                inputAdornment={t('SellPrice.euroPerKwh')}
              />
            </Grid>
          ))}
        </Grid>

        <div className="mb-12 mt-5 flex items-center justify-start">
          <Button
            onClick={handleSubmit((data) =>
              onSubmitValueForPowerRanges(
                data,
                powerRangesQuery,
                sellPriceByKwhInEuroCollection,
                'sellPriceByKwhInEuro'
              )
            )}
            variant="adminGreen">
            {t('saveChanges')}
          </Button>
        </div>
      </PaddingBox>
    </>
  );
};
