import { enqueueSnackbar } from 'notistack';
import i18next from '../locales/i18n';

export const snackError = (translationkey?: string) => {
  return enqueueSnackbar(i18next.t(translationkey || 'sorryAnErrorOccurred'), {
    variant: 'error',
  });
};

export const snackSuccess = (translationkey?: string) => {
  return enqueueSnackbar(i18next.t(translationkey || 'saved'), {
    variant: 'success',
  });
};
