import React from 'react';
import { getImageName } from '../productsChoice/getImageName';
import { Divider, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNewStudy } from '../contexts/NewStudyContext';
import { ChosenProduct, Product } from '../types/ProductTypes';
import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection } from '../firebase/firestoreCollections';
import { Timestamp } from 'firebase/firestore';

export const KitsBox = ({ withDelete }: { withDelete?: boolean }) => {
  const { t } = useTranslation();
  const { studyContext, setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const deleteProduct = (product: Product) => {
    const newChosenProducts = studyContext.chosenProducts.filter(
      (p) => p.catalogueReference !== product.catalogueReference
    );
    updateData(studiesCollection, {
      uid: studyContext.study?.uid,
      chosenProducts: newChosenProducts,
      updatedAt: Timestamp.fromDate(new Date()),
    });
    setStudyContext({
      ...studyContext,
      chosenProducts: newChosenProducts,
    });
  };

  const Kit = ({ product, index }: { product: ChosenProduct; index: number }) => (
    <SelectedKitBox key={product.catalogueReference} className={isWeb ? '' : 'mb-4'}>
      <KitNumber className="self-start">
        {t('product:kits.kit')} {index + 1}
      </KitNumber>

      <div className="mb-4 flex w-full">
        <ProductImage src={'/images/tiles/' + getImageName(product)} alt={product.tileType} />
        <div className="flex flex-col">
          <ProductInfoWithMargin>{product.name}</ProductInfoWithMargin>
          <ProductInfoWithMargin>
            <Trans
              i18nKey="product:composition.unitsOn"
              values={{ quantity: product.productQuantity, surface: product.surface }}
              count={product.productQuantity}
            />
          </ProductInfoWithMargin>
          <ProductInfo>
            <Trans
              i18nKey="product:composition.puissanceReelle"
              values={{ realPower: product.totalPower }}
            />
          </ProductInfo>
          <OtherInfoLine1 className="font-normal">
            {t(`product:composition.${product.color}`)}
          </OtherInfoLine1>

          <OtherInfoLine2 className="font-normal">
            {product.connectionPrice && t('product:composition.serviceBranchementInclu')}
          </OtherInfoLine2>
        </div>
      </div>
      {studyContext.shouldDisplayPrice ? (
        <>
          <Divider flexItem />

          <FromText className="mt-4">{t('product:kits.prixVteAPartirDe')}</FromText>
          <Price className="self-end">
            {product.sellPrice
              ? product.sellPrice + (product.connectionPrice || 0)
              : product.listPrice + (product.connectionPrice || 0)}{' '}
            € TTC
          </Price>
        </>
      ) : null}
      {withDelete && <Delete onClick={() => deleteProduct(product)}>{t('common:delete')}</Delete>}
    </SelectedKitBox>
  );

  return (
    <>
      {studyContext.chosenProducts.map((product, index) => (
        <Kit product={product} index={index} key={product.catalogueReference} />
      ))}
    </>
  );
};

const KitBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 260px;
  border-radius: 10px;
  margin-right: 20px;
  padding: 18px;
  background-color: white;
`;

const SelectedKitBox = styled(KitBox)`
  box-shadow: 0 0 5px #d4d4e5;
`;

const KitNumber = styled('div')`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const ProductImage = styled('img')`
  width: 60px;
  object-fit: contain;
  margin-right: 15px;
  border-radius: 4px;
  align-self: start;
`;

const ProductInfo = styled('span')`
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
`;

const ProductInfoWithMargin = styled(ProductInfo)`
  margin-bottom: 8px;
`;

const OtherInfoLine1 = styled('span')`
  font-size: 13px;
  line-height: 17px;
  margin-top: 4px;
`;

const OtherInfoLine2 = styled(OtherInfoLine1)`
  margin-top: 8px;
  margin-bottom: 10px;
`;

const Price = styled('div')`
  font-weight: bold;
  font-size: 20px;
`;

const FromText = styled('span')`
  font-size: 13px;
  font-weight: bold;
`;

const Delete = styled('span')`
  text-decoration: underline;
  cursor: pointer;
  align-self: end;
  margin-top: 4px;
  font-weight: normal;
  font-size: 13px;
`;
