import React from 'react';
import { Controller } from 'react-hook-form';

import { Equipment } from './components/Equipment';
import { FormGridLayout } from './components/FormGridLayout';
import { handleMultipleSelect } from '../../util/formMultipleSelect';

export const NewSimulationHeating = ({ control, getValues }: { control: any; getValues: any }) => {
  return (
    <Controller
      name="heating"
      render={({ field }) => (
        <FormGridLayout
          withMobileLabel
          labelTranslationKey="heating"
          items={[
            <Equipment
              equipmentName="electricHeating"
              icon="\ue901"
              onClick={() => handleMultipleSelect(field, 'electricHeating', getValues)}
              isSelectedAtStart={getValues('heating')?.includes('electricHeating')}
            />,
            <Equipment
              equipmentName="heatPump"
              icon="\ue903"
              onClick={() => handleMultipleSelect(field, 'heatPump', getValues)}
              isSelectedAtStart={getValues('heating')?.includes('heatPump')}
            />,
            <Equipment
              equipmentName="boiler"
              icon="\ue904"
              onClick={() => handleMultipleSelect(field, 'boiler', getValues)}
              isSelectedAtStart={getValues('heating')?.includes('boiler')}
            />,
          ]}
        />
      )}
      control={control}
    />
  );
};
