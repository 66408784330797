import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { SalesArea, SolarSector } from '../../types/AdminManagement';
import {
  salesAreasCollection,
  salesAreasCollectionName,
  solarSectorsCollection,
  solarSectorsCollectionName,
  usersCollection,
  usersCollectionName,
} from '../../firebase/firestoreCollections';
import { AppHeader } from '../../components/AppHeader';
import {
  ActionIconContainer,
  ActionsContainer,
  Background,
  BigPlusButton,
  Container,
  GoBackButton,
  GridElement,
  GridText,
  Subtitle,
} from './components/AdminComponents';
import { Button, Divider, Grid, Modal, styled } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalContent } from '../../components/ModalContent';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { setData } from '../../firebase/firestoreQueries';
import { snackSuccess } from '../../util/snacks';
import { User } from '../../types/User';
import { SelectManagerModal } from './components/SelectManagerModal';
import { AddLinkedModal } from './components/AddLinkedModal';
import { clientRoutes } from 'routing/clientRoutes';
import { TitleWithLabelEdit } from './components/TitleWithLabelEdit';

export const EditSolarSector = () => {
  const { t } = useTranslation('admin');
  const { uid } = useParams();

  const [isManagerEditActionOpen, setIsManagerEditActionOpen] = useState(false);
  const [isEditManagerModalOpen, setIsEditManagerModalOpen] = useState(false);
  const [isDeleteSalesAreaModalOpen, setIsDeleteSalesAreaModalOpen] = useState<string>();
  const [isAddSalesAreasModalOpen, setIsAddSalesAreasModalOpen] = useState(false);
  const [isDeleteSectorActionVisible, setIsDeleteSectorActionVisible] = useState<string>();
  const [newManager, setNewManager] = useState<User>();
  const [newLinkedSalesAreas, setNewLinkedSalesAreas] = useState<SalesArea[]>([]);
  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection, {
    subscribe: true,
  });
  const internalUsers = (usersQuery.data || []).filter((u) => u.isInternal);

  const solarSectorsQuery = useFirestoreQueryData<SolarSector>(
    [solarSectorsCollectionName],
    solarSectorsCollection,
    {
      subscribe: true,
    }
  );
  const solarSectors = solarSectorsQuery.data || [];
  const solarSector = solarSectors.find((solarSectors) => solarSectors.uid === uid);

  const salesAreasQuery = useFirestoreQueryData<SalesArea>(
    [salesAreasCollectionName],
    salesAreasCollection,
    {
      subscribe: true,
    }
  );
  const salesAreas = salesAreasQuery.data || [];
  const linkedSalesArea =
    salesAreas.filter((salesArea) => solarSector?.linkedSalesAreasUid.includes(salesArea.uid)) ||
    [];

  const updateSolarSectorsLabel = async (newLabel: string) => {
    await setData(solarSectorsCollection, { ...solarSector, label: newLabel });
    snackSuccess();
  };

  const updateManager = async () => {
    if (!newManager) {
      return;
    }

    await setData(solarSectorsCollection, {
      ...solarSector,
      managerEmail: newManager.email,
      managerFirstName: newManager.firstName || '',
      managerLastName: newManager.lastName || '',
    });
    snackSuccess();
    setIsEditManagerModalOpen(false);
  };

  const updateSalesAreas = async () => {
    await setData(solarSectorsCollection, {
      ...solarSector,
      linkedSalesAreasUid: _.uniq(
        solarSector?.linkedSalesAreasUid.concat((newLinkedSalesAreas || []).map((s) => s.uid))
      ),
    });
    snackSuccess();
    setNewLinkedSalesAreas([]);
    setIsAddSalesAreasModalOpen(false);
  };

  const deleteSalesAreas = async (sectorUid?: string) => {
    await setData(solarSectorsCollection, {
      ...solarSector,
      linkedSalesAreasUid: solarSector?.linkedSalesAreasUid.filter((uid) => uid !== sectorUid),
    });
    snackSuccess();
    setIsDeleteSalesAreaModalOpen(undefined);
  };

  if (!solarSector) {
    return null;
  }

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <TitleWithLabelEdit
            title={t('UsersManagement.solarSectors')}
            label={solarSector?.label || ''}
            updateLabel={updateSolarSectorsLabel}
          />
          <Divider />
          <GoBackButton path={clientRoutes.solarSectorsManagement} />
          <Subtitle>{t('UsersManagement.solarSectorManager')}</Subtitle>
          <Grid container spacing={2} className="mb-10">
            <Grid item xs={6} lg={4}>
              <GridElement
                className="flex flex-col items-center"
                onMouseEnter={() => setIsManagerEditActionOpen(true)}
                onMouseLeave={() => setIsManagerEditActionOpen(false)}>
                <div className="relative flex w-full justify-center">
                  <GridText>
                    {_.upperFirst(solarSector.managerFirstName)}{' '}
                    {_.upperFirst(solarSector.managerLastName)}
                  </GridText>
                  {isManagerEditActionOpen && (
                    <ActionsContainer>
                      <ActionIconContainer
                        onClick={() => setIsEditManagerModalOpen(true)}
                        className="cursor-pointer">
                        <CreateIcon />
                      </ActionIconContainer>
                    </ActionsContainer>
                  )}
                </div>
                <GridText>{solarSector.managerEmail}</GridText>
              </GridElement>
            </Grid>
          </Grid>

          <Subtitle>{t('UsersManagement.linkedSalesAreas')}</Subtitle>
          <Grid container spacing={2}>
            {linkedSalesArea.map((salesArea) => (
              <Grid item xs={6} lg={4} key={salesArea.uid}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setIsDeleteSectorActionVisible(salesArea.uid)}
                  onMouseLeave={() => setIsDeleteSectorActionVisible(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>{salesArea.label}</GridText>
                    {isDeleteSectorActionVisible === salesArea.uid && (
                      <ActionsContainer>
                        <ActionIconContainer
                          onClick={() => setIsDeleteSalesAreaModalOpen(salesArea.uid)}>
                          <DeleteIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <GridText>
                    {_.upperCase(salesArea.managerFirstName)}{' '}
                    {_.upperFirst(salesArea.managerLastName)}
                  </GridText>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddSalesAreasModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>
        </Container>
      </Background>
      <SelectManagerModal
        title={t('UsersManagement.selectNewManager')}
        isModalOpen={isEditManagerModalOpen}
        setIsEditModalOpen={setIsEditManagerModalOpen}
        users={internalUsers}
        newManager={newManager}
        setNewManager={setNewManager}
        updateManager={updateManager}
      />
      <Modal open={!!isDeleteSalesAreaModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">
              {t('UsersManagement.removeSalesAreasFromSolarSector')}
            </Title>
          </div>

          <div className="mb-5 mt-7 text-center">{t('UsersManagement.youCanAddItAgain')}</div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsDeleteSalesAreaModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton
                variant="adminGreen"
                onClick={() => deleteSalesAreas(isDeleteSalesAreaModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>
      <AddLinkedModal
        title={t('UsersManagement.addLinkedSalesAreas')}
        onChange={setNewLinkedSalesAreas}
        isModalOpen={isAddSalesAreasModalOpen}
        setIsModalOpen={setIsAddSalesAreasModalOpen}
        onValidate={updateSalesAreas}
        options={salesAreas.filter((s) => !solarSector.linkedSalesAreasUid.includes(s.uid))}
        disabled={!newLinkedSalesAreas?.length}
        noOptionsText={t('all:studiesList.noSalesAreaFound')}
      />
    </>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 15px;
`;

const SButton = styled(Button)`
  width: 250px;
`;
