import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { BackgroundImage, FormContainer, FormContent } from '../auth/authStyle';
import { clientRoutes } from '../routing/clientRoutes';
import styled from 'styled-components';

export const Policies = withTranslation()(({ t }) => {
  return (
    <BackgroundImage>
      <FormContainer>
        <SFormContent>
          <h1>Politique relative à la protection des données</h1>
          <p className="mt-10">
            La présente politique décrit les engagements pris en matière de protection des données à
            caractère personnel par :
            <br />
            EDILIANS SAS au capital social de 161.227.700,00 euros (€) 65, chemin du Moulin Carron,
            69570 Dardilly, FRANCE Immatriculée au RCS de Lyon sous le numéro B 449 354 224 Cette
            politique relative à la protection de données (« Politique ») s'applique au contenu de
            l’application « Simulation solaire » d’EDILIANS www.edilians.com (« l’Application ») et
            encadre l'utilisation des données à caractère personnel par l’Application ou à travers
            nos services. Nous vous remercions de lire attentivement cette Politique afin de vous
            assurer qu'elle ne contient rien que vous ne soyez prêt à accepter. L’utilisation de
            cette Application implique votre acceptation et adhésion complète et inconditionnelle à
            cette Politique. Si la Politique contient des dispositions que vous ne souhaitez pas
            accepter, vous n’êtes pas autorisé à utiliser cette Application.
            <br />
            <br />
            <ul>
              <li
                onClick={() => document.getElementById('first')?.scrollIntoView()}
                className="underline">
                1. Loi applicable
              </li>
              <li
                onClick={() => document.getElementById('second')?.scrollIntoView()}
                className="underline">
                2. Vie privée et protection de vos données à caractère personnel
              </li>
            </ul>
          </p>
          <div className="flex justify-start">
            <h2 className="my-5 text-left" id="first">
              1. Loi applicable
            </h2>
          </div>
          <p className="py-2">
            Cette Politique est soumise aux dispositions règlementaires applicables en France,
            comprenant notamment la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux
            fichiers et aux libertés modifiée ainsi que le Règlement européen no 2016/679, dit
            règlement général sur la protection des données (« RGPD ») ou toutes règlementations qui
            viendraient s’y substituer ou les compléter.
          </p>
          <h2 className="my-5" id="second">
            2. VIE PRIVEE ET PROTECTION DE VOS DONNEES A CARACTERE PERSONNEL
          </h2>
          <p className="py-2">
            Nous respectons les normes les plus strictes en matière de protection de la vie privée
            et des données à caractère personnel lors de l'utilisation du Site.
          </p>
          <p className="py-2">
            <span className="underline">
              2.1 Quand et pourquoi collectons-nous des données à caractère personnel sur vous ?
            </span>
            <br />
            EDILIANS a créé l’Application dédiée à ces partenaires et clients. EDILIANS demande
            ainsi à chaque utilisateur de créer son compte afin d’utiliser l’Application. EDILIANS
            validera l’inscription de chaque utilisateur préalablement
          </p>
          <p className="py-2">
            <span className="underline">
              2.1.1 Quel type de données à caractère personnel collectons-nous ?
            </span>
            <br />
            Les données à caractère personnel que nous pouvons recueillir comprennent, sans que
            cette liste soit exhaustive, votre nom, raison sociale, adresse postale, adresse de
            courriel, numéro de téléphone fixe et/ou portable, et toute autre information collectée
            à l’occasion de l'utilisation l’Application et des services de simulation.
          </p>
          <p className="py-2">
            <span className="underline">
              2.1.2 Finalités, base juridique du traitement et durée de conservation de vos données
              à caractère personnel
            </span>
            <br />
            Nous traitons vos données à caractère personnel collectées à l’occasion de l’ouverture
            de votre compte pour vous permettre de bénéficier des services de l’Application.
            L’ouverture d’un compte utilisateur est indispensable afin d’accéder aux services de
            l’Application, à savoir : Réception de « Newsletters » EDILIANS et de prospection
            commerciale sur nos produits et services solaires, ; et Réalisation d’une simulation
            pour une installation solaire. Les données à caractère personnel sont conservées pendant
            une durée de 3 ans à compter de votre dernier contact ou de votre dernière activité sur
            le compte.
          </p>
          <p className="py-2">
            <span className="underline">
              2.2 Qui a accès à vos données à caractère personnel ?{' '}
            </span>
            <br />
            Seul un nombre restreint de nos salariés ont accès à vos données à caractère personnel,
            qui ne leur sont accessibles qu'en cas de nécessité en vertu de leur fonction. Elles ne
            sont destinées qu’aux seules personnes ayant besoin d’en connaître au sein de la
            Direction marketing, communication et commerciale du groupe EDILIANS, dans la stricte
            limite de leurs attributions. Les données à caractère personnel que vous nous fournissez
            ne sont ni accessibles aux tiers, ni transmises, ni vendues ou échangées, sauf dans les
            cas décrits ci-après. Nous pouvons transférer (ou rendre accessible) vos données à
            caractère personnel à nos prestataires de service qui traitent les données à caractère
            personnel pour notre compte notamment notre prestataire informatique hébergeant
            l’Application. Nous ne transférons que ce qui est nécessaire à ce tiers pour exécuter sa
            prestation et ses obligations contractuelles. Afin de nous conformer aux lois et
            règlements impératifs applicables, nous pouvons divulguer des données à caractère
            personnel vous concernant à des tiers, y compris aux autorités publiques compétentes.
            Lorsque dans les conditions prévues ci-dessus, vos données à caractère personnel sont
            susceptibles d’être transférées en dehors du territoire de l’Union européenne/Espace
            économique européen, EDILIANS s’assure que le destinataire est situé dans un pays dont
            la Commission européenne a constaté qu’il assurait un niveau de protection adéquat et, à
            défaut, prévoit les garanties appropriées nécessaires (notamment en recourant aux
            clauses contractuelles types adoptées par la Commission européenne accessibles
            <a
              href="https://www.cnil.fr/fr/les-clauses-contractuelles-types-de-la-commision-europeenne"
              className="underline">
              https://www.cnil.fr/fr/les-clauses-contractuelles-types-de-la-commision-europeenne
            </a>
            ).
          </p>
          <p className="py-2">
            <span className="underline">2.3 Vos droits </span>
            <br /> Vous disposez d’un droit d’accès, de rectification, d’effacement ou de
            portabilité de vos données personnelles, ainsi qu’un droit de vous opposer ou de
            solliciter la limitation du traitement ou de retirer votre consentement, dans les
            conditions et limites prévues à la loi. Vous pouvez également définir et communiquer à
            EDILIANS des directives particulières relatives à la conservation, à l'effacement et à
            la communication de vos données à caractère personnel après votre décès. Vous pouvez
            exercer vos droits en écrivant par :
            <ul>
              <li>
                Courrier à : EDILIANS S.A.S. – Service Marketing Digital/Données personnelles, 65,
                Chemin du Moulin Carron, 69570 Dardilly, FRANCE
              </li>
              <li>
                Email à :
                <a href="mailto:donneespersonnelles@edilians.com" className="underline">
                  donneespersonnelles@edilians.com
                </a>
              </li>
            </ul>
          </p>
          <p className="py-2">
            A tout moment après votre demande consistant à recevoir nos services informationnels
            (bulletin d’information ou « Newsletter ») ou offres promotionnelles, vous pouvez
            demander à ne plus les recevoir, sans frais. Pour cela, il suffit de suivre la procédure
            expliquée en pied de courriel ou du sms que vous avez reçu, d’en faire la demande à
            EDILIANS aux coordonnées ci-dessus ou encore de vous désabonner directement dans votre
            compte utilisateur. Si après avoir contacté EDILIANS, vous estimez que vos droits ne
            sont pas respectés ou que les traitements que EDILIANS opère ne sont pas conformes à la
            règlementation applicable, vous pouvez adresser une réclamation auprès de la Commission
            nationale de l’informatique et des libertés (CNIL), 3, place de Fontenoy, TSA 80715
            75334, Paris cedex 07 (tél. : +33 1 53 73 22 22).
          </p>
          <p className="py-2">
            <span className="underline">2.4 Sécurité </span>
            <br /> Toutes les précautions ont été prises sur nos bases de données pour conserver vos
            données à caractère personnel dans un environnement sécurisé.
          </p>
          <p className="pt-5 underline">
            <Link to={clientRoutes.signInChoice}>{t('backToHome')}</Link>
          </p>
        </SFormContent>
      </FormContainer>
    </BackgroundImage>
  );
});

const SFormContent = styled(FormContent)`
  align-items: start;
`;
