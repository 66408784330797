import React from 'react';
import { Button, Divider, Grid, Modal, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

import { AppHeader } from '../../components/AppHeader';
import { clientRoutes } from '../../routing/clientRoutes';
import {
  salesAreasCollection,
  salesAreasCollectionName,
  sectorsCollection,
  sectorsCollectionName,
} from '../../firebase/firestoreCollections';
import {
  ActionIconContainer,
  Background,
  BigPlusButton,
  Container,
  GoBackButton,
  GridElement,
  GridText,
  InactiveGridElement,
  InactiveGridText,
} from './components/AdminComponents';
import { ModalContent } from '../../components/ModalContent';
import { snackSuccess } from '../../util/snacks';
import { SalesArea, Sector } from '../../types/AdminManagement';
import { setData, updateData } from '../../firebase/firestoreQueries';
import _ from 'lodash';
import { v4 } from 'uuid';
import { AddModal } from './components/AddModal';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const schema = yup
  .object()
  .shape({
    code: yup.string().required('requiredField'),
    label: yup.string().required('requiredField'),
    manager: yup.object().shape({
      email: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    }),
    linkedSectorsUid: yup
      .array()
      .of(yup.string().required('requiredField'))
      .required('requiredField'),
  })
  .required();

type FormValues = yup.InferType<typeof schema>;

export const SalesAreasManagement = () => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();

  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<string>();
  const [isActivateModalOpen, setIsActivateModalOpen] = React.useState<string>();
  const [displayActions, setDisplayActions] = React.useState<string>();

  const salesAreasQuery = useFirestoreQueryData<SalesArea>(
    [salesAreasCollectionName],
    salesAreasCollection,
    {
      subscribe: true,
    }
  );
  const activeSalesAreas = (salesAreasQuery.data || []).filter((s) => s.isActive);
  const inactiveSalesAreas = (salesAreasQuery.data || []).filter((s) => !s.isActive);

  const sectorsQuery = useFirestoreQueryData<Sector>([sectorsCollectionName], sectorsCollection, {
    subscribe: true,
  });
  const sectors = sectorsQuery.data || [];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: FormValues) => {
    const salesArea: SalesArea = {
      ..._.omit(data, 'manager'),
      uid: v4(),
      isActive: true,
      managerFirstName: data.manager.firstName || '',
      managerLastName: data.manager.lastName || '',
      managerEmail: data.manager.email || '',
    };

    await setData(salesAreasCollection, salesArea);
    reset();
    snackSuccess();
    setIsAddModalOpen(false);
  };

  const deactivateSalesArea = async (salesAreaUid?: string) => {
    if (!salesAreaUid) {
      return;
    }

    await updateData(salesAreasCollection, { uid: salesAreaUid, isActive: false });
    snackSuccess();
    setIsDeleteModalOpen(undefined);
  };

  const activateSalesArea = async (salesAreaUid?: string) => {
    if (!salesAreaUid) {
      return;
    }

    await updateData(salesAreasCollection, { uid: salesAreaUid, isActive: true });
    snackSuccess();
    setIsActivateModalOpen(undefined);
  };

  const onModalClose = () => {
    setIsAddModalOpen(false);
    reset();
  };

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <h1 className="mb-8">{t('UsersManagement.salesAreas')}</h1>

          <Divider />

          <GoBackButton />

          <Grid container spacing={2} className="mb-10">
            {activeSalesAreas.map((salesArea) => (
              <Grid item xs={6} lg={4} key={salesArea.uid}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setDisplayActions(salesArea.uid)}
                  onMouseLeave={() => setDisplayActions(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>{salesArea.code}</GridText>
                    {displayActions === salesArea.uid && (
                      <ActionsContainer>
                        <ActionIconContainer
                          className="mr-3"
                          onClick={() => navigate(clientRoutes.editSalesArea(salesArea.uid))}>
                          <CreateIcon />
                        </ActionIconContainer>
                        <ActionIconContainer onClick={() => setIsDeleteModalOpen(salesArea.uid)}>
                          <DeleteIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <GridText>{salesArea.label}</GridText>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>

          <h2>{t('UsersManagement.inactive')}</h2>
          <Grid container spacing={2} className="mb-10">
            {inactiveSalesAreas.map((salesArea) => (
              <Grid item xs={6} lg={4} key={salesArea.uid}>
                <InactiveGridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setDisplayActions(salesArea.uid)}
                  onMouseLeave={() => setDisplayActions(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <InactiveGridText>{salesArea.label}</InactiveGridText>
                    {displayActions === salesArea.uid && (
                      <ActionsContainer>
                        <ActionIconContainer onClick={() => setIsActivateModalOpen(salesArea.uid)}>
                          <PowerSettingsNewIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <InactiveGridText>
                    {salesArea.managerFirstName} {salesArea.managerLastName}
                  </InactiveGridText>
                </InactiveGridElement>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Background>

      <AddModal
        title={t('UsersManagement.newSalesArea')}
        linkedElementsName="linkedSectorsUid"
        onSubmit={handleSubmit(onSubmit)}
        isModalOpen={isAddModalOpen}
        onModalClose={onModalClose}
        errors={errors}
        control={control}
        options={sectors.map((s) => s.uid)}
        getOptionLabel={(sectorUid) => sectors.find((s) => sectorUid === s.uid)?.label || ''}
        disabled={!isValid}
        noOptionsText={t('all:studiesList.aucunSecteurTrouve')}
        linkedElementTitle={t('UsersManagement.linkedSectors')}
      />

      <Modal open={!!isDeleteModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.confirmDeleteElement')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">
            {t('UsersManagement.confirmDeleteElementDesc')}
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsDeleteModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" onClick={() => deactivateSalesArea(isDeleteModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={!!isActivateModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.confirmActivateElement')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">
            {t('UsersManagement.confirmActivateElementDesc')}
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsActivateModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" onClick={() => activateSalesArea(isActivateModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 70px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const ActionsContainer = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
`;
