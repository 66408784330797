import React from 'react';
import Grid from '@mui/material/Grid';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../style/colors';
import {
  BluePercentage,
  DoubleRowItem,
  GreenPercentage,
  H2,
  Profit,
  RoundedDiv,
  Icon,
} from './components/ResultsDetailsComponents';
import styled from 'styled-components';

export const ResultsDetailsMobile = ({
  selfConsumptionPercentage,
  annualCoveragePercentage,
  year1AmountSaved,
  year30AmountSaved,
  subsidyAmount,
}: {
  selfConsumptionPercentage?: number;
  annualCoveragePercentage?: number;
  year1AmountSaved?: string;
  year30AmountSaved?: string;
  subsidyAmount?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mb-6 mt-12">{t('details')}</h1>

      <Grid container spacing={2}>
        <Grid item xs={12} alignItems="stretch">
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={6} alignItems="stretch">
              <DoubleRowItem>
                <IconMobile className="ml-3">{'\ue925'}</IconMobile>
                <ProfitMobile>{year1AmountSaved}€</ProfitMobile>
                <h4 className="text-center">{t('results.details.economieAnnee1Titre')}</h4>
              </DoubleRowItem>
            </Grid>
            <Grid item xs={6} alignItems="stretch">
              <DoubleRowItem>
                <IconMobile className="ml-3">{'\ue926'}</IconMobile>
                <ProfitMobile>{year30AmountSaved}€</ProfitMobile>
                <h4 className="text-center">{t('results.details.economieAnnee30Titre')}</h4>
              </DoubleRowItem>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} alignItems="stretch">
          <Grid container spacing={2}>
            <Grid item md={3} xs={6} alignItems="stretch">
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12}>
                  <RoundedDivMobile>
                    <div className="flex items-center">
                      <H2>{t('results.details.autoconsommationAnnuelleMobile')}</H2>
                      <div className="flex">
                        <Tooltip
                          title={t('results.details.autoConsoInfos')}
                          enterTouchDelay={0}
                          leaveTouchDelay={12000}
                          sx={{ color: COLORS.primary }}>
                          <InfoIcon />
                        </Tooltip>
                        <Tooltip
                          title={t('results.details.augmenterAutoconsoInfos')}
                          enterTouchDelay={0}
                          leaveTouchDelay={8000}
                          sx={{ color: COLORS.primary }}>
                          <InfoIcon />
                        </Tooltip>
                      </div>
                    </div>
                    <GreenPercentageMobile>{selfConsumptionPercentage}%</GreenPercentageMobile>
                  </RoundedDivMobile>
                </Grid>
                <Grid item xs={12}>
                  <RoundedDivMobile>
                    <div className="flex items-center">
                      <H2 className="mr-2">{t('results.details.autoproductionAnnuelleMobile')}</H2>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={12000}
                        title={t('results.details.autoProdInfos')}
                        sx={{ color: COLORS.primary }}>
                        <InfoIcon />
                      </Tooltip>
                    </div>
                    <BluePercentageMobile>{annualCoveragePercentage}%</BluePercentageMobile>
                  </RoundedDivMobile>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={6} alignItems="stretch">
              <DoubleRowItemMobile>
                <IconMobile>{'\ue927'}</IconMobile>
                <ProfitMobile>{subsidyAmount}€</ProfitMobile>
                <h4 className="mb-3 text-center">{t('results.details.subventionsTitre')}</h4>
              </DoubleRowItemMobile>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const RoundedDivMobile = styled(RoundedDiv)`
  padding: 10px;
`;

const IconMobile = styled(Icon)`
  font-size: 60px;
  margin-top: -20px;
`;

const ProfitMobile = styled(Profit)`
  margin-bottom: 4px;
`;

const DoubleRowItemMobile = styled(DoubleRowItem)`
  justify-content: center;
`;

const GreenPercentageMobile = styled(GreenPercentage)`
  margin-top: 4px;
`;

const BluePercentageMobile = styled(BluePercentage)`
  margin-top: 4px;
`;
