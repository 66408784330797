import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { User } from '../../types/User';
import { usersCollection, usersCollectionName } from '../../firebase/firestoreCollections';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Divider, InputAdornment, Modal, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import styled from 'styled-components';

import { ModalContent } from '../../components/ModalContent';
import { updateData } from '../../firebase/firestoreQueries';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import _ from 'lodash';
import { COLORS } from '../../style/colors';
import { Background, Container } from './components/AdminComponents';
import { AppHeader } from '../../components/AppHeader';
import { clientRoutes } from '../../routing/clientRoutes';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { snackSuccess } from '../../util/snacks';
import * as XLSX from 'xlsx';
import { usersExportHeader } from './usersExportHeader';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

export const ProfessionalUsersManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection, {
    subscribe: true,
  });

  const apiRef = useGridApiRef();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isDetailsModalOpen, setIsDetailsModalOpen] = React.useState<string | undefined>();
  const [search, setSearch] = React.useState<string>();
  const [selectedRowsUid, setSelectedRowsUid] = useState<GridRowSelectionModel>([]);

  const formatRowsForGrid = (users: User[]): User[] => {
    return users.map((user) => ({
      ...user,
      id: user.uid,
    }));
  };

  const professionalUsers = formatRowsForGrid(
    usersQuery.data?.filter((user) => !user.isAdmin && !user.isInternal) || []
  ).filter((user) => {
    if (search) {
      return (
        user.companyName?.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(search.toLowerCase()) ||
        user.firstName?.toLowerCase().includes(search.toLowerCase()) ||
        user.email?.toLowerCase().includes(search.toLowerCase()) ||
        user.zipCode?.toLowerCase().includes(search.toLowerCase()) ||
        user.city?.toLowerCase().includes(search.toLowerCase()) ||
        user.phoneNumber?.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      return true;
    }
  });

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // const handleEditClick = (id: GridRowId) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow?.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };

  // const processRowUpdate = async (newRow: any) => {
  // if (
  //   isOverlapping(
  //     [newRow.minPower, newRow.maxPower],
  //     rows.filter((r) => r.id !== newRow.id)
  //   ) ||
  //   newRow.minPower >= newRow.maxPower ||
  //   newRow.minPower < 0
  // ) {
  //   snackError('admin:GlobalSettings.noOverlappingRanges');
  //   throw new Error('Overlapping ranges');
  // }
  //
  // const allRows = rows.concat(newRow).sort((r1, r2) => r1.maxPower - r2.maxPower);
  // const newRowIndex = allRows.findIndex((row) => row.id === newRow.id);
  // const updatedRow = { ...newRow, name: t('admin:rangeWithSharp') + newRowIndex, isNew: false };
  // try {
  //   await setData(usersCollection, omit({ uid: newRow.id, ...newRow }, 'isNew'));
  //   snackSuccess();
  // } catch (e) {
  //   snackError();
  // }
  //
  // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  // return updatedRow;
  // };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // const handleAddRange = () => {
  //   const id = v4();
  //   // @ts-ignore
  //   setRows((oldRows) => [...oldRows, { id, isNew: true }]);
  //   setRowModesModel((oldModel) => ({
  //     ...oldModel,
  //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'minPower' },
  //   }));
  // };

  const fromTimestampToDate = (date?: Timestamp): string => {
    return date?.seconds ? moment(date?.seconds * 1000).format('DD/MM/YYYY') : '';
  };

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      width: 110,
      editable: false,
      headerName: t('admin:professionalUsers.companyName'),
    },
    {
      field: 'lastName',
      headerName: t('admin:professionalUsers.name'),
      width: 105,
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (value) => _.upperFirst(value),
      editable: true,
    },
    {
      field: 'firstName',
      headerName: t('admin:professionalUsers.firstName'),
      width: 105,
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (value) => _.upperFirst(value),
      editable: true,
    },
    {
      field: 'email',
      headerName: t('admin:professionalUsers.email'),
      width: 180,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'zipCode',
      headerName: t('admin:professionalUsers.zipCode'),
      width: 85,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'city',
      headerName: t('admin:professionalUsers.city'),
      width: 80,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'phoneNumber',
      headerName: t('admin:professionalUsers.phone'),
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'isActive',
      headerName: t('admin:professionalUsers.status'),
      width: 65,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (grid) =>
        grid.value ? (
          <StatusCell>
            <GreenCircle />
            {t('admin:professionalUsers.active')}
          </StatusCell>
        ) : (
          <StatusCell>
            <RedCircle />
            {t('admin:professionalUsers.inactive')}
          </StatusCell>
        ),
    },
    {
      field: 'creationDate',
      headerName: t('admin:professionalUsers.creationDate'),
      width: 110,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      // @ts-ignore
      valueFormatter: (creationDate) => fromTimestampToDate(creationDate),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />,
          <DetailsButton
            onClick={() =>
              // @ts-ignore
              setIsDetailsModalOpen(id)
            }>
            {t('common:details')}
          </DetailsButton>,
          <div>
            {professionalUsers.find((p) => p.uid === id)?.isActive ? (
              <DeactivateButton
                onClick={async () => {
                  await updateData(usersCollection, {
                    // @ts-ignore
                    uid: id,
                    isActive: false,
                  });
                  snackSuccess();
                }}>
                {t('common:deactivate')}
              </DeactivateButton>
            ) : (
              <ActivateButton
                onClick={async () => {
                  await updateData(usersCollection, {
                    // @ts-ignore
                    uid: id,
                    isActive: true,
                  });
                  snackSuccess();
                }}>
                {t('common:activate')}
              </ActivateButton>
            )}
          </div>,
        ];
      },
    },
  ];

  const activateSelectedRows = async (deactivate: boolean) => {
    await Promise.all(
      selectedRowsUid.map(async (id) => {
        await updateData(usersCollection, {
          // @ts-ignore
          uid: id,
          isActive: !deactivate,
        });
      })
    );

    snackSuccess();
  };

  const exportSelectedRows = () => {
    const fileName = 'clients-pros.xlsx';

    const formattedProfessionalUsers = professionalUsers
      // .filter((u) => selectedRowsUid.includes(u.uid))
      .map((user) => [
        user.firstName + ' ' + user.lastName,
        user.companyName,
        user.siret?.toString(),
        user.gender === 'male' ? 'M' : 'Mme',
        user.email,
        user.address,
        user.zipCode,
        user.city,
        user.country,
        user.phoneNumber,
        user.isActive ? 'Oui' : 'Non',
        fromTimestampToDate(user.creationDate),
      ]);

    const worksheet = XLSX.utils.json_to_sheet(formattedProfessionalUsers);
    XLSX.utils.sheet_add_aoa(worksheet, [usersExportHeader], { origin: 'A1' });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const detailUser = professionalUsers.find((u) => u.uid === isDetailsModalOpen);

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <h1 className="mb-2">{t('admin:UsersManagement.professionalUsersManagement')}</h1>

          <Divider />

          <div className="mt-6">
            <button
              type="button"
              onClick={() => navigate(clientRoutes.usersManagement)}
              className="btn go-back-btn">
              <span style={{ fontFamily: 'solaire', fontSize: 26 }}>{'\ue929'}</span>
            </button>
          </div>

          <SearchContainer>
            <Button variant="contained" color="primary" onClick={exportSelectedRows} size="small">
              {t('admin:UsersManagement.exportData')}
            </Button>
            <TextField
              onChange={onSearchChange}
              value={search}
              variant="standard"
              placeholder={t('admin:UsersManagement.fullTextSearch')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search?.length ? (
                      <CloseIcon onClick={() => setSearch('')} className="cursor-pointer" />
                    ) : (
                      <ArrowRightAltIcon />
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{ width: '50%', maxWidth: 440 }}
            />
          </SearchContainer>

          {selectedRowsUid.length ? (
            <div>
              <div className="mb-5 flex items-center gap-3">
                <p>{t('admin:UsersManagement.selectedUsers', { count: selectedRowsUid.length })}</p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => activateSelectedRows(false)}
                  sx={{ width: 170 }}
                  size="small">
                  {t('common:activate')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => activateSelectedRows(true)}
                  sx={{ width: 170 }}
                  size="small">
                  {t('common:deactivate')}
                </Button>{' '}
                <div className="ml-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => apiRef.current.setRowSelectionModel([])}
                    sx={{ width: 170 }}
                    size="small">
                    {t('admin:UsersManagement.cancelSelection')}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}

          <Box
            sx={{
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}>
            <DataGrid
              apiRef={apiRef}
              rows={professionalUsers}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectedRowsUid(newSelectionModel);
              }}
              checkboxSelection
              disableRowSelectionOnClick
              localeText={{
                noRowsLabel: t('admin:UsersManagement.noUsers'),
              }}
              // processRowUpdate={processRowUpdate}
              // slotProps={{
              //   toolbar: { setRows, setRowModesModel },
              // }}
              sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold !important',
                },
              }}
            />
          </Box>
        </Container>
      </Background>

      <Modal open={!!isDetailsModalOpen}>
        <SModalContent>
          <h2 className="text-center">
            {_.upperFirst(detailUser?.firstName)} {_.upperCase(detailUser?.lastName)}
          </h2>

          <div className="my-5 flex flex-col justify-center">
            <DetailRow>
              <DetailLabel>Email</DetailLabel>
              <p>{detailUser?.email}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Civilité</DetailLabel>
              <p>{detailUser?.gender === 'male' ? 'M' : 'Mme'}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Raison sociale</DetailLabel>
              <p>{detailUser?.companyName}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Siret</DetailLabel>
              <p>{detailUser?.siret}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Pays</DetailLabel>
              <p>{detailUser?.country}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Code postal</DetailLabel>
              <p>{detailUser?.zipCode}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Ville</DetailLabel>
              <p>{detailUser?.city}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Adresse</DetailLabel>
              <p>{detailUser?.address}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Numéro de téléphone</DetailLabel>
              <p>{detailUser?.phoneNumber}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Date de création</DetailLabel>
              <p>{detailUser?.creationDate?.toDate().toLocaleDateString()}</p>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Actif</DetailLabel>
              <p>{detailUser?.isActive ? 'Oui' : 'Non'}</p>
            </DetailRow>
          </div>

          <div className="mt-6 flex justify-end">
            <SButton onClick={() => setIsDetailsModalOpen(undefined)} variant="adminGrey">
              {t('common:close')}
            </SButton>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const SModalContent = styled(ModalContent)`
  min-height: 50px;
  min-width: 480px;
  max-width: 720px;
  padding: 30px 50px 32px;
`;

const SButton = styled(Button)`
  width: 130px;
  margin: 5px;
`;

const Circle = styled('div')`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
`;

const RedCircle = styled(Circle)`
  background-color: var(--red);
`;

const GreenCircle = styled(Circle)`
  background-color: var(--statut-color);
`;

const StatusCell = styled('div')`
  display: flex;
  align-items: center;
`;

const SearchContainer = styled('div')`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 35px;
  gap: 15px;
`;

const DetailRow = styled('div')`
  display: flex;
`;

const DetailLabel = styled('span')`
  font-weight: bold;
  width: 50%;
`;

const DeactivateButton = styled('div')`
  font-weight: bold;
  color: var(--red);
  cursor: pointer;
  width: 70px;
`;

const ActivateButton = styled(DeactivateButton)`
  color: var(--statut-color);
`;

const DetailsButton = styled(DeactivateButton)`
  color: ${COLORS.primary};
`;
