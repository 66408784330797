import React from 'react';
import Popover from '@mui/material/Popover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  useMediaQuery,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../style/colors';
import { getData } from '../firebase/firestoreQueries';
import { useAuth } from '../auth/authContext';
import { usersCollection } from '../firebase/firestoreCollections';
import { User } from '../types/User';
import { clientRoutes } from '../routing/clientRoutes';

export const UserMenu = ({ color }: { color?: string }) => {
  const { t } = useTranslation();
  const user = useAuth();
  const navigate = useNavigate();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const [userData, setUserData] = React.useState<User>();

  React.useEffect(() => {
    (async () => {
      if (user) {
        setUserData(await getData(usersCollection, user.uid));
      }
    })();
  }, [user]);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  const signOut = async () => {
    await getAuth().signOut();
    navigate(clientRoutes.signInChoice);
  };

  return (
    <div style={isWeb ? {} : { marginRight: 20 }}>
      <UserLogo {...bindHover(popupState)} color={color}>
        {'\ue91f'}
      </UserLogo>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Paper sx={{}}>
          <MenuList>
            <MenuItem>
              <ListItemIcon>
                <UserLogoInMenu>{'\ue91f'}</UserLogoInMenu>
              </ListItemIcon>
              <div className="flex flex-col">
                <ListItemText>
                  <UserName>
                    {`${userData?.firstName} ${userData?.lastName}`.toUpperCase()}
                  </UserName>
                </ListItemText>
                {userData?.isAdmin && (
                  <ListItemText>
                    <Role>Administrateur</Role>
                  </ListItemText>
                )}
              </div>
            </MenuItem>
            <Divider />
            <MenuItem>
              <ListItemText>
                <span className="font-bold" onClick={signOut}>
                  {t('common:logout')}
                </span>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </div>
  );
};

const UserLogo = styled.span<{ color?: string }>`
  font-family: solaire, sans-serif;
  color: ${(props) => (props.color ? props.color : 'white')};
  font-size: 30px;
  margin-left: 30px;
`;

const UserLogoInMenu = styled(UserLogo)`
  margin-left: 0;
  margin-right: 20px;
  color: ${COLORS.primaryColor};
`;

const UserName = styled('span')`
  font-weight: bold;
`;

const Role = styled('span')`
  font-size: 14px;
  margin-top: 4px;
`;
