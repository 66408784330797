const middle = (P1: google.maps.LatLng, P2: google.maps.LatLng) => {
  const lat = (P1.lat() + P2.lat()) / 2;
  let lng = P1.lng() - P2.lng();
  if (lng <= 180 && lng >= -180) {
    lng = (P1.lng() + P2.lng()) / 2;
  } else {
    lng = (P1.lng() + P2.lng() + 360) / 2;
  }
  return new google.maps.LatLng(lat, lng);
};

export const findClosestEdge = (polygon: google.maps.Polygon, coordinate: google.maps.LatLng) => {
  const polygonCoords = polygon.getPath().getArray();

  let closestEdge = [polygonCoords[0], polygonCoords[1]];

  let minDistance = Infinity;

  for (let i = 0; i < polygonCoords.length; i++) {
    const start = polygonCoords[i];
    const end = polygonCoords[(i + 1) % polygonCoords.length];

    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      coordinate,
      middle(start, end)
    );

    if (distance < minDistance) {
      minDistance = distance;
      closestEdge = [start, end];
    }
  }

  return closestEdge;
};
