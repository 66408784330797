import React from 'react';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Button, Checkbox, Modal, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';

import { COLORS } from '../style/colors';
import { useNewStudy } from '../contexts/NewStudyContext';
import { ModalContent } from '../components/ModalContent';
import { EconomicModelEnum } from '../types/EconomicModel.enum';
import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection, studiesForSearchCollection } from '../firebase/firestoreCollections';
import { snackSuccess } from '../util/snacks';
import theme from '../style/mui-theme';

const CheckBoxLine = ({ economicModel }: { economicModel: EconomicModelEnum }) => {
  const { t } = useTranslation('product');
  const { studyContext, setStudyContext } = useNewStudy();

  const toggleModels = (model: EconomicModelEnum) => {
    if (studyContext.economicModels.some((m) => m === model)) {
      const newModels = studyContext.economicModels.filter((m) => m !== model);
      setStudyContext({ ...studyContext, economicModels: newModels });
    } else {
      setStudyContext({ ...studyContext, economicModels: [...studyContext.economicModels, model] });
    }
  };

  return (
    <SCheckBoxLine>
      <span>{t(`economicModel.${economicModel}`)}</span>
      <Checkbox
        value={economicModel}
        onChange={() => toggleModels(economicModel)}
        checked={studyContext.economicModels.some((m) => m === economicModel)}
        disabled={
          studyContext.economicModels.length === 1 &&
          studyContext.economicModels[0] === economicModel
        }
      />
    </SCheckBoxLine>
  );
};

export const PowerAndModel = ({ withAddModel }: { withAddModel?: boolean }) => {
  const { t } = useTranslation('product');
  const { studyContext } = useNewStudy();
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));

  const [isAddModelsModalOpen, setIsAddModelsModalOpen] = React.useState(false);

  const study = studyContext.study;
  const results = studyContext.isCustomPowerSelected
    ? studyContext.customPowerResults
    : studyContext.results;
  if (!study || !results) {
    return null;
  }
  const power = studyContext.isCustomPowerSelected ? study.customPower : results.recommendedPower;

  const onAddModel = () => {
    setIsAddModelsModalOpen(true);
  };

  const onSaveModal = async () => {
    await updateData(studiesCollection, {
      uid: study.uid || undefined,
      economicModels: studyContext.economicModels,
      updatedAt: Timestamp.fromDate(new Date()),
    });
    await updateData(studiesForSearchCollection, {
      uid: study.uid || undefined,
      economicModels: studyContext.economicModels,
    });
    snackSuccess();
    setIsAddModelsModalOpen(false);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className="flex">
          <BorderBlock>
            <div className="mb-2 flex items-center">
              <h2 className="mr-2">{t('modelPower.puissance')}</h2>
              <FontAwesomeIcon icon={faCheck} color={COLORS.darkGreen} />
            </div>
            <span className="font-bold text-green">
              {power}
              {t('wattPeak')}
            </span>
          </BorderBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <BorderBlock>
            <div className="flex justify-between">
              <div className="mb-7 flex items-center">
                <h2 className="mr-2"> {t('modelPower.modeleEco')}</h2>
                <FontAwesomeIcon icon={faCheck} color={COLORS.darkGreen} />
              </div>
              {withAddModel && isWeb && (
                <Button onClick={onAddModel} variant="outlined" size="small">
                  {t('modelPower.add')}
                </Button>
              )}
            </div>

            {withAddModel && !isWeb && (
              <Button
                onClick={onAddModel}
                variant="outlined"
                size="small"
                style={{ marginTop: '-12px', marginBottom: '22px' }}>
                {t('modelPower.add')}
              </Button>
            )}

            <div className="flex flex-col">
              {studyContext.economicModels.map((economicModel) => (
                <ModelButton
                  size="large"
                  variant="notClickable"
                  disabled
                  key={economicModel}
                  fullWidth={!isWeb}>
                  {t(`economicModel.${economicModel}`)}
                </ModelButton>
              ))}
            </div>
          </BorderBlock>
        </Grid>
      </Grid>

      <Modal open={isAddModelsModalOpen}>
        <SModalContent style={isWeb ? {} : { paddingBottom: '30px' }}>
          <div className="mb-5">
            <h2 className="text-center">{t('modelPower.addModalTitle')}</h2>
          </div>

          <div className="mb-8 mt-3 flex justify-center ">{t('modelPower.content')}</div>

          <div className="mb-8 ml-8 flex flex-col items-start">
            <CheckBoxLine economicModel="selfConsumption" />
            <CheckBoxLine economicModel="consumptionAndResale" />
            <CheckBoxLine economicModel="fullResale" />
          </div>

          <div className="flex justify-center">
            <SButton onClick={onSaveModal} variant="contained" size="large">
              {t('common:save')}
            </SButton>
          </div>
        </SModalContent>
      </Modal>
    </Container>
  );
};

const Container = styled('div')`
  background-color: ${COLORS.lighterGreyPurple};
  border-radius: 10px;
  padding: 5px;
`;

const BorderBlock = styled('div')`
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  padding: 18px;
  width: 100%;
`;

const ModelButton = styled(Button)`
  width: 50%;
  margin-bottom: 10px !important;
`;

const SButton = styled(Button)`
  width: 230px;
`;

const SCheckBoxLine = styled('div')`
  width: 50%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SModalContent = styled(ModalContent)`
  width: 800px;
  max-width: 100%;
  padding: 35px 35px 0 35px;
`;
