import { AllOrientations, isOrientation, Orientation } from '../../../types/Orientations.enum';
import { altitudeOptions } from '../NewSimulationFormHelper';

export const computeOrientation = (
  polygon: google.maps.Polygon,
  highestEdge: google.maps.LatLng[]
): Orientation => {
  const center = computePolygonCenter(polygon);
  const closestEdgeMidPoint = computeMidpoint(highestEdge[0], highestEdge[1]);
  return computeOrientationFromPoints(closestEdgeMidPoint, center);
};

const computePolygonCenter = (polygon: google.maps.Polygon): google.maps.LatLng => {
  const bounds = new google.maps.LatLngBounds();
  let i;

  const polygonCoords = polygon.getPath().getArray();

  for (i = 0; i < polygonCoords.length; i++) {
    bounds.extend(polygonCoords[i]);
  }

  return bounds.getCenter();
};

export const getAltitude = async (polygon: google.maps.Polygon): Promise<string | void> => {
  const elevator = new google.maps.ElevationService();
  const middle = computePolygonCenter(polygon);
  const { results } = await elevator.getElevationForLocations({
    locations: [middle],
  });
  if (results[0]) {
    const elevation = results[0]?.elevation;
    const altitudeOptionsIndex = Math.round(elevation / 100);
    return altitudeOptions[altitudeOptionsIndex > 16 ? 16 : altitudeOptionsIndex + 1].value;
  }
};

const computeMidpoint = (
  pointA: google.maps.LatLng,
  pointB: google.maps.LatLng
): google.maps.LatLng => {
  const midLat = (pointA.lat() + pointB.lat()) / 2;
  const midLon = (pointA.lng() + pointB.lng()) / 2;

  return new google.maps.LatLng(midLat, midLon);
};

//origin point = center of the highest edge & destination point = center of the polygon
const computeOrientationFromPoints = (
  originPoint: google.maps.LatLng,
  destinationPoint: google.maps.LatLng
): Orientation => {
  //  Headings are expressed in degrees clockwise from North within the range [-180,180)
  const heading: number = google.maps.geometry.spherical.computeHeading(
    originPoint,
    destinationPoint
  );

  const orientation = doComputeOrientation(heading);

  if (isOrientation(orientation)) {
    return orientation;
  } else {
    return AllOrientations.NC;
  }
};

const doComputeOrientation = (heading: number): AllOrientations => {
  const value = heading + 180;
  return [
    AllOrientations.S,
    AllOrientations.SSW,
    AllOrientations.SW,
    AllOrientations.WSW,
    AllOrientations.W,
    AllOrientations.WNW,
    AllOrientations.NW,
    AllOrientations.NNW,
    AllOrientations.N,
    AllOrientations.NNE,
    AllOrientations.NE,
    AllOrientations.ENE,
    AllOrientations.E,
    AllOrientations.ESE,
    AllOrientations.SE,
    AllOrientations.SSE,
    AllOrientations.S,
  ][Math.round(value / 22.5)];
};
