import React from 'react';
import { Button, Divider, Grid, Modal, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

import { AppHeader } from '../../components/AppHeader';
import { clientRoutes } from '../../routing/clientRoutes';
import { sectorsCollection, sectorsCollectionName } from '../../firebase/firestoreCollections';
import {
  ActionIconContainer,
  Background,
  BigPlusButton,
  Container,
  GoBackButton,
  GridElement,
  GridText,
  InactiveGridElement,
  InactiveGridText,
} from './components/AdminComponents';
import { ModalContent } from '../../components/ModalContent';
import { snackSuccess } from '../../util/snacks';
import { Sector } from '../../types/AdminManagement';
import { departments } from '../../util/departments';
import { setData, updateData } from '../../firebase/firestoreQueries';
import { v4 } from 'uuid';
import _ from 'lodash';
import { AddModal } from './components/AddModal';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const schema = yup
  .object()
  .shape({
    code: yup.string().required('requiredField'),
    label: yup.string().required('requiredField'),
    manager: yup
      .object()
      .shape({
        email: yup.string().required('requiredField'),
        firstName: yup.string(),
        lastName: yup.string(),
      })
      .required('requiredField'),
    linkedDepartmentCodes: yup
      .array()
      .of(yup.string().required('requiredField'))
      .required('requiredField'),
  })
  .required();

type FormValues = yup.InferType<typeof schema>;

export const SectorsManagement = () => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();

  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<string>();
  const [isActivateModalOpen, setIsActivateModalOpen] = React.useState<string>();
  const [displayActions, setDisplayActions] = React.useState<string>();

  const sectorsQuery = useFirestoreQueryData<Sector>([sectorsCollectionName], sectorsCollection, {
    subscribe: true,
  });

  const activeSectors = (sectorsQuery.data || []).filter((s) => s.isActive);
  const inactiveSectors = (sectorsQuery.data || []).filter((s) => !s.isActive);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    const sector: Sector = {
      ..._.omit(data, 'manager'),
      uid: v4(),
      isActive: true,
      managerFirstName: data.manager.firstName || '',
      managerLastName: data.manager.lastName || '',
      managerEmail: data.manager.email || '',
      prescriptionManagers: [],
    };

    await setData(sectorsCollection, sector);
    reset();
    snackSuccess();
    setIsAddModalOpen(false);
  };

  const deactivateSector = async (sectorUid?: string) => {
    if (!sectorUid) {
      return;
    }

    await updateData(sectorsCollection, { uid: sectorUid, isActive: false });

    snackSuccess();
    setIsDeleteModalOpen(undefined);
  };

  const activateSector = async (sectorUid?: string) => {
    if (!sectorUid) {
      return;
    }

    await updateData(sectorsCollection, { uid: sectorUid, isActive: true });
    snackSuccess();
    setIsActivateModalOpen(undefined);
  };

  const onModalClose = () => {
    setIsAddModalOpen(false);
    reset();
  };

  return (
    <>
      <AppHeader />
      <Background>
        <Container>
          <h1 className="mb-8">{t('UsersManagement.sectors')}</h1>

          <Divider />

          <GoBackButton />

          <Grid container spacing={2} className="mb-10">
            {activeSectors.map((sector) => (
              <Grid item xs={6} lg={4} key={sector.uid}>
                <GridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setDisplayActions(sector.uid)}
                  onMouseLeave={() => setDisplayActions(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <GridText>{sector.label}</GridText>
                    {displayActions === sector.uid && (
                      <ActionsContainer>
                        <ActionIconContainer
                          className="mr-3"
                          onClick={() => navigate(clientRoutes.editSector(sector.uid))}>
                          <CreateIcon />
                        </ActionIconContainer>
                        <ActionIconContainer onClick={() => setIsDeleteModalOpen(sector.uid)}>
                          <DeleteIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <GridText>
                    {_.upperFirst(sector.managerFirstName)} {_.upperFirst(sector.managerLastName)}{' '}
                    {_.truncate(sector.linkedDepartmentCodes.join('/'))}
                  </GridText>
                </GridElement>
              </Grid>
            ))}
            <Grid item xs={6} lg={4}>
              <BigPlusButton onClick={() => setIsAddModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} color="white" fontSize={34} />
              </BigPlusButton>
            </Grid>
          </Grid>

          <h2>{t('UsersManagement.inactive')}</h2>
          <Grid container spacing={2} className="mb-10">
            {inactiveSectors.map((sector) => (
              <Grid item xs={6} lg={4} key={sector.uid}>
                <InactiveGridElement
                  className="flex flex-col items-center"
                  onMouseEnter={() => setDisplayActions(sector.uid)}
                  onMouseLeave={() => setDisplayActions(undefined)}>
                  <div className="relative flex w-full justify-center">
                    <InactiveGridText>{sector.label}</InactiveGridText>
                    {displayActions === sector.uid && (
                      <ActionsContainer>
                        <ActionIconContainer onClick={() => setIsActivateModalOpen(sector.uid)}>
                          <PowerSettingsNewIcon />
                        </ActionIconContainer>
                      </ActionsContainer>
                    )}
                  </div>
                  <InactiveGridText>
                    {sector.managerFirstName} {sector.managerLastName}
                  </InactiveGridText>
                </InactiveGridElement>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Background>

      <AddModal
        title={t('UsersManagement.newSector')}
        linkedElementsName="linkedDepartmentCodes"
        onSubmit={handleSubmit(onSubmit)}
        isModalOpen={isAddModalOpen}
        onModalClose={onModalClose}
        errors={errors}
        control={control}
        options={departments.map((department) => department.num_dep)}
        getOptionLabel={(departmentCode) => {
          const department = departments.find((d) => d.num_dep === departmentCode);
          return department?.num_dep + ' - ' + department?.dep_name;
        }}
        disabled={!isValid}
        noOptionsText={t('all:studiesList.aucunDepartementTrouve')}
        linkedElementTitle={t('UsersManagement.linkedDepartments')}
      />

      <Modal open={!!isDeleteModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.confirmDeleteAdmin')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">{t('UsersManagement.confirmDeleteAdminDesc')}</div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsDeleteModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" onClick={() => deactivateSector(isDeleteModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>

      <Modal open={!!isActivateModalOpen}>
        <SModalContent>
          <div className="mb-5 mt-7">
            <Title className="text-center">{t('UsersManagement.confirmActivateElement')}</Title>
          </div>

          <div className="mb-5 mt-7 text-center">
            {t('UsersManagement.confirmActivateElementDesc')}
          </div>

          <div className="mt-8 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={() => setIsActivateModalOpen(undefined)}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" onClick={() => activateSector(isActivateModalOpen)}>
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 780px;
  padding: 30px 70px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const ActionsContainer = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
`;
