import React from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FormControl, FormControlLabel, Radio, RadioGroup, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputContainer, SelectAndIcon, SInput } from './components/NewSimulationComponents';
import { FormGridLayout } from './components/FormGridLayout';
import { StudyFormValues } from './NewSimulationFormHelper';

const InputRow = ({
  name,
  control,
  errors,
  watch,
}: {
  name: string;
  control: any;
  errors: any;
  watch: any;
}) => {
  const { t } = useTranslation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const selectedRadio: any = watch('electricReference');
  const currentInput = watch(name);

  if (selectedRadio === name) {
    if (!currentInput && currentInput !== '') {
      errors[name] = { message: t('requiredField') };
    } else if (isNaN(currentInput)) {
      errors[name] = { message: t('mustBeANumber') };
    } else {
      errors[name] = null;
    }
  } else {
    errors[name] = null;
  }

  return (
    <FormGridLayout
      labelTranslationKey={name}
      items={[
        <SelectAndIcon>
          <InputContainer>
            <SInput name={name} errors={errors} control={control} type="number" />
          </InputContainer>
          <FormControlLabel
            value={name}
            control={<Radio />}
            label=""
            sx={isWeb ? {} : { marginLeft: 2 }}
          />
        </SelectAndIcon>,
      ]}
    />
  );
};

export const NewSimulationElectricReference = ({
  control,
  errors,
  watch,
  setValue,
}: {
  control: Control<StudyFormValues>;
  errors: FieldErrors<StudyFormValues>;
  watch: UseFormWatch<StudyFormValues>;
  setValue: UseFormSetValue<StudyFormValues>;
}) => {
  const { t } = useTranslation();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const electricRef = watch('electricReference');

  React.useEffect(() => {
    if (electricRef === 'annualElectricConsumption') {
      setValue('annualElectricBill', '');
      setTimeout(() => setValue('annualElectricBill', undefined), 100);
      setValue('monthlyElectricBill', '');
      setTimeout(() => setValue('monthlyElectricBill', undefined), 100);
    } else if (electricRef === 'annualElectricBill') {
      setValue('annualElectricConsumption', '');
      setTimeout(() => setValue('annualElectricConsumption', undefined), 100);
      setValue('monthlyElectricBill', '');
      setTimeout(() => setValue('monthlyElectricBill', undefined), 100);
    } else if (electricRef === 'monthlyElectricBill') {
      setValue('annualElectricConsumption', '');
      setTimeout(() => setValue('annualElectricConsumption', undefined), 100);
      setValue('annualElectricBill', '');
      setTimeout(() => setValue('annualElectricBill', undefined), 100);
    } else if (electricRef === 'NC') {
      setValue('annualElectricConsumption', '');
      setTimeout(() => setValue('annualElectricConsumption', undefined), 100);
      setValue('annualElectricBill', '');
      setTimeout(() => setValue('annualElectricBill', undefined), 100);
      setValue('monthlyElectricBill', '');
      setTimeout(() => setValue('monthlyElectricBill', undefined), 100);
    }
  }, [electricRef]);

  return (
    <>
      <div className="my-5">
        <span className="font-bold">{t('electricReference')}</span>
      </div>
      <Controller
        control={control}
        name="electricReference"
        render={({ field }) => (
          <FormControl style={{ width: '100%' }}>
            <RadioGroup {...field}>
              <InputRow
                name="annualElectricConsumption"
                control={control}
                errors={errors}
                watch={watch}
              />
              <InputRow name="annualElectricBill" control={control} errors={errors} watch={watch} />
              <InputRow
                name="monthlyElectricBill"
                control={control}
                errors={errors}
                watch={watch}
              />
              {isWeb ? (
                <FormGridLayout
                  labelTranslationKey="iDontKnow"
                  items={[
                    <>
                      <InputContainer></InputContainer>
                      <FormControlLabel value="NC" label="" control={<Radio />} />
                    </>,
                  ]}
                />
              ) : (
                <MobileLabel>
                  <span className="font-bold uppercase" style={{ letterSpacing: 1 }}>
                    {t('iDontKnow')}
                  </span>
                  <FormControlLabel value="NC" label="" control={<Radio />} />
                </MobileLabel>
              )}
            </RadioGroup>
          </FormControl>
        )}
      />
    </>
  );
};

const MobileLabel = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
