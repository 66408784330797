import { Models } from '../types/Results';
import { EconomicModelEnum } from '../types/EconomicModel.enum';

export const bestModelAndSaving = (
  models: Models
): {
  bestModel: EconomicModelEnum;
  selfConsumption: { saving: number; totalSubsidies: number };
  fullResale: { saving: number; totalSubsidies: number };
  consumptionAndResale: { saving: number; totalSubsidies: number };
} => {
  const selfConsumptionProfit = models.selfConsumption.profitsOverYears.reduce(
    (acc, val) => acc + val,
    0
  );
  const fullResaleProfit = models.fullResale.profitsOverYears.reduce((acc, val) => acc + val, 0);

  const consumptionAndResaleProfit = models.consumptionAndResale.profitsOverYears.reduce(
    (acc, val) => acc + val,
    0
  );
  let bestModel;

  if (
    selfConsumptionProfit > fullResaleProfit &&
    selfConsumptionProfit > consumptionAndResaleProfit
  ) {
    bestModel = 'selfConsumption';
  } else if (fullResaleProfit > consumptionAndResaleProfit) {
    bestModel = 'fullResale';
  } else {
    bestModel = 'consumptionAndResale';
  }
  return {
    bestModel: bestModel as EconomicModelEnum,
    selfConsumption: {
      saving: Math.round(selfConsumptionProfit),
      totalSubsidies: models.selfConsumption.totalSubsidies,
    },
    fullResale: {
      saving: Math.round(fullResaleProfit),
      totalSubsidies: models.fullResale.totalSubsidies,
    },
    consumptionAndResale: {
      saving: Math.round(consumptionAndResaleProfit),
      totalSubsidies: models.consumptionAndResale.totalSubsidies,
    },
  };
};
