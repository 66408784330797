import React from 'react';
import { COLORS } from '../../style/colors';
import { StudyStatus } from '../../types/Study';
import { useTranslation } from 'react-i18next';

const statusToColor: Record<StudyStatus, string> = {
  INITIALIZED: COLORS.green,
  COMPLETED: COLORS.green,
  ORDER_COMPLETED: COLORS.green,
  ORDER_NOT_COMPLETED: COLORS.green,
};

export const StudyStatusColor = ({ status }: { status: StudyStatus }) => {
  const { t } = useTranslation();
  const color = statusToColor[status];

  return (
    <span style={{ color }} className="text-end text-xl font-bold">
      {t(status)}
    </span>
  );
};
