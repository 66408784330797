import * as yup from 'yup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { isFrenchZipCode } from '../../util/formValidators/frenchZipCodeValidator';
import { isPhoneNumber } from '../../util/formValidators/phoneNumberValidator';

export const schema = yup
  .object()
  .shape({
    title: yup.string().required('requiredField'),
    clientFirstName: yup.string().required('requiredField'),
    clientLastName: yup.string().required('requiredField'),
    address: yup.string().required('requiredField'),
    zipCode: yup
      .string()
      .required('requiredField')
      .test('zipCode', 'invalidZipCode', (value) => isFrenchZipCode(value)),
    city: yup.string().required('requiredField'),
    country: yup.string().required('requiredField'),
    clientPhoneNumber: yup
      .string()
      .required('requiredField')
      .test('phoneNumber', 'invalidPhoneNumber', (value) => isPhoneNumber(value.replace(/ /g, ''))),
    clientEmail: yup.string().required('requiredField').email('invalidEmailAddress'),
  })
  .required();

export type ClientFormValues = yup.InferType<typeof schema>;

const AppendixButton = ({ setAppendix }: { setAppendix: any }) => {
  const { t } = useTranslation();

  return (
    <button type="button" onClick={() => setAppendix(undefined)} className="btn go-back-btn">
      <span>{t('ok').toUpperCase()}</span>
    </button>
  );
};

export const Appendix0 = ({ setAppendix }: { setAppendix: any }) => {
  return (
    <div>
      <p>
        Vos données à caractère personnel sont collectées par la société EDILIANS dans le but de
        vous proposer une proposition personnalisée pour votre projet d’installation photovoltaïque
        sur le fondement de votre consentement. Elles sont destinées aux seules personnes ayant
        besoin d’en connaître au sein de la Direction commerciale et marketing du groupe EDILIANS,
        dans la stricte limite de leurs attributions et transmises à certains professionnels du
        bâtiment avec votre accord. Elles sont conservées pour une période de trois ans.
      </p>
      <br />
      <p>
        Vous disposez des droits suivants : droit d’accès, droit de rectification, droit à
        l’effacement (« droit à l’oubli »), droit à la limitation et droit d’opposition. Pour
        exercer vos droits, vous êtes invités à contacter EDILIANS aux coordonnées suivantes :
        <b> donneespersonnelles@edilians.com</b> ou à EDILIANS, Service juridique, 65, chemin du
        Moulin Carron 69570 DARDILLY. Vous pouvez également adresser une réclamation auprès de la
        CNIL.
      </p>
      <br />
      <h6>
        <b>Consentement transmission de données aux professionnels du bâtiment : </b>
      </h6>
      <br />
      <p>
        En donnant votre consentement, vous acceptez qu’Edilians transmette à des professionnels du
        bâtiment qui auraient vocation à intervenir sur votre projet d’installation photovoltaïque,
        vos données personnelles dans le but de vous proposer un devis tarifaire personnalisé. Vos
        données seront traitées par les personnes ayant besoin d’en connaître simplement pour cette
        finalité. EDILIANS s’engage à transmettre ces données de manière sécurisée.
      </p>
      <Center>
        <AppendixButton setAppendix={setAppendix} />
      </Center>
    </div>
  );
};

export const Appendix1 = ({ setAppendix }: { setAppendix: any }) => {
  return (
    <div>
      <p>
        Vos données à caractère personnel sont collectées par la société EDILIANS dans le but de
        vous envoyer des communications à des fins commerciales et marketing sur le fondement de
        votre consentement. Elles sont destinées aux seules personnes ayant besoin d’en connaître au
        sein de la Direction commerciale et marketing du groupe EDILIANS, dans la stricte limite de
        leurs attributions. Elles sont conservées pour une période de trois ans.
      </p>
      <p>
        Vous disposez des droits suivants : droit d’accès, droit de rectification, droit à
        l’effacement (« droit à l’oubli »), droit à la limitation et droit d’opposition. Pour
        exercer vos droits, vous êtes invités à contacter EDILIANS aux coordonnées suivantes :
        donneespersonnelles@edilians.com ou à EDILIANS, Service juridique, 65, chemin du Moulin
        Carron 69570 DARDILLY. Vous pouvez également adresser une réclamation auprès de la CNIL.
      </p>
      <Center>
        <AppendixButton setAppendix={setAppendix} />
      </Center>
    </div>
  );
};

const Center = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
