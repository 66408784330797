import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Autocomplete, Button, Modal, styled, TextField } from '@mui/material';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { useTranslation } from 'react-i18next';

import { ModalContent } from '../../../components/ModalContent';
import { Label } from '../../parameters/components/Label';
import { MyInput } from '../../../components/MyInput';
import { User } from '../../../types/User';
import { usersCollection, usersCollectionName } from '../../../firebase/firestoreCollections';

interface AddModalProps {
  title: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  options: any[];
  onSubmit: () => void;
  disabled: boolean;
  noOptionsText: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  getOptionLabel: (option: any) => string;
  linkedElementsName: string;
  linkedElementTitle: string;
}

export const AddModal = ({
  title,
  isModalOpen,
  onModalClose,
  options,
  onSubmit,
  disabled,
  noOptionsText,
  errors,
  getOptionLabel,
  control,
  linkedElementsName,
  linkedElementTitle,
}: AddModalProps) => {
  const { t } = useTranslation('admin');

  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection, {
    subscribe: true,
  });
  const internalUsers = (usersQuery.data || []).filter((u) => u.isInternal);

  return (
    <Modal open={isModalOpen}>
      <SModalContent>
        <div className="mb-8">
          <Title className="text-center">{title}</Title>
        </div>

        <form onSubmit={onSubmit} className="flex flex-col items-center">
          <Line>
            <div className=" mb-3 flex items-center justify-center">
              <LabelContainer>
                <SLabel>{t('UsersManagement.code')}</SLabel>
              </LabelContainer>
              {/*@ts-ignore*/}
              <SInput name="code" errors={errors} control={control} size="medium" />
            </div>
          </Line>

          <Line>
            <div className="mb-8 flex items-center justify-center ">
              <LabelContainer>
                <SLabel>{t('UsersManagement.label')}</SLabel>
              </LabelContainer>
              <SInput
                name="label"
                errors={errors}
                control={control}
                // @ts-ignore
                size="medium"
                style={{ marginRight: -5 }}
              />
            </div>
          </Line>

          <div className="flex flex-col items-start">
            <SLabel>{t('UsersManagement.manager')}</SLabel>
            <Line>
              <div className="flex justify-center">
                <Controller
                  name="manager"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      noOptionsText={t('all:studiesList.aucunUtilisateurTrouve')}
                      getOptionLabel={(option) =>
                        option.firstName + ' ' + option.lastName + ' ' + option.email
                      }
                      options={internalUsers}
                      sx={{ width: '100%' }}
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={t('UsersManagement.searchUser')} />
                      )}
                    />
                  )}
                />
              </div>
            </Line>
          </div>

          <div className="mt-8 flex flex-col items-start">
            <SLabel>{linkedElementTitle}</SLabel>
            <Line>
              <div className="flex justify-center">
                <Controller
                  name={linkedElementsName}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      noOptionsText={noOptionsText}
                      options={options}
                      getOptionLabel={getOptionLabel}
                      sx={{ width: '100%' }}
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={(params) => <TextField {...params} label={t('common:search')} />}
                    />
                  )}
                />
              </div>
            </Line>
          </div>

          <div className="mt-12 flex justify-end">
            <div className="mr-1">
              <SButton variant="adminGrey" onClick={onModalClose}>
                {t('common:cancel')}
              </SButton>
            </div>
            <div className="ml-1">
              <SButton variant="adminGreen" disabled={disabled} type="submit">
                {t('common:validate')}
              </SButton>
            </div>
          </div>
        </form>
      </SModalContent>
    </Modal>
  );
};

const Title = styled('h2')``;

const SModalContent = styled(ModalContent)`
  min-height: 220px;
  width: 100%;
  max-width: 740px;
  padding: 30px 70px;
`;

const SButton = styled(Button)`
  width: 250px;
`;

const SLabel = styled(Label)`
  flex: 1;
  margin-bottom: 12px;
`;

const LabelContainer = styled('div')`
  width: 115px;
`;

const Line = styled('div')`
  width: 400px;
`;

const SInput = styled(MyInput)`
  //flex: 1;
  width: 100%;
`;
