import React from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';

import { snackError, snackSuccess } from '../util/snacks';
import {
  BackgroundImage,
  Form,
  FormContainer,
  FormContent,
  Line,
  LineWithMargin,
  SButton,
  SInput,
  SolarImage,
} from './authStyle';
import { clientRoutes } from '../routing/clientRoutes';

type FormValues = {
  email: string;
  password: string;
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required('requiredField').email('invalidEmailAddress'),
    password: yup.string().required('requiredField').min(6, 'atLeast6Characters'),
  })
  .required();

export const EmailSignIn = withTranslation()(({ t }) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data: FormValues) => {
    signInWithEmailAndPassword(getAuth(), data.email, data.password)
      .then(() => {
        snackSuccess('loggedIn');
        navigate('/', { replace: true });
      })
      .catch(() => {
        return snackError('invalidCredentials');
      });
  };

  return (
    <BackgroundImage>
      <FormContainer>
        <FormContent>
          <div className="flex flex-row items-center">
            <SolarImage src="/images/login-solar-img.png" alt={t('solarSimulator')} />
            <h1>{t('solarSimulator')}</h1>
          </div>
          <p className="my-10" style={{ fontSize: 16, fontWeight: 700 }}>
            {t('EmailSignIn.externalConnection')}
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SInput
              name="email"
              type="email"
              placeholder="email"
              errors={errors}
              control={control}
            />
            <SInput
              name="password"
              type="password"
              placeholder="password"
              errors={errors}
              control={control}
            />
            <LineWithMargin>
              <Link to={clientRoutes.forgotPassword}>{t('forgotPassword')}</Link>
              <Link to={clientRoutes.signUp}>{t('EmailSignIn.accountCreation')}</Link>
            </LineWithMargin>
            <Line>
              <SButton onClick={() => navigate(clientRoutes.signInChoice)} variant="outlinedLogin">
                {t('back')}
              </SButton>
              <SButton type="submit" variant="contained" disabled={!isValid}>
                {t('signIn')}
              </SButton>
            </Line>
          </Form>
        </FormContent>
      </FormContainer>
    </BackgroundImage>
  );
});
