import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

import { UserMenu } from './UserMenuAppHeader';
import { AdminSettings } from './AdminSettingsAppHeader';
import { COLORS } from '../style/colors';
import { clientRoutes } from '../routing/clientRoutes';
import { useNavigate } from 'react-router-dom';

export const AppHeader = ({ isAdminScreen }: { isAdminScreen?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  return (
    <AppBar sx={{ bgcolor: 'white' }} position="static">
      <Toolbar
        sx={{ maxHeight: '120px', paddingX: isWeb ? '15%' : '4%', paddingY: 3 }}
        disableGutters>
        <Box sx={{}}>
          <EdiliansLogo
            src="/images/signature-mail.png"
            alt={t('logo')}
            onClick={() => navigate(clientRoutes.home)}
          />
        </Box>

        {process.env.REACT_APP_ENVIRONMENT === 'development' && (
          <Box className="ml-4 text-blue" style={{ fontSize: '8px' }}>
            {t('qa_environment')}
          </Box>
        )}

        {isAdminScreen && (
          <Box
            sx={{
              flexGrow: 1,
              display: { md: 'flex' },
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            {isWeb && <h1 className="ml-20 text-2xl text-blue">{t('admin:appHeader.title')}</h1>}
          </Box>
        )}

        <Box
          sx={{
            flexGrow: 1,
            display: { md: 'flex' },
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          {isWeb && <AdminSettings />}
        </Box>

        <UserMenu color={COLORS.primary} />
      </Toolbar>
    </AppBar>
  );
};

const EdiliansLogo = styled('img')`
  max-height: 80px;
  cursor: pointer;
`;
