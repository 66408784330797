import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import { COLORS } from '../../../style/colors';
import { ModalContent } from '../../../components/ModalContent';
import { clientRoutes } from '../../../routing/clientRoutes';

export const Background = styled('div')`
  background-color: ${COLORS.greyPurple};
  padding: 20px 12px;
  height: 100%;
`;

export const Container = styled('div')`
  background-color: ${COLORS.white};
  border-radius: 10px;
  padding: 30px;
`;

export const GridElement = styled('div')`
  border-radius: 10px;
  border-width: 1px;
  border-color: ${COLORS.primary};
  padding: 10px;
  height: 90px;
  justify-content: center;
`;

export const InactiveGridElement = styled(GridElement)`
  border-color: ${COLORS.grey};
`;

export const BigPlusButton = styled('div')`
  border-radius: 10px;
  background-color: ${COLORS.primary};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
`;

export const GridText = styled('span')`
  font-weight: bold;
  color: ${COLORS.primary};
`;

export const InactiveGridText = styled(GridText)`
  color: ${COLORS.grey};
`;

export const SModalContent = styled(ModalContent)`
  max-width: 630px;
  padding: 30px 50px 32px;
`;

export const ActionsContainer = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ActionIconContainer = styled('span')`
  padding: 6px 4px 8px;
  background-color: var(--background-secondary-color);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Subtitle = styled('h2')`
  margin-bottom: 7px;
`;

export const GoBackButton = ({ path }: { path?: string }) => {
  const navigate = useNavigate();

  return (
    <div className="my-4">
      <button
        type="button"
        onClick={() => navigate(path || clientRoutes.usersManagement)}
        className="btn go-back-btn">
        <span style={{ fontFamily: 'solaire', fontSize: 26 }}>{'\ue929'}</span>
      </button>
    </div>
  );
};
