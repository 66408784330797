import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, IconButton, Modal, TextField } from '@mui/material';
import styled from 'styled-components';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import CloseIcon from '@mui/icons-material/Close';

import { ModalContent } from '../../components/ModalContent';
import {
  studiesCollection,
  studiesForSearchCollection,
  usersCollection,
  usersCollectionName,
} from '../../firebase/firestoreCollections';
import { StudyForSearch } from '../../types/Study';
import { snackError, snackSuccess } from '../../util/snacks';
import { User } from '../../types/User';
import { removeField, updateData } from '../../firebase/firestoreQueries';
import _ from 'lodash';
import { Timestamp } from 'firebase/firestore';

export const AssignModal = ({
  study,
  setStudy,
  setUpdatedAssignedCompleteName,
}: {
  study?: StudyForSearch;
  setStudy: React.Dispatch<React.SetStateAction<StudyForSearch | undefined>>;
  setUpdatedAssignedCompleteName: React.Dispatch<
    React.SetStateAction<string | undefined | 'UNAFFECTED'>
  >;
}) => {
  const { t } = useTranslation();

  const [assignment, setAssignment] = React.useState<{
    uid: string;
    firstName?: string;
    lastName?: string;
  } | null>();

  const usersQuery = useFirestoreQueryData<User>([usersCollectionName], usersCollection);
  const externalUsers = (usersQuery.data || [])
    .filter((u) => u.isInternal !== true)
    .map((u) => ({
      uid: u.uid,
      firstName: u.firstName,
      lastName: u.lastName,
    }));

  const close = () => {
    setAssignment(undefined);
    setStudy(undefined);
  };

  const onSubmit = async () => {
    if (study) {
      try {
        const assignmentInfo = assignment
          ? {
              firstName: assignment.firstName,
              lastName: assignment.lastName,
              uid: assignment.uid,
            }
          : undefined;

        await updateData(studiesCollection, {
          uid: study.uid,
          assignment: assignmentInfo,
          updatedAt: Timestamp.fromDate(new Date()),
        });
        await updateData(studiesForSearchCollection, {
          uid: study.uid,
          assignment: assignmentInfo,
        });
        setUpdatedAssignedCompleteName(
          _.upperCase(assignment?.firstName) + ' ' + _.upperCase(assignment?.lastName)
        );
        snackSuccess();
      } catch (e) {
        return snackError();
      }
    }
    close();
  };

  const unassign = async () => {
    if (study) {
      try {
        await removeField(studiesCollection, 'assignment', study.uid);
        await removeField(studiesForSearchCollection, 'assignment', study.uid);
        setUpdatedAssignedCompleteName('UNAFFECTED');
        snackSuccess();
      } catch (e) {
        return snackError();
      }
    }
    close();
  };

  return (
    <Modal open={!!study} onClose={close}>
      <SModalContent>
        <CloseIconContainer>
          <IconButton onClick={() => setStudy(undefined)}>
            <CloseIcon />
          </IconButton>
        </CloseIconContainer>
        <div>
          <h2 className="mb-5 text-center font-bold">{t('studiesList.assignment')}</h2>

          {study?.assignment && (
            <div className="mb-10 flex items-center justify-between">
              <span>
                <span className="font-bold">{t('assignedAt')}</span>
                {_.upperCase(study?.assignment.firstName)} {_.upperCase(study?.assignment.lastName)}
              </span>
              <Button variant="contained" onClick={unassign} size="small">
                {t('studiesList.desaffecter')}
              </Button>
            </div>
          )}

          <div className="mb-5 mt-3 flex justify-center">
            <Autocomplete
              onChange={(_event, user) => setAssignment(user)}
              disablePortal
              noOptionsText={t('studiesList.aucunUtilisateurTrouve')}
              options={externalUsers}
              getOptionLabel={(user) => user.firstName + ' ' + user.lastName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label={t('studiesList.rechercheAffectation')} />
              )}
              renderOption={(props, user) => (
                <li {...props} key={user.uid}>
                  {user.firstName + ' ' + user.lastName}
                </li>
              )}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button variant="contained" disabled={!assignment} onClick={onSubmit}>
            {t('studiesList.affecter')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

const SModalContent = styled(ModalContent)`
  height: unset;
  min-height: unset;
`;

const CloseIconContainer = styled('div')`
  display: flex;
  justify-content: end;
  margin-right: -24px;
  margin-top: -20px;
  margin-bottom: 6px;
`;
