import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AllOrientations, Orientation } from '../../types/Orientations.enum';

const newSimulationSchema = yup
  .object()
  .shape({
    altitude: yup.string().required('requiredField'),
    annexes: yup.array().of(yup.string().required()),
    annualElectricBill: yup.string().notRequired(),
    annualElectricConsumption: yup.string().notRequired(),
    constructionPeriod: yup.string().required('requiredField'),
    departmentCode: yup.string().required('requiredField'),
    domesticHotWater: yup
      .array()
      .of(yup.string())
      .min(1, 'requiredField')
      .required('requiredField'),
    electricReference: yup.string().required('requiredField'),
    equipments: yup
      .object()
      .shape({
        fridge: yup.number().required(),
        television: yup.number().required(),
        dishwasher: yup.number().required(),
        dryer: yup.number().required(),
        kettle: yup.number().required(),
        vacuum: yup.number().required(),
        freezer: yup.number().required(),
        coffeemaker: yup.number().required(),
        iron: yup.number().required(),
        washingMachine: yup.number().required(),
        toaster: yup.number().required(),
        microwave: yup.number().required(),
        internetBox: yup.number().required(),
        CMV: yup.number().required(),
        swimmingPool: yup.number().required(),
        heatedSwimmingPool: yup.number().required(),
        hammam: yup.number().required(),
        electricCar: yup.number().required(),
        electricBike: yup.number().required(),
        electricScooter: yup.number().required(),
      })
      .required('requiredField'),
    heating: yup.array().of(yup.string()).min(1, 'requiredField').required('requiredField'),
    livingAreaInSquareMeters: yup
      .number()
      .typeError('mustBeANumber')
      .test('roofArea', 'requiredField', (value) => !!value)
      .test('roofArea', 'invalidValue', (value) => !!(value && value >= 1))
      .required('requiredField'),
    monthlyElectricBill: yup.string().notRequired(),
    numberOfResidents: yup
      .number()
      .typeError('mustBeANumber')
      .test('roofArea', 'requiredField', (value) => !!value)
      .test('roofArea', 'invalidValue', (value) => !!(value && value >= 1))
      .required('requiredField'),
    orientation: yup.string().required('requiredField'),
    roofArea: yup
      .number()
      .typeError('mustBeANumber')
      .test('roofArea', 'requiredField', (value) => !!value)
      .test('roofArea', 'invalidValue', (value) => !!(value && value >= 1))
      .required('requiredField'),
    slope: yup.string().required('requiredField'),
  })
  .required();

export type StudyFormValues = yup.InferType<typeof newSimulationSchema>;

export const isFormValid = (isValid: boolean, watchedFields: StudyFormValues): boolean =>
  isValid &&
  (watchedFields.electricReference === 'NC' ||
    (watchedFields.electricReference === 'monthlyElectricBill' &&
      !!watchedFields.monthlyElectricBill) ||
    (watchedFields.electricReference === 'annualElectricBill' &&
      !!watchedFields.annualElectricBill) ||
    (watchedFields.electricReference === 'annualElectricConsumption' &&
      !!watchedFields.annualElectricConsumption)) &&
  watchedFields.domesticHotWater.length === 1 &&
  !(
    watchedFields.equipments.swimmingPool === 1 && watchedFields.equipments.heatedSwimmingPool === 1
  );

export const altitudeOptions = [
  { value: 'NC', label: 'iDontKnow' },
  { value: '0', label: '0' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '300', label: '300' },
  { value: '400', label: '400' },
  { value: '500', label: '500' },
  { value: '600', label: '600' },
  { value: '700', label: '700' },
  { value: '800', label: '800' },
  { value: '900', label: '900' },
  { value: '1000', label: '1000' },
  { value: '1100', label: '1100' },
  { value: '1200', label: '1200' },
  { value: '1300', label: '1300' },
  { value: '1400', label: '1400' },
  { value: '1500', label: '1500' },
];

export const periodOptions = [
  { value: 'NC', label: 'iDontKnow' },
  { value: 'Before_1980', label: 'before1980' },
  { value: 'Between_1980_1989', label: '1980-1989' },
  { value: 'Between_1990_1999', label: '1990-1999' },
  { value: 'Between_2000_2009', label: '2000-2009' },
  { value: 'Between_2010_2021', label: '2010-2021' },
  { value: 'After_2022', label: 'after2022' },
  { value: 'En_construction', label: 'inConstruction' },
];

export const slopeOptions = [
  { value: 'NC', label: 'iDontKnow' },
  { value: '0', label: 'flatRoof' },
  { value: '10', label: '10°' },
  { value: '20', label: '20°' },
  { value: '30', label: '30°' },
  { value: '40', label: '40°' },
  { value: '50', label: '50°' },
  { value: '60', label: '60°' },
  { value: '70', label: '70°' },
  { value: '80', label: '80°' },
  { value: '90', label: '90°' },
];

export const orientationOptions: { value: Orientation; label: string }[] = [
  { value: AllOrientations.NC, label: 'iDontKnow' },
  { value: AllOrientations.E, label: 'est' },
  { value: AllOrientations.ESE, label: 'est-sud-est' },
  { value: AllOrientations.SE, label: 'sud-est' },
  { value: AllOrientations.SSE, label: 'sud-sud-est' },
  { value: AllOrientations.S, label: 'sud' },
  { value: AllOrientations.SSW, label: 'sud-sud-ouest' },
  { value: AllOrientations.SW, label: 'sud-ouest' },
  { value: AllOrientations.WSW, label: 'ouest-sud-ouest' },
  { value: AllOrientations.W, label: 'ouest' },
];

export const newStudyFormOptions = {
  mode: 'onChange' as const,
  reValidateMode: 'onChange' as const,
  resolver: yupResolver(newSimulationSchema),
};

export const defaultValues = {
  altitude: 'NC',
  annexes: [],
  heating: [],
  constructionPeriod: 'NC',
  departmentCode: '',
  domesticHotWater: [],
  electricReference: '',
  equipments: {
    coffeemaker: 1,
    dishwasher: 1,
    dryer: 1,
    freezer: 1,
    fridge: 1,
    internetBox: 1,
    iron: 1,
    kettle: 1,
    microwave: 1,
    television: 1,
    toaster: 1,
    vacuum: 1,
    washingMachine: 1,
    CMV: 0,
    swimmingPool: 0,
    heatedSwimmingPool: 0,
    hammam: 0,
    electricCar: 0,
    electricBike: 0,
    electricScooter: 0,
  },
  livingAreaInSquareMeters: undefined,
  numberOfResidents: undefined,
  orientation: 'NC',
  roofArea: undefined,
  slope: 'NC',
};
