import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from 'styled-components';
import { Button } from '@mui/material';

import { useAuth } from './authContext';
import { BackgroundImage, FormContainer, FormContent, SolarImage } from './authStyle';
import { clientRoutes } from '../routing/clientRoutes';
import { getAdditionalUserInfo, getAuth, SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import { snackError } from '../util/snacks';
import { createUser, getData } from '../firebase/firestoreQueries';
import { usersCollection } from '../firebase/firestoreCollections';

const provider = new SAMLAuthProvider(process.env.REACT_APP_SAML_PROVIDER || '');

export const SignInChoice = withTranslation()(({ t }) => {
  const navigate = useNavigate();
  const user = useAuth();
  const auth = getAuth();

  const samlSignIn = async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider);

      if (userCredential) {
        const userData = await getData(usersCollection, userCredential.user.uid);
        if (!userData) {
          const additionalUserInfo = getAdditionalUserInfo(userCredential)?.profile;

          const firstName =
            process.env.REACT_APP_ENVIRONMENT === 'development'
              ? 'Prénom'
              : additionalUserInfo?.FirstName;
          const lastName =
            process.env.REACT_APP_ENVIRONMENT === 'development'
              ? 'Nom'
              : additionalUserInfo?.LastName;
          await createUser({
            // @ts-ignore
            firstName,
            // @ts-ignore
            lastName,
            uid: userCredential.user.uid,
            email: userCredential.user.email || '',
            isActive: true,
            isInternal: true,
          });
        }
        navigate(clientRoutes.home);
      } else {
        snackError();
      }
    } catch (error) {
      console.error(error);
      snackError();
    }
  };

  return (
    <BackgroundImage>
      <FormContainer>
        <FormContent>
          <div className="flex flex-row items-center">
            <SolarImage src="/images/login-solar-img.png" alt={t('solarSimulator')} />
            <h1>{t('solarSimulator')}</h1>
          </div>
          <p className="my-10" style={{ fontSize: 16, fontWeight: 700 }}>
            {user ? t('youAreLoggedIn') : t('youAreNotLoggedIn')}
          </p>
          <SButton
            onClick={() => navigate(clientRoutes.signIn)}
            variant="contained"
            endIcon={<ChevronRightIcon />}
            sx={{
              '& .MuiButton-endIcon': {
                position: 'absolute',
                right: '1rem',
              },
            }}>
            {t('iAmClientOrInstaller')}
          </SButton>
          <ButtonContainer>
            <SButton
              onClick={samlSignIn}
              variant="contained"
              endIcon={<ChevronRightIcon />}
              sx={{
                '& .MuiButton-endIcon': {
                  position: 'absolute',
                  right: '1rem',
                },
              }}>
              {t('iAmInternalToEdilians')}
            </SButton>
          </ButtonContainer>
        </FormContent>
      </FormContainer>
    </BackgroundImage>
  );
});

const SButton = styled(Button)`
  width: 37vh;
  margin: 200px;
`;

const ButtonContainer = styled('div')`
  margin-bottom: 15px;
  margin-top: 15px;
`;
