import React from 'react';

import { useAuth } from '../auth/authContext';
import { SignInChoice } from '../auth/SignInChoice';
import { NonActivatedUser } from '../auth/NonActivatedUser';

interface AuthenticationGuardProps {
  component: JSX.Element;
}

export const AuthenticationGuard = ({ component }: AuthenticationGuardProps) => {
  const user = useAuth();
  if (!user) {
    return <SignInChoice />;
  } else if (!user.isActive && !user.isAdmin && !user.isInternal) {
    return <NonActivatedUser />;
  }

  return component;
};
