import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColoredSquare, ColoredTitle } from './ColoredSquare';
import { PowerRangesInput } from '../PowerRangesInput';
import { EconomicModelEnum } from '../../../types/EconomicModel.enum';

export const ColoredSquareAndModel = ({
  model,
  errors,
  control,
  inputLabel,
  inputAdornment,
}: {
  model: EconomicModelEnum;
  errors: FieldErrors<any>;
  control: Control<any>;
  inputLabel: string;
  inputAdornment: string;
}) => {
  const { t } = useTranslation('admin');

  return (
    <div>
      <ColoredTitle model={model}>{t(model)}</ColoredTitle>
      <ColoredSquare model={model}>
        <div className="flex">
          <div className="flex" style={{ flex: 9 }}>
            <span className="text-lg font-bold uppercase">{t('powerRange')}</span>
          </div>
          <div className="flex flex-1" style={{ flex: 6 }}>
            <span className="text-lg font-bold">{inputLabel}</span>
          </div>
        </div>
        <PowerRangesInput
          model={model}
          errors={errors}
          control={control}
          inputAdornment={inputAdornment}
        />
      </ColoredSquare>
    </div>
  );
};
